import React from 'react';
import styled from 'styled-components';
import {
  FlexContainer,
  BalanceDetail,
  HoverContainer
} from 'containers/inProgressPage/subcomponents/stock/StyledComponents';
import { upArrow } from '../../assets/upArrow';
import { downArrow } from '../../assets/downArrow';

const BalanceTableRow = ({
  leftObj,
  rightObj,
  portfolio,
  increase,
  condense
}) => {
  return (
    <DetailRow
      portfolio={portfolio}
      className={condense && 'condense'}
      flexWrap="wrap"
    >
      <BalanceDetail className={leftObj.className}>
        {leftObj.text}
      </BalanceDetail>
      {rightObj && (
        <HoverContainer className={rightObj.className} increase={increase}>
          {portfolio && <Indicator>{increase ? upArrow : downArrow}</Indicator>}
          {rightObj.text}
          {portfolio && (
            <div className={portfolio && 'description'}>
              <div className="pop-up information">
                Change in your portfolio value since the start of this scenario
              </div>
            </div>
          )}
        </HoverContainer>
      )}
    </DetailRow>
  );
};

export default BalanceTableRow;

const DetailRow = styled(FlexContainer)`
  margin: 0 0 8px;
  align-items: baseline;
  ${({ portfolio }) => portfolio && 'justify-content: flex-start'};
  &.condense {
    margin-bottom: 0px;
  }
`;

// This is eventually going to be removed in lieu of svgs
const Indicator = styled.div`
  padding-top: 5px;
`;
