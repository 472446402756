import styled from 'styled-components';

export const OpaqueWrapper = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 200;
`;

export const ModalWrapper = styled.div`
  animation: fadeInDown 300ms ease-out;
  background: ${({ theme }) => theme.white};
  border-radius: 4px;
  font-family: ${({ theme }) => theme.fontFamily};
  height: fit-content;
  padding: 32px;
  position: relative;
  max-width: 1024px;
  width: 100%;
  .buttonContainer {
    display: flex;
    justify-content: flex-start;
  }
  .btn-left {
    margin-right: 10px;
  }
  @media (max-width: 768px) {
    max-width: 350px;
    .buttonContainer {
      display: flex;
      flex-direction: column;
    }
    .btn-left {
      width: 100%;
      margin-bottom: 15px;
    }
    .btn-right {
      width: 100%;
    }
  }
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const ModalDescription = styled.div`
  color: #484b4d;
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 30px;
  margin: ${({ margin }) => margin};
  &.bold {
    font-weight: 900;
  }
`;
