import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FlexContainer } from 'containers/inProgressPage/subcomponents/stock/StyledComponents';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';

const NetworkNotification = () => {
  const [showNotification, setShowNotification] = useState(false);
  const {
    state: {
      assessmentAttempt: { requestTime },
      error
    }
  } = useAssessmentContext();

  useEffect(() => {
    if (requestTime >= 5000) {
      setShowNotification(true);
    }
  }, [requestTime, error]);

  return (
    showNotification && (
      <Notification>
        <div>
          We noticed you are having connectivity issues that may impact
          performance.
          <br />
          This could be caused by your internet connection, a firewall, or the
          usage of a VPN.
          <br />
        </div>
        <CloseButton onClick={() => setShowNotification(false)}>x</CloseButton>
      </Notification>
    )
  );
};

export default NetworkNotification;

const Notification = styled(FlexContainer)`
  align-items: center;
  align-self: center;
  background: #e86d5a;
  color: ${({ theme }) => theme.white};
  font-size: 12px;
  max-width: 600px;
  padding: 8px;
  position: fixed;
  top: 12px;
  z-index: 200;
  a {
    text-decoration: none;
  }
`;

const CloseButton = styled.div`
  font-size: 12px;
  bottom: 15px;
  cursor: pointer;
  font-weight: bold;
  position: absolute;
  right: 4px;
  top: 4px;
`;
