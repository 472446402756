import { useState } from 'react';

//Set focus or blur for input
export const useInputFocus = () => {
  const [focus, setFocus] = useState(false);

  return {
    focus,
    onFocus: () => setFocus(true),
    onBlur: () => setFocus(false)
  };
};
