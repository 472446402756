import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FlexContainer } from 'components/local/styles/StyledComponents';
import warningOutlineIcon from './assets/warningOutlineIcon';
import orangeXIcon from './assets/orangeXIcon';

const ErrorPopup = ({ message, onClose, title }) => (
  <ErrorPopupContainer align="center">
    <div>{warningOutlineIcon}</div>
    <ErrorDescriptionContainer direction="column">
      <ErrorTitle>{title}</ErrorTitle>
      <ErrorMessage>{message}</ErrorMessage>
    </ErrorDescriptionContainer>
    <CloseIconContainer onClick={onClose}>{orangeXIcon}</CloseIconContainer>
  </ErrorPopupContainer>
);

export default ErrorPopup;

ErrorPopup.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

const ErrorPopupContainer = styled(FlexContainer)`
  background: #fbe7dc;
  border-radius: 2px;
  border: 1px solid #df7c48;
  color: #c35a22;
  max-width: 75%;
  padding: 12px 25px;
`;

const ErrorDescriptionContainer = styled(FlexContainer)`
  margin-left: 20px;
`;

const ErrorTitle = styled.span`
  font-family: ${({ theme }) => theme.boldFontFamily};
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
`;

const ErrorMessage = styled.span`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
`;

const CloseIconContainer = styled.div`
  cursor: pointer;
  margin: auto 0 auto auto;
`;
