import { PlayerTypes } from 'containers/app/helpers/playerTypes';

export const findPortfolio = (portfolios, botId) => {
  // Finds most recent portfolio object for taker or bot
  for (let i = portfolios.length - 1; i >= 0; i--) {
    const { player_type, asset_value, cash_value, answer_bot_id } = portfolios[
      i
    ];
    const newPortfolio = {
      ...portfolios[i],
      portfolio_value: asset_value + cash_value
    };
    const matchingBotPortfolio =
      botId && answer_bot_id && botId === answer_bot_id;
    const matchingTakerPortfolio = player_type === PlayerTypes.TAKER && !botId;
    if (matchingTakerPortfolio || matchingBotPortfolio) {
      return newPortfolio;
    }
  }
};
