import { useEffect } from 'react';
import { attemptStateTypes } from 'containers/app/helpers/attemptStateTypes';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';
import { SET_CURRENT_SECTION } from 'services/context/assessmentContext/assessmentContext';

export const useSetCurrentSection = () => {
  const { state, dispatch } = useAssessmentContext();
  const { assessmentAttempt, sectionIsLoading } = state;

  useEffect(() => {
    const findCurrentSection = attemptState => {
      return assessmentAttempt.section_attempts.find(section => {
        return section.attempt_state === attemptState;
      });
    };

    const isInProgressSection = findCurrentSection(
      attemptStateTypes.IN_PROGRESS
    );

    if (isInProgressSection !== undefined) {
      dispatch({
        type: SET_CURRENT_SECTION,
        payload: {
          currentSectionIndex: isInProgressSection.sort_index,
          sectionIsLoading: false
        }
      });
    } else {
      const isPendingSection = findCurrentSection(attemptStateTypes.PENDING);
      dispatch({
        type: SET_CURRENT_SECTION,
        payload: {
          currentSectionIndex: isPendingSection.sort_index,
          sectionIsLoading: false
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { sectionIsLoading, state };
};
