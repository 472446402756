import PropTypes from 'prop-types';
import React from 'react';
import {
  RadioRowWrapper,
  RadioInput,
  RadioLabel
} from './styles/StyledComponents';
import useParseHtml from '../hooks/useParseHtml';

const RadioRow = ({ name, option, formikProps, disabled, className }) => {
  const { handleChange, values } = formikProps;
  const classNames = `${className}${disabled && ' disabled'}`;

  const parsedHtml = useParseHtml(option);

  return (
    <RadioRowWrapper
      className={classNames}
      option={option}
      value={values[name]}
      checked={values[name] === option}
    >
      <RadioInput
        type="radio"
        id={option}
        name={name}
        value={option}
        disabled={disabled}
        onChange={handleChange}
        className={className}
        checked={values[name] === option}
      />
      <RadioLabel htmlFor={option} className={classNames}>
        {parsedHtml}
      </RadioLabel>
    </RadioRowWrapper>
  );
};

RadioRow.propTypes = {
  className: PropTypes.oneOf(['', 'secondary']).isRequired,
  disabled: PropTypes.bool.isRequired,
  formikProps: PropTypes.object.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  option: PropTypes.string.isRequired
};

export default RadioRow;
