import React, { useState } from 'react';
import MobileWarning from 'components/local/modals/MobileWarning';
import Modal from 'components/local/modals/Modal';
import NetworkNotification from 'containers/inProgressPage/subcomponents/NetworkNotification';
import PageLoading from 'components/local/loading/PageLoading';
import SectionClickable from 'containers/inProgressPage/subcomponents/clickable/SectionClickable';
import SectionLinear from 'containers/inProgressPage/subcomponents/linear/SectionLinear';
import SectionPending from 'containers/inProgressPage/subcomponents/SectionPending';
import SectionStock from 'containers/inProgressPage/subcomponents/stock/SectionStock';
import { attemptStateTypes } from 'containers/app/helpers/attemptStateTypes';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';
import { useHandleRequest } from 'containers/inProgressPage/hooks/useHandleRequest';
import { useSetCurrentSection } from 'containers/inProgressPage/hooks/useSetCurrentSection';
import { FlexContainer } from 'components/local/styles/StyledComponents';

const InProgressPage = () => {
  const { sectionIsLoading } = useSetCurrentSection();
  const {
    currentSection,
    assessmentAttempt: { assessment }
  } = useAssessmentContext();
  const { handleStartSection, handleUpdateAssessment } = useHandleRequest();
  const isStockSection =
    currentSection && currentSection.section.section_type === 'section_stock';
  const [modalShowing, setModalShowing] = useState(false);

  const renderSection = () => {
    return sectionIsLoading ? <PageLoading /> : renderAttempt();
  };

  const renderAttempt = () => {
    if (currentSection.attempt_state === attemptStateTypes.PENDING) {
      return (
        <>
          {modalShowing && assessment.is_pausable && (
            <Modal
              handleAccept={() =>
                handleUpdateAssessment(attemptStateTypes.PAUSED)
              }
              handleClose={() => setModalShowing(false)}
              modalType="leaveAssessment"
            />
          )}
          <SectionPending
            currentSection={currentSection}
            handleStartSection={handleStartSection}
            isPausable={assessment.is_pausable}
            isStockSection={isStockSection}
            setModalShowing={() => setModalShowing(true)}
          />
        </>
      );
    } else if (currentSection.attempt_state === attemptStateTypes.IN_PROGRESS) {
      return renderSectionType();
    }
  };

  const renderSectionType = () => {
    return isStockSection ? (
      <SectionStock />
    ) : currentSection.section.is_linear ? (
      <SectionLinear />
    ) : (
      <SectionClickable />
    );
  };

  return (
    <FlexContainer direction="column">
      <MobileWarning />
      {renderSection()}
      <NetworkNotification />
    </FlexContainer>
  );
};

export default InProgressPage;
