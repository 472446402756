import { axios } from 'services/requests/helpers/AxiosClient';
import { configureEndpoint } from 'services/requests/helpers/configureEndpoint';
import {
  SET_PAGE,
  UPDATE_ASSESSMENT
} from 'services/context/assessmentContext/assessmentContext';
import { SET_ASSESSMENT_THEME } from 'services/context/themeContext/themeContext';
import { setErrorPage } from 'services/requests/helpers/setErrorPage';
const proctoring = 'proctoring';

// Fetch assessment attempt when slug and uuid provided
export const getAssessmentAttempt = async (
  urlSlug,
  urlUuid,
  dispatch,
  themeDispatch
) => {
  const requestUrl = configureEndpoint(proctoring, urlSlug, urlUuid);

  try {
    const response = await axios.get(requestUrl);
    const { data } = response.data;
    data.requestTime = response.headers['request-duration'];

    dispatch({
      type: SET_PAGE,
      payload: {
        assessment: {
          ...data
        },
        assessmentIsLoading: false,
        assessmentError: null
      }
    });

    if (data.assessment?.configuration) {
      themeDispatch({
        type: SET_ASSESSMENT_THEME,
        payload: data.assessment.configuration
      });
    }
  } catch ({ response }) {
    setErrorPage(response, dispatch);
  }
};

// Fetch assessment when slug provided
export const getPublicAssessment = async (urlSlug, dispatch, themeDispatch) => {
  const requestUrl = configureEndpoint(proctoring, urlSlug);

  try {
    const response = await axios.get(requestUrl);
    const { data } = response.data;

    dispatch({
      type: SET_PAGE,
      payload: {
        assessment: {
          assessment: data,
          attempt_state: 'PUBLIC',
          requestTime: response.headers['request-duration']
        },
        assessmentIsLoading: false,
        assessmentError: null
      }
    });

    if (data?.configuration) {
      themeDispatch({
        type: SET_ASSESSMENT_THEME,
        payload: data.configuration
      });
    }
  } catch ({ response }) {
    setErrorPage(response, dispatch);
  }
};

// Create new assessment
export const createAssessmentAttempt = async (
  slug,
  dispatch,
  themeDispatch,
  isClicked = false
) => {
  const requestUrl = configureEndpoint(proctoring, slug);

  dispatch({
    type: SET_PAGE,
    payload: {
      assessmentIsLoading: true
    }
  });

  try {
    const response = await axios.post(requestUrl, {
      is_clicked: isClicked
    });
    const { uuid } = response.data.data;

    getAssessmentAttempt(slug, uuid, dispatch, themeDispatch);
  } catch ({ response }) {
    setErrorPage(response, dispatch);
  }
};

// Set assessment to in progress
export const updateAssessmentState = async (
  slug,
  uuid,
  attemptState,
  dispatch
) => {
  const requestUrl = configureEndpoint(proctoring, slug, uuid);
  const params = {
    state: attemptState
  };

  try {
    const response = await axios.put(requestUrl, params);
    response.data.data.requestTime = response.headers['request-duration'];

    dispatch({
      type: UPDATE_ASSESSMENT,
      payload: {
        assessment: response.data.data
      }
    });
  } catch ({ response }) {
    response?.status === 410 && setErrorPage(response, dispatch);
    // Possibly should always call setErrorPage on all errors >=400
    // but not doing it yet until we can more thoroughly test
  }
};
