import PropTypes from 'prop-types';
import React from 'react';
import {
  ProgressLabel,
  ProgressCircle,
  BarWrapper,
  CheckMark,
  ProgressContainer,
  Underline
} from './styles/StyledComponents';
import { checkMark } from './assets/checkMark';

const ProgressBarChecks = ({ slotLabels, currentSlot }) => {
  return (
    <BarWrapper>
      {slotLabels.map((slotLabel, index) => {
        return (
          <ProgressContainer key={slotLabel}>
            <ProgressCircle>
              {index < currentSlot && <CheckMark>{checkMark}</CheckMark>}
            </ProgressCircle>
            <ProgressLabel>{slotLabel}</ProgressLabel>
          </ProgressContainer>
        );
      })}
      <Underline></Underline>
    </BarWrapper>
  );
};

ProgressBarChecks.propTypes = {
  slotLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentSlot: PropTypes.number.isRequired
};

export default ProgressBarChecks;
