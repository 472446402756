import styled from 'styled-components';

export const TenantLogoContainer = styled.div`
  width: 126px;
  height: 126px;
  border-radius: 200px;
  background: rgb(0, 0, 0, 0.09);
  position: relative;
`;

export const TenantLogo = styled.img`
  width: 110px;
  max-height: 110px;
  border-radius: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const AssessmentTitle = styled.h1`
  font-family: ${props => props.theme.fontFamily};
  font-size: 34px;
  letter-spacing: 0.25px;
  line-height: 41px;
  color: ${props => props.theme.black};
`;

export const InstructionContainer = styled.div`
  background: #f0f2f4;
  margin-bottom: 28px;
  padding: 24px;
  color: #5a5a5a;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 22px;
`;

export const InstructionList = styled.ol`
  font-family: ${props => props.theme.fontFamily};
  list-style: none;
  counter-reset: custom-counter;
  letter-spacing: 0.44px;
  line-height: 28px;
  padding: 0;
  ol,
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    counter-increment: custom-counter;
    padding-bottom: 4px;
    &::before {
      content: counter(custom-counter);
      font-weight: bold;
      background-color: #d1e1e7;
      border-radius: 100px;
      margin-right: 10px;
      padding: 6px 8px 3px;
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 300px;
`;

export const Table = styled.table`
  margin-bottom: 28px;
  border-collapse: collapse;
  font-size: 12px;
`;

export const TableCell = styled.td`
  border: 1px solid #d0d0d0;
  padding: 5px 32px 0;
  height: 50px;
  text-align: center;
  @media (max-width: 667px) {
    border: 1px solid #eaeaea;
    padding-top: 9px;
    height: 30px;
    display: inline-block;
    width: 50%;
    background: #f7f7f7;
  }
  &.section-cell {
    @media (max-width: 667px) {
      width: 100%;
      margin-top: 15px;
      background: #eaeaea;
    }
  }
`;

export const TermsAndConditions = styled.div`
  font-size: 12px;
  letter-spacing: 0.25px;
  line-height: 22px;
  margin-top: 28px;
`;
