import React from 'react';
import TextInput from 'components/global/textInput/TextInput';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';

const QuestionBanded = ({ formikProps }) => {
  const {
    currentSlot,
    state: { currentSlotId }
  } = useAssessmentContext();
  const { content } = currentSlot.question;

  return (
    <TextInput
      description="Please enter your answer below"
      formikProps={formikProps}
      label={content[0] ? content[0].text_display : 'No label given'}
      name={currentSlotId}
      placeholder={content[0] && content[0].placeholder}
      showInitialErrors
    />
  );
};

export default QuestionBanded;
