import React from 'react';

export const skipTick = (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Group 23@1x</title>
    <g
      id="Test-taker-flow"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Game_Scenario1_trading1"
        transform="translate(-1141.000000, -75.000000)"
      >
        <g id="Group-11" transform="translate(212.000000, 32.000000)">
          <g id="Group-24" transform="translate(929.000000, 43.000000)">
            <g id="Group-23">
              <circle id="Oval" fill="#ABAFB7" cx="8" cy="8" r="8"></circle>
              <polygon
                id="Rectangle"
                fill="#FFFFFF"
                transform="translate(5.538462, 8.000000) rotate(-315.000000) translate(-5.538462, -8.000000) "
                points="3.07692308 5.53846154 8 5.53846154 8 10.4615385"
              ></polygon>
              <polygon
                id="Line-3"
                fill="#FFFFFF"
                fillRule="nonzero"
                points="11.1538462 4.61538462 11.1538462 11.3846154 9.15384615 11.3846154 9.15384615 4.61538462"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
