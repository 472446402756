import React from 'react';
import PropTypes from 'prop-types';
import SubmitButton from 'components/global/Buttons/SubmitButton';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';
import { FlexContainer } from 'components/local/styles/StyledComponents';
import {
  NavWrapper,
  QuestionCount
} from 'components/local/navbar/styles/StyledComponents';
import { getSubmitButtonText } from 'containers/app/helpers/getSubmitButtonText';
import { BUTTON_TEXTS } from 'containers/app/constants';
import { shouldSubmitAssessment } from 'containers/app/helpers/shouldSubmitAssessment';

const LinearBottomNavBar = ({
  background,
  flat,
  formikProps,
  minimal,
  setShowSummary
}) => {
  const { state, currentSection } = useAssessmentContext();
  const { currentSlotIndex, assessmentAttempt } = state;
  const {
    assessment: { sections: totalSections }
  } = assessmentAttempt;
  const slotNumber = currentSlotIndex + 1;
  const sectionLength = currentSection.slot_attempts.length;

  const isAssessmentReadyToSubmit = shouldSubmitAssessment(
    totalSections.length,
    currentSection.sort_index,
    sectionLength,
    currentSlotIndex
  );

  return (
    <NavWrapper background={background} flat={flat} height="72px">
      <FlexContainer
        width="100%"
        justifyContent="flex-end"
        alignItems="center"
        maxWidth="1024px"
      >
        {!minimal && (
          <QuestionCount>
            {slotNumber} of {sectionLength}
          </QuestionCount>
        )}
        <SubmitButton
          formikProps={formikProps}
          text={
            slotNumber === sectionLength
              ? getSubmitButtonText(!isAssessmentReadyToSubmit, true)
              : BUTTON_TEXTS.NEXT
          }
          setShowSummary={setShowSummary}
        />
      </FlexContainer>
    </NavWrapper>
  );
};

LinearBottomNavBar.propTypes = {
  background: PropTypes.string,
  flat: PropTypes.bool,
  formikProps: PropTypes.object.isRequired,
  minimal: PropTypes.bool,
  setShowSummary: PropTypes.func
};

LinearBottomNavBar.defaultProps = {
  background: '#ffffff',
  flat: false,
  minimal: false,
  setShowSummary: null
};

export default LinearBottomNavBar;
