import { putFileToSubmitted } from 'services/requests/slotRequests';

export const formatAnswer = async (value, currentSlot, select, banded) => {
  /* NOTE: this converts the answer into the format it should be in to submit
   the slot*/
  if (select) {
    const {
      question: { options, can_select_multiple }
    } = currentSlot;
    return options
      .filter(option => {
        if (option.content[0]) {
          const text_display = option.content[0].text_display;
          if (can_select_multiple) {
            return value.includes(text_display);
          } else {
            return value === text_display;
          }
        }
        return null;
      })
      .map(({ content, sort_index, value, ...id }) => id);
  } else if (value instanceof File) {
    try {
      const response = await putFileToSubmitted(currentSlot?.id, value);
      return {
        files: [{ id: response.data?.data?.id }],
        question_upload_id: currentSlot?.question.id
      };
    } catch (err) {
      console.error(err);
      return err;
    }
  } else {
    return banded ? value : null;
  }
};
