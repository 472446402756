import React from 'react';
import styled from 'styled-components';
import { useThemeContext } from 'services/context/themeContext/useThemeContext';
import { FlexContainer } from 'components/local/styles/StyledComponents';
import { AssessLogo } from 'containers/inProgressPage/styles/StyledComponents';
import { celebration } from './assets/celebration';
import CustomSubmission from 'containers/submittedPage/components/CustomSubmission';
import { useGetAssessmentInfo } from 'containers/landingPage/hooks/useGetAssessmentInfo';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';

const SubmittedPage = () => {
  const { themeState } = useThemeContext();
  const { assessment_detail_content } = useGetAssessmentInfo();
  const {
    assessmentAttempt: { external_application_redirect_url }
  } = useAssessmentContext();

  const renderSubmitPage = () => {
    if (assessment_detail_content.show_custom_completion_page) {
      if (external_application_redirect_url) {
        assessment_detail_content.completion_button_url = external_application_redirect_url;
      }

      return <CustomSubmission content={assessment_detail_content} />;
    } else {
      return (
        <FlexContainer
          direction="column"
          alignItems="center"
          justifyContent="center"
          height="100vh"
          id="complete-confirmation"
        >
          {themeState.showNavLogo && (
            <AssessLogo src={themeState.logoUrl} alt="logo" />
          )}
          {celebration}
          <CompletionText>We've received your submission</CompletionText>
          <CompletionSubText>
            Thank you for your participation
          </CompletionSubText>
        </FlexContainer>
      );
    }
  };

  // TODO: ADD CONDITIONAL BUTTON WITH LINK BACK TO PLATFORM BASED ON BACKEND CONFIG
  return renderSubmitPage();
};

export default SubmittedPage;

export const CompletionText = styled.div`
  margin: 30px 0 10px;
  text-transform: uppercase;
  font-size: 20px;
  font-family: ${props => props.theme.blackFontFamily};
  letter-spacing: 0.4px;
`;

export const CompletionSubText = styled.div`
  color: #a0a0ba;
  font-weight: 600;
`;
