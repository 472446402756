import { useEffect } from 'react';
import { useHandleRequest } from 'containers/inProgressPage/hooks/useHandleRequest';
import { useSetCurrentSlot } from 'containers/inProgressPage/hooks/useSetCurrentSlot';
import { attemptStateTypes } from 'containers/app/helpers/attemptStateTypes';

export const useHandleSlotRequests = () => {
  const {
    currentSlot,
    state: { currentSlotId }
  } = useSetCurrentSlot();
  const { handleStartSlot, handleGetSlot } = useHandleRequest();

  useEffect(() => {
    if (currentSlotId && currentSlot) {
      if (currentSlot.attempt_state === attemptStateTypes.PENDING) {
        handleStartSlot();
      } else {
        handleGetSlot();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlotId]);
};
