import PropTypes from 'prop-types';
import React from 'react';
import InputContainer from 'components/global/InputContainer';
import { useInputFocus } from 'components/global/hooks/useInputFocus';
import { useRenderErrors } from 'components/global/hooks/useRenderErrors';
import {
  Input,
  InputUnderlineInner,
  InputUnderlineOuter
} from '../styles/StyledComponents';

const types = Object.freeze({
  TEXT: 'text',
  PASSWORD: 'password',
  NUMBER: 'number'
});

const TextInput = ({
  capitalize,
  description,
  disabled,
  formikProps,
  label,
  name,
  optional,
  placeholder,
  showInitialErrors,
  size,
  type
}) => {
  const { focus, onFocus, onBlur } = useInputFocus();
  const { errors, handleChange, submitCount, values } = formikProps;
  const showErrors = useRenderErrors(
    errors,
    submitCount,
    name,
    showInitialErrors
  );

  return (
    <InputContainer
      disabled={disabled}
      capitalize={capitalize}
      optional={optional}
      label={label}
      description={description}
      showErrors={showErrors}
      errors={errors}
      name={name}
    >
      <Input
        className={showErrors ? `error ${size}` : `${size}`}
        disabled={disabled}
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        defaultValue={values[name]}
        onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
        role="textbox"
      />
      <InputUnderlineOuter className={showErrors && 'error'}>
        <InputUnderlineInner
          className={focus ? 'focus' : 'unfocus'}
        ></InputUnderlineInner>
      </InputUnderlineOuter>
    </InputContainer>
  );
};

TextInput.propTypes = {
  capitalize: PropTypes.bool,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  formikProps: PropTypes.object.isRequired,
  label: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  optional: PropTypes.bool,
  placeholder: PropTypes.string,
  showInitialErrors: PropTypes.bool,
  size: PropTypes.oneOf(['large', 'small']),
  type: PropTypes.oneOf(Object.values(types))
};

TextInput.defaultProps = {
  capitalize: false,
  description: '',
  disabled: false,
  label: '',
  optional: false,
  placeholder: 'placeholder',
  showInitialErrors: false,
  size: 'large',
  type: types.TEXT
};

export default TextInput;
