import styled from 'styled-components';

export const NavWrapper = styled.div`
  align-items: center;
  background: ${({ background, theme }) => background || theme.white};
  bottom: 0;
  box-shadow: ${({ flat }) => !flat && '0 4px 16px 0 rgba(0, 0, 0, 0.14)'};
  display: flex;
  height: ${({ height }) => height || '100px'};
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 4;
  padding: 0 16px;
`;

export const TopNavWrapper = styled(NavWrapper)`
  top: 0;
  box-shadow: none;
  z-index: 100;
  height: 50px;
  @media (max-width: 768px) {
    height: 50px;
  }
`;

export const QuestionCount = styled.div`
  color: #939393;
  line-height: 28px;
  margin: 5px 20px 0;
`;
