import styled from 'styled-components';
import { Underline } from 'components/global/progressBar/styles/StyledComponents';

export const SectionLandingBodyContainer = styled.div`
  font-family: ${props => props.theme.boldFontFamily};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 0 100px;
  @media (max-width: 667px) {
    padding: 40px 0 100px;
  }
`;

export const SectionLandingTopContainer = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 667px) {
    flex-direction: column;
  }
`;

export const SectionLandingTopWrapper = styled.div`
  flex-grow: 0.1;
  @media (max-width: 667px) {
    margin-bottom: 10px;
  }
`;

export const SectionLandingHeader = styled.div`
  color: ${({ color }) => color || '#5a5a5a'};
  height: 16px;
  font-size: 12px;
  letter-spacing: 1.5px;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
  @media (max-width: 667px) {
    margin-bottom: 3px;
  }
`;

export const SectionLandingInfo = styled.div`
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${props => props.theme.color};
`;

export const SectionLandingDescription = styled.div`
  color: ${props => props.theme.black};
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
`;

export const SectionLandingDivider = styled(Underline)`
  margin: 25px 0 40px;
  @media (max-width: 667px) {
    margin: 10px 0 20px;
  }
`;

export const QuestionNumber = styled.div`
  opacity: 0.6;
  color: ${props => props.theme.color};
  font-family: ${props => props.theme.condensedFontFamily};
  font-size: 26px;
  font-weight: 900;
  letter-spacing: 2.58px;
  line-height: 36px;
`;

export const AssessLogo = styled.img`
  position: fixed;
  left: 20px;
  top: 20px;
  height: 12px;
`;
