import React from 'react';

export const privateIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="112px"
    height="134px"
    viewBox="0 0 112 134"
    version="1.1"
  >
    <title>F2F0827C-BB0A-4E6C-8C7D-70BED393FD0E@1.5x</title>
    <g
      id="Error-Pages"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <g
        id="Private-and-Archived-Card-Copy"
        transform="translate(-664.000000, -248.000000)"
        stroke="#2D2A3E"
        strokeWidth="2.513513"
      >
        <g
          id="noun_hidden_1571311"
          transform="translate(666.000000, 250.000000)"
        >
          <path
            d="M97.4410595,95.6769224 C97.4410595,108.692047 93.5343169,118.409634 86.9877466,124.127806 L86.9701214,124.127806 C85.9104678,125.075465 84.7544539,125.910274 83.5208754,126.618659 L83.4679999,126.653671 C74.6337881,131.863298 62.31508,131.161631 48.7116202,123.338566 C25.1653959,109.779567 5.52587897,79.3991495 0.985529082,51.4043547 C0.341430356,47.5019039 0.0118675314,43.5543125 0,39.5994246 C0,22.6727585 6.59930478,11.2889055 17.0350101,7.07910177 L17.1758176,7.02653961 C18.4268164,6.53248336 19.7156365,6.13946088 21.0298257,5.85126944 C28.8786494,4.16743581 38.4168281,6.0091843 48.7117083,11.9379216 C68.8969006,23.5322341 86.2134199,47.5278253 93.6046765,71.7865786 C96.0481736,79.5203097 97.3407168,87.5693761 97.4410595,95.6769224 Z"
            id="Path"
            strokeLinejoin="round"
          />
          <path
            d="M108,90.4147337 C108,107.27127 101.435928,118.602561 91.0705823,122.847377 L90.9826151,122.882512 L83.5210164,126.618676 C84.7545949,125.910291 85.9106088,125.075483 86.9702624,124.127824 L86.9878876,124.127824 C93.5344579,118.409581 97.4412005,108.692082 97.4412005,95.67694 C97.3408578,87.5693937 96.0483146,79.5203273 93.6048175,71.7865961 C86.2134199,47.5278253 68.8969006,23.5322341 48.7117436,11.937904 C38.416881,6.00914916 28.8786846,4.16741824 21.029861,5.85125188 C19.7156718,6.13944331 18.4268517,6.5324658 17.1758529,7.02652205 L24.4791422,3.36048723 L24.5495019,3.3253521 C33.3838371,-1.86658394 45.6849024,-1.14745562 59.2706489,6.67568018 C86.1782753,22.1465748 108,59.6484796 108,90.4147337 Z"
            id="Path"
            fill="#2D2A3E"
            strokeLinejoin="round"
          />
          <path
            d="M81.8880266,86.6368813 L76.5348567,89.6764216 C61.34633,98.3004803 36.7208636,84.1294275 21.532337,58.0244811 L15.9488766,48.4280401 L21.3020465,45.3884998 C36.4905731,36.7644411 61.1160396,50.9354939 76.3045662,77.0404403 L81.8880266,86.6368813 Z"
            id="Path"
          />
          <path
            d="M40.6982832,61.4247714 C40.6982832,56.9897339 43.8373262,55.2073814 47.7166264,57.4397622"
            id="Path"
          />
          <path
            d="M62.9229193,75.5515715 C62.9229193,84.4144085 56.6384882,87.9827326 48.8862328,83.5216073 C41.1339774,79.060482 34.8495463,68.2592748 34.8495463,59.3964202 C34.8495463,50.5335655 41.1339774,46.9652591 48.8862328,51.4263844 C56.6384882,55.8875097 62.9229193,66.6887168 62.9229193,75.5515715 Z"
            id="Path"
          />
          <line
            x1="83.3576663"
            y1="126.714859"
            x2="14.4608715"
            y2="8.30946383"
            id="Path"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </g>
  </svg>
);
