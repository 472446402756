import React from 'react';
import FileUpload from 'components/global/fileUpload/FileUpload';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';

const QuestionFileUpload = ({ formikProps }) => {
  const {
    currentSlot,
    state: { currentSlotId }
  } = useAssessmentContext();
  const { content } = currentSlot.question;
  return (
    <FileUpload
      formikProps={formikProps}
      label={content[0] ? content[0].text_display : 'No label given'}
      name={currentSlotId}
      showInitialErrors
    />
  );
};

export default QuestionFileUpload;
