import React from 'react';
import styled from 'styled-components';
import {
  InputDescriptor,
  InputLabel,
  InputTitle,
  InputWrapper,
  OptionalLabel,
  ErrorSpan
} from 'components/global/styles/StyledComponents';
import { FlexContainer } from 'components/local/styles/StyledComponents';
import useParseHtml from './hooks/useParseHtml';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';
import { downloadFile } from 'services/requests/slotRequests';
import { downloadArrow } from 'components/global/assets/downloadArrow';

const InputContainer = ({
  children,
  disabled,
  capitalize,
  optional,
  label,
  description,
  showErrors,
  errors,
  name
}) => {
  const { dispatch, currentSlot } = useAssessmentContext();
  const files = currentSlot?.question?.files;
  const parsedHtml = useParseHtml(label);

  const getParsedFileName = rawFileName => {
    const nameList = rawFileName.split('/');
    return nameList[nameList.length - 1];
  };

  const displayFiles = files =>
    files?.length > 0 && (
      <DownloadFilesWrapper direction="row">
        {files?.map(questionFile => (
          <DownloadLink
            id="download-file-link"
            key={questionFile.id}
            onClick={() =>
              downloadFile(
                questionFile.gcs_link,
                getParsedFileName(questionFile.file_name),
                dispatch
              )
            }
          >
            {downloadArrow}
            <DownloadFile>
              {getParsedFileName(questionFile.file_name)}
            </DownloadFile>
          </DownloadLink>
        ))}
      </DownloadFilesWrapper>
    );

  return (
    <InputWrapper className={disabled && 'disabled'}>
      {label && (
        <InputTitle>
          <InputLabel className={capitalize && 'capitalized'}>
            {parsedHtml}
          </InputLabel>
          {displayFiles(files)}
          {optional && <OptionalLabel>(Optional)</OptionalLabel>}
        </InputTitle>
      )}
      {description && <InputDescriptor>{description}</InputDescriptor>}
      {children}
      <ErrorSpan>
        {showErrors ? errors[name] : <span>error placeholder</span>}
      </ErrorSpan>
    </InputWrapper>
  );
};

const DownloadFilesWrapper = styled(FlexContainer)`
  padding-bottom: 25px;
`;

const DownloadFile = styled.span`
  cursor: pointer;
  margin-right: 16px;
  user-select: none;
`;

const DownloadLink = styled.a`
  display: flex;
`;

export default InputContainer;
