import { attemptStateTypes } from 'containers/app/helpers/attemptStateTypes';
import { updateAssessmentState } from 'services/requests/assessmentRequests';
import { MOVE_TO_NEXT_SECTION } from 'services/context/assessmentContext/assessmentContext';

export const moveToNextSection = (sectionIndex, assessment, dispatch) => {
  const {
    uuid,
    section_attempts,
    assessment: { slug }
  } = assessment;
  const currentSection = section_attempts[sectionIndex];

  if (
    sectionIndex === section_attempts[section_attempts.length - 1].sort_index
  ) {
    dispatch({
      type: MOVE_TO_NEXT_SECTION,
      payload: {
        assessment,
        currentSectionIndex: null,
        sectionIsLoading: true
      }
    });
    updateAssessmentState(slug, uuid, attemptStateTypes.SUBMITTED, dispatch);
  } else {
    dispatch({
      type: MOVE_TO_NEXT_SECTION,
      payload: {
        assessment,
        currentSectionIndex: currentSection.sort_index + 1,
        sectionIsLoading: false
      }
    });
  }
};
