import React from 'react';

export const upArrowPortfolio = (
  <svg
    width="8px"
    height="8px"
    viewBox="0 0 7 7"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Group 43</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Game_Scenario3_trading-reivew_optionB_portfolio-value"
        transform="translate(-1184.000000, -868.000000)"
        stroke="#6EBC96"
      >
        <g id="Group-39" transform="translate(211.000000, 604.000000)">
          <g
            id="Group-40-Copy-13"
            transform="translate(976.500000, 267.500000) rotate(-45.000000) translate(-976.500000, -267.500000) translate(972.000000, 263.000000)"
          >
            <g id="Group-43" transform="translate(0.000000, -0.000000)">
              <line
                x1="4.53262773"
                y1="8.06056576"
                x2="4.5"
                y2="1"
                id="Line-3"
                strokeLinecap="square"
              ></line>
              <polyline
                id="Rectangle"
                transform="translate(4.500000, 4.000000) rotate(-135.000000) translate(-4.500000, -4.000000) "
                points="7 1.5 7 6.5 2 6.5"
              ></polyline>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
