import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import styled from 'styled-components';

const MaintenanceBanner = () => {
  const {
    proctorIsMaintenanceScheduled: maintenanceHeaderMessage
  } = useFlags();
  if (!maintenanceHeaderMessage) return null;
  return (
    <Banner>
      <strong>{maintenanceHeaderMessage}</strong>
      <div>
        Any assessments in progress during the maintenance window will be
        interrupted.
      </div>
      <div>Please reach out to support@correlation-one.com for support.</div>
    </Banner>
  );
};

export default MaintenanceBanner;

const Banner = styled.div`
  background: #eedcd3;
  border: 1px solid #dfae48;
  border-radius: 2px;
  color: #c35a22;
  font-size: 16px;
  padding: 14px 24px;
  position: relative;
  text-align: center;
  z-index: 999;
  strong {
    display: block;
    margin-bottom: 4px;
  }
`;
