import React from 'react';

export const questionMark = (
  <svg
    width="26px"
    height="26px"
    viewBox="0 0 26 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1374.000000, -837.000000)" fill="#2C374B">
        <g id="Group-3" transform="translate(1374.000000, 837.000000)">
          <g id="Group-9">
            <path d="M13,0 C20.1797017,0 26,5.82029825 26,13 C26,20.1797017 20.1797017,26 13,26 C5.82029825,26 0,20.1797017 0,13 C0,5.82029825 5.82029825,0 13,0 Z M12.9894737,16.9781421 C11.9368421,16.9781421 11.0526316,17.8743169 11.0526316,18.989071 C11.0526316,20.147541 11.9368421,21 12.9894737,21 C14.0421053,21 14.9263158,20.1256831 14.9263158,18.989071 C14.9263158,17.852459 14.0210526,16.9781421 12.9894737,16.9781421 Z M13.2631579,5 C10.2947368,5 8.29473684,6.70491803 8,9.7431694 L11.2421053,9.96174863 C11.3684211,8.71584699 12.1684211,8.01639344 13.3263158,8.01639344 C14.2315789,8.01639344 14.8421053,8.6284153 14.8421053,9.52459016 C14.8421053,10.0710383 14.5263158,10.5519126 13.9578947,11.0983607 L12.1684211,12.8032787 C11.5368421,13.4153005 11.4105263,14.0273224 11.4105263,14.9453552 L11.4105263,15.7540984 L14.5684211,15.7540984 L14.5684211,15.2076503 C14.5684211,14.420765 14.8631579,14.0491803 15.5157895,13.5027322 C17.1789474,12.1038251 18,11.1857923 18,9.30601093 C18,6.57377049 15.9157895,5 13.2631579,5 Z"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
