import React from 'react';
import Loading from 'components/local/loading/Loading';
import { PageWrapper } from 'components/local/styles/StyledComponents';
import LoadingSpinner from 'components/global/Buttons/components/LoadingSpinner';

const PageLoading = () => {
  return (
    <PageWrapper>
      <div className="container-fluid container-height middle-xs">
        <div className="row">
          <div direction="column" className="col-xs-12">
            <Loading delay={false} />
            <div
              style={{
                display: 'flex',
                gap: '5px',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <LoadingSpinner className={'inverted'} />
              <p>Loading</p>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default PageLoading;
