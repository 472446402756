import React from 'react';
import Button from 'components/global/Buttons/Button';
import StockSummary from 'containers/inProgressPage/subcomponents/stock/StockSummary';

const LinkedStockSummary = ({
  data,
  startingBalance,
  showSummary,
  setShowSummary
}) => {
  return (
    <>
      <p>
        <Button
          onClick={() => setShowSummary(!showSummary)}
          text="View Trading Summary"
        />
      </p>
      {showSummary && (
        <StockSummary currentSlot={data} startingBalance={startingBalance} />
      )}
    </>
  );
};

export default LinkedStockSummary;
