import React from 'react';
import InputContainer from 'components/global/InputContainer';
import { useRenderErrors } from 'components/global/hooks/useRenderErrors';
import { VideoUploadInput } from './VideoUploadInput';

const VideoUpload = ({
  formikProps,
  name,
  showInitialErrors,
  disabled,
  capitalize,
  optional,
  label,
  description
}) => {
  const { errors, handleChange, submitCount } = formikProps;

  const showErrors = useRenderErrors(
    errors,
    submitCount,
    name,
    showInitialErrors
  );

  return (
    <InputContainer
      disabled={disabled}
      capitalize={capitalize}
      optional={optional}
      label={label}
      description={description}
      showErrors={showErrors}
      errors={errors}
      name={name}
    >
      <VideoUploadInput
        handleChange={handleChange}
        formikProps={formikProps}
        name={name}
      />
    </InputContainer>
  );
};

export default VideoUpload;
