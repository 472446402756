import { QuestionTypes } from 'containers/app/helpers/questionTypes';

export const determineAnswerType = (question, answer, fileResponse) => {
  if (!question) {
    return null;
  }

  const { question_type } = question;

  let answerKey;
  switch (question_type) {
    case QuestionTypes.SELECT:
      answerKey = 'options';
      break;
    case QuestionTypes.BANDED:
      answerKey = 'answer_value';
      answer = parseFloat(answer);
      if (isNaN(answer)) answer = null;
      break;
    case QuestionTypes.UPLOAD:
      answerKey = 'answer_upload';
      break;
    default:
      answerKey = null;
  }
  let answer_postfix = question_type
    .split('_')
    .splice(1)
    .join('_');

  if (question_type === QuestionTypes.UPLOAD) {
    return {
      answer_type: `answer_${answer_postfix}`,
      question_upload_id: answer?.question_upload_id,
      files: answer?.files
    };
  }

  return {
    answer_type: `answer_${answer_postfix}`,
    [answerKey]: answer
  };
};
