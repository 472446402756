import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  color: ${({ theme }) => theme.color};
`;

export const screenSize = (
  <Svg
    width="87px"
    height="84px"
    viewBox="0 0 87 84"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-178.000000, -305.000000)">
        <g transform="translate(59.000000, 306.000000)">
          <g transform="translate(120.000000, 0.000000)">
            <g>
              <rect
                stroke="#373440"
                strokeWidth="2"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="76.9894483"
                height="62.5776304"
                rx="2"
              ></rect>
              <g>
                <path
                  d="M2,0 L74.9894483,0 C76.0940178,-2.02906125e-16 76.9894483,0.8954305 76.9894483,2 L76.9894483,13.2740428 L76.9894483,13.2740428 L0,13.2740428 L0,2 C-3.57315355e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z"
                  stroke="#373440"
                  strokeWidth="2"
                  fill="#939393"
                ></path>
                <circle
                  stroke="#373440"
                  fill="#373440"
                  cx="5.68887549"
                  cy="6.82665059"
                  r="1.01703346"
                ></circle>
                <circle
                  stroke="#373440"
                  fill="#373440"
                  cx="11.377751"
                  cy="6.82665059"
                  r="1.01703346"
                ></circle>
                <circle
                  stroke="#373440"
                  fill="#373440"
                  cx="17.0666265"
                  cy="6.82665059"
                  r="1.01703346"
                ></circle>
                <rect
                  fill="#373440"
                  x="31.099186"
                  y="6.06813386"
                  width="39.4428701"
                  height="2.517033"
                  rx="1.2585165"
                ></rect>
              </g>
              <g transform="translate(10.239976, 19.724080)" stroke="#373440">
                <polyline
                  strokeWidth="2"
                  fill="#DADCDF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  points="1.51703346 10.6192342 1.51703346 0 54.9924631 0 54.9924631 34.3202376 1.51703346 34.3202376 1.51703346 21.9969852"
                ></polyline>
                <circle
                  fill="#373440"
                  cx="1.51703346"
                  cy="17.063982"
                  r="1.01703346"
                ></circle>
              </g>
            </g>
            <g
              transform="translate(45.511004, 41.718420)"
              stroke="#373440"
              strokeWidth="2"
            >
              <rect
                fill="#DADCDF"
                x="3.03406693"
                y="3.22369611"
                width="32.9954778"
                height="32.9954778"
                rx="2"
              ></rect>
              <rect
                fill="currentColor"
                x="0"
                y="0.379258366"
                width="6.06813386"
                height="6.06813386"
                rx="2"
              ></rect>
              <rect
                fill="currentColor"
                x="32.9954778"
                y="0"
                width="6.06813386"
                height="6.06813386"
                rx="2"
              ></rect>
              <rect
                fill="currentColor"
                x="32.9954778"
                y="33.3747362"
                width="6.06813386"
                height="6.06813386"
                rx="2"
              ></rect>
              <rect
                fill="currentColor"
                x="0"
                y="32.9954778"
                width="6.06813386"
                height="6.06813386"
                rx="2"
              ></rect>
              <g
                transform="translate(9.481459, 10.619234)"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <g transform="translate(0.000000, 0.000000)">
                  <polyline
                    transform="translate(3.034067, 3.034067) rotate(-90.000000) translate(-3.034067, -3.034067) "
                    points="2.94431146e-13 7.02413034e-13 6.06813386 7.02413034e-13 6.06813386 6.06813386"
                  ></polyline>
                  <line x1="0" y1="0" x2="6.06813386" y2="6.06813386"></line>
                </g>
                <g transform="translate(16.600693, 3.500000) rotate(-270.000000) translate(-16.600693, -3.500000) translate(13.100693, -0.000000)">
                  <polyline
                    transform="translate(3.034067, 3.034067) rotate(-90.000000) translate(-3.034067, -3.034067) "
                    points="2.76223489e-13 -6.3873638e-13 6.06813386 -6.3873638e-13 6.06813386 6.06813386"
                  ></polyline>
                  <line x1="0" y1="0" x2="6.06813386" y2="6.06813386"></line>
                </g>
                <g transform="translate(16.600693, 15.083660) rotate(-180.000000) translate(-16.600693, -15.083660) translate(13.100693, 11.583660)">
                  <polyline
                    transform="translate(3.034067, 3.034067) rotate(-90.000000) translate(-3.034067, -3.034067) "
                    points="2.98259524e-13 7.01080766e-13 6.06813386 7.01080766e-13 6.06813386 6.06813386"
                  ></polyline>
                  <line x1="0" y1="0" x2="6.06813386" y2="6.06813386"></line>
                </g>
                <g transform="translate(3.500000, 15.083660) rotate(-90.000000) translate(-3.500000, -15.083660) translate(0.000000, 11.583660)">
                  <polyline
                    transform="translate(3.034067, 3.034067) rotate(-90.000000) translate(-3.034067, -3.034067) "
                    points="1.39972313e-13 8.33761581e-13 6.06813386 8.33761581e-13 6.06813386 6.06813386"
                  ></polyline>
                  <line x1="0" y1="0" x2="6.06813386" y2="6.06813386"></line>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Svg>
);
