import React from 'react';

export const alertIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="112px"
    height="134px"
    viewBox="0 0 112 134"
    version="1.1"
  >
    <title>DA5B712C-8B3F-4FC5-977C-8507ACC09FCB@1.5x</title>
    <g
      id="Error-Pages"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Slug-Doesnt-Exist-Card"
        transform="translate(-664.000000, -248.000000)"
        stroke="#2D2A3E"
        strokeWidth="2.513513"
      >
        <g
          id="noun_Alert_1571260"
          transform="translate(666.000000, 250.000000)"
        >
          <path
            d="M97.4410595,95.6769224 C97.4410595,108.692047 93.5343169,118.409634 86.9877466,124.127806 L86.9701214,124.127806 C85.9104678,125.075465 84.7544539,125.910274 83.5208754,126.618659 L83.4679999,126.653671 C74.6337881,131.863298 62.31508,131.161631 48.7116202,123.338566 C25.1653959,109.779567 5.52587897,79.3991495 0.985529082,51.4043547 C0.341430356,47.5019039 0.0118675314,43.5543125 0,39.5994246 C0,22.6727585 6.59930478,11.2889055 17.0350101,7.07910177 L17.1758176,7.02653961 C18.4268164,6.53248336 19.7156365,6.13946088 21.0298257,5.85126944 C28.8786494,4.16743581 38.4168281,6.0091843 48.7117083,11.9379216 C68.8969006,23.5322341 86.2134199,47.5278253 93.6046765,71.7865786 C96.0481736,79.5203097 97.3407168,87.5693761 97.4410595,95.6769224 Z"
            id="Path"
          />
          <path
            d="M108,90.4147337 C108,107.27127 101.435928,118.602561 91.0705823,122.847377 L90.9826151,122.882512 L83.5210164,126.618676 C84.7545949,125.910291 85.9106088,125.075483 86.9702624,124.127824 L86.9878876,124.127824 C93.5344579,118.409581 97.4412005,108.692082 97.4412005,95.67694 C97.3408578,87.5693937 96.0483146,79.5203273 93.6048175,71.7865961 C86.2134199,47.5278253 68.8969006,23.5322341 48.7117436,11.937904 C38.416881,6.00914916 28.8786846,4.16741824 21.029861,5.85125188 C19.7156718,6.13944331 18.4268517,6.5324658 17.1758529,7.02652205 L24.4791422,3.36048723 L24.5495019,3.3253521 C33.3838371,-1.86658394 45.6849024,-1.14745562 59.2706489,6.67568018 C86.1782753,22.1465748 108,59.6484796 108,90.4147337 Z"
            id="Path"
            fill="#2D2A3E"
          />
          <path
            d="M43.6306534,76.9931835 L54.2062145,83.0790627 C55.9683086,84.0930802 57.4320262,83.3813478 57.5621881,81.4472291 L60.4288165,38.8531544 C60.4035109,36.3985068 59.1426682,34.1207349 57.0728429,32.7904293 L40.7640251,23.4052733 C38.807631,22.2794031 37.263525,23.291699 37.4080514,25.6055231 L40.2746798,71.4988745 C40.5020045,73.7419794 41.7358255,75.7619577 43.6306534,76.9931835 Z"
            id="Path"
          />
          <path
            d="M43.8707083,104.062291 L53.9662125,109.871885 C55.8247516,110.941398 57.3313865,109.806129 57.3313865,107.336182 L57.3313865,98.9678721 C57.2927054,96.4206043 56.0440546,94.0426306 53.9662125,92.5591186 L43.8707083,86.7495246 C42.0121692,85.6800111 40.5055343,86.81528 40.5055343,89.285227 L40.5055343,97.6535371 C40.5442154,100.200805 41.7928662,102.578779 43.8707083,104.062291 L43.8707083,104.062291 Z"
            id="Path"
          />
        </g>
      </g>
    </g>
  </svg>
);
