import React from 'react';
import { displaySectionTime } from 'containers/inProgressPage/helpers/displaySectionTime';
import {
  SectionLandingTopContainer,
  SectionLandingTopWrapper,
  SectionLandingHeader,
  SectionLandingInfo,
  SectionLandingDivider
} from 'containers/inProgressPage/styles/StyledComponents';

const SectionLandingTop = ({ currentSection, isStock }) => {
  const { section } = currentSection;
  return (
    <>
      <SectionLandingTopContainer>
        <SectionLandingTopWrapper>
          <SectionLandingHeader
            fontWeight={isStock ? 'bold' : null}
            color={isStock ? '#85807c' : null}
          >
            section:
          </SectionLandingHeader>
          <SectionLandingInfo>
            {section.title || `Section ${currentSection.sort_index + 1}`}
          </SectionLandingInfo>
        </SectionLandingTopWrapper>
        <SectionLandingTopWrapper>
          <SectionLandingHeader
            fontWeight={isStock ? 'bold' : null}
            color={isStock ? '#85807c' : null}
          >
            duration:
          </SectionLandingHeader>
          <SectionLandingInfo>{displaySectionTime(section)}</SectionLandingInfo>
        </SectionLandingTopWrapper>
        {!isStock && (
          <SectionLandingTopWrapper>
            <SectionLandingHeader>number of questions:</SectionLandingHeader>
            <SectionLandingInfo>
              {section.standard_question_list.length}
            </SectionLandingInfo>
          </SectionLandingTopWrapper>
        )}
      </SectionLandingTopContainer>
      <SectionLandingDivider />
    </>
  );
};

export default SectionLandingTop;
