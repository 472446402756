import React from 'react';

export const starIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="99px"
    height="134px"
    viewBox="0 0 99 134"
    version="1.1"
  >
    <title>057AD4DE-3A83-4F67-BF04-CCFE452D1238@1.5x</title>
    <g
      id="Error-Pages"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <g
        id="Already-Completed-Card"
        transform="translate(-670.000000, -248.000000)"
        stroke="#2D2A3E"
        strokeWidth="2.513609"
      >
        <g
          id="noun_Favorite_1571218"
          transform="translate(672.000000, 250.000000)"
        >
          <path
            d="M40.9642948,23.5456523 L32.770265,41.1212678 C32.6765844,41.351287 32.4859537,41.5281606 32.2494739,41.6044754 C32.0129941,41.6807902 31.7548529,41.6487405 31.5442484,41.5169177 L13.0919961,33.019255 C11.7840763,32.4169346 11.2500266,34.1651306 12.1740087,36.0242337 L25.6273528,63.0928823 C25.9740239,63.7472741 26.1269469,64.4868297 26.0682132,65.22494 L22.906528,88.6674565 C22.6880667,90.2872301 24.1097263,92.5475873 25.2973491,92.4686717 L41.6494233,91.3821585 C42.3110676,91.4551813 42.8967814,91.8428084 43.2222847,92.4230826 L59.5743589,115.153197 C60.7619466,116.803996 62.1836062,116.425335 61.9651801,114.516426 L58.8034949,86.8891137 C58.6657031,86.3313323 58.83339,85.7423204 59.2443552,85.3405609 L72.6976994,76.0786883 C73.6216814,75.4425845 73.0876317,72.9875031 71.7797119,71.8586685 L53.3273893,55.9329909 C52.7399558,55.3863123 52.3155846,54.6876634 52.1013727,53.9145808 L43.9073253,25.4933686 C43.3222773,23.4640311 41.5493604,22.2907142 40.9642948,23.5456523 Z"
            id="Path"
          />
          <path
            d="M84.8741516,95.6216963 C84.8741516,111.555912 79.7936566,122.553507 71.6367373,127.332015 C68.3660583,129.212179 64.6307928,130.131682 60.8604625,129.98479 C55.2877396,129.844246 49.0469931,127.700944 42.4370758,123.326501 C32.188188,116.545236 22.7831194,105.530073 15.4348602,92.7229691 C6.01221206,76.2441441 0,56.7963197 0,39.4566605 C0,21.554824 6.41654211,9.87207492 16.384069,6.25305791 C23.574112,3.65298744 32.6276772,5.25167942 42.4369879,11.7518556 C60.069294,23.4170366 75.2053016,47.5730967 81.6042641,71.9399734 C83.6991235,79.6624744 84.7979843,87.6208269 84.8741516,95.6216963 Z"
            id="Path"
            strokeLinejoin="round"
          />
          <path
            d="M95,90.5445317 C95,107.286877 89.392118,118.600698 80.4968569,122.940004 L80.4968569,122.957572 L71.6367549,127.332015 C79.7936742,122.553507 84.8741692,111.555912 84.8741692,95.6216963 C84.7980305,87.6208307 83.6991992,79.6624782 81.6043696,71.9399734 C75.2054071,47.5729913 60.0693995,23.4169312 42.4370934,11.7518556 C32.6276947,5.25167942 23.5742175,3.65298744 16.3841744,6.25305791 L22.4842843,3.23135439 L22.5546026,3.1962183 C30.219294,-1.86337829 40.8197732,-1.07281632 52.5629242,6.692259 C75.9964876,22.1872736 95,59.730183 95,90.5445317 Z"
            id="Path"
            fill="#2D2A3E"
            strokeLinejoin="round"
          />
          <line
            x1="25.4024925"
            y1="1.79077481"
            x2="22.5546026"
            y2="3.1962183"
            id="Path"
            strokeLinejoin="round"
          />
          <line
            x1="16.3841744"
            y1="6.25305791"
            x2="13.4659662"
            y2="7.6936375"
            id="Path"
            strokeLinejoin="round"
          />
          <line
            x1="81.6043696"
            y1="122.412963"
            x2="80.4968569"
            y2="122.957572"
            id="Path"
            strokeLinejoin="round"
          />
          <line
            x1="71.6367549"
            y1="127.332015"
            x2="69.3338315"
            y2="128.45637"
            id="Path"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </g>
  </svg>
);
