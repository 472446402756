import styled from 'styled-components';
import { SelectInput, RowWrapper } from '../../styles/StyledComponents';

export const RadioContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: ${({ orientation }) =>
    orientation === 'horizontal' ? 'row' : 'column'};
  flex-wrap: wrap;
  @media (max-width: 667px) {
    flex-direction: column;
  }
`;

const adjustHex = (hex, amt) => {
  let pound = false;
  if (hex[0] === '#') {
    hex = hex.slice(1);
    pound = true;
  }
  let rgb = [
    parseInt(hex.substring(0, 2), 16),
    parseInt(hex.substring(2, 4), 16),
    parseInt(hex.substring(4, 6), 16)
  ];

  rgb = rgb.map(num => {
    num = num + amt;
    if (num > 255) {
      num = 255;
    } else if (num < 0) {
      num = 0;
    }
    const str = num.toString(16);
    return str.length === 1 ? `0${str}` : str;
  });

  return `${pound && '#'}${rgb.join('')}`;
};

export const RadioRowWrapper = styled(RowWrapper)`
  color: ${props =>
    props.option === props.value ? props.theme.color : props.theme.black};
  border-color: ${props =>
    props.option === props.value ? props.theme.color : '#C9C9C9'};
  &:hover {
    border-color: ${props => !props.checked && adjustHex('#C9C9C9', -40)};
  }
  &.secondary {
    border: none;
    margin: 0 6px 0 0;
  }
`;

export const RadioLabel = styled.label`
  font-family: ${props => props.theme.fontFamily};
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.89px;
  padding: 18px 16px 16px 50px;
  width: 100%;
  transition: all 150ms ease-out;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
  }
  &.secondary {
    padding: 16px 16px 8px 26px;
  }
`;

export const RadioInput = styled(SelectInput)`
  border-radius: 50%;
  &.secondary {
    left: 0px;
  }
  border-color: ${props =>
    props.option === props.value ? props.theme.color : '#C9C9C9'};

  &:not(:checked) {
    :hover {
      border-color: ${props => adjustHex('#C9C9C9', -40)};
    }
  }
`;
