import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { FlexContainer } from 'containers/inProgressPage/subcomponents/stock/StyledComponents';

const ChartToggles = ({
  showAssets,
  setShowAssets,
  startingBalance,
  setRange,
  omit,
  setOmit,
  bots,
  assets
}) => {
  const renderLineToggleButtons = () => {
    const toggleOptions = [];
    if (showAssets) {
      toggleOptions.push('you');
      bots.forEach(bot => toggleOptions.push(bot.bot.player_name));
    } else {
      assets.forEach(asset =>
        toggleOptions.push(asset.question_stock_asset.ticker_name)
      );
    }

    return (
      toggleOptions.length > 1 &&
      toggleOptions.map(option => {
        return (
          <ToggleButton
            onClick={() => handleActionToggle(option)}
            key={option}
            className="line"
            selected={!omit.includes(option)}
          >
            {option}
          </ToggleButton>
        );
      })
    );
  };

  const handleActionToggle = toggleOption => {
    omit.includes(toggleOption)
      ? setOmit(omit.filter(omittedLine => omittedLine !== toggleOption))
      : setOmit([...omit, toggleOption]);
  };

  const showMarketPrice = () => {
    setShowAssets(true);
    setRange({ max: 120, min: 80 });
  };

  const showPortfolioValue = () => {
    setShowAssets(false);
    setRange({ max: startingBalance + 20, min: startingBalance - 80 });
  };

  return (
    <ToggleContainer alignItems="center">
      <LineButtons>{renderLineToggleButtons()}</LineButtons>
      <ChartButton direction="column" alignItems="center">
        <ToggleButton
          className="chart"
          selected={showAssets}
          onClick={() => showMarketPrice()}
        >
          market price
        </ToggleButton>
        <Pointer selected={showAssets} />
      </ChartButton>
      <ChartButton direction="column" alignItems="center">
        <ToggleButton
          className="chart selected"
          selected={!showAssets}
          onClick={() => showPortfolioValue()}
        >
          portfolio value
        </ToggleButton>
        <Pointer selected={!showAssets} />
      </ChartButton>
    </ToggleContainer>
  );
};

ChartToggles.propTypes = {
  showAssets: PropTypes.bool.isRequired,
  setShowAssets: PropTypes.func.isRequired,
  startingBalance: PropTypes.number.isRequired,
  setRange: PropTypes.func.isRequired,
  omit: PropTypes.arrayOf(PropTypes.string).isRequired,
  setOmit: PropTypes.func.isRequired,
  bots: PropTypes.arrayOf(PropTypes.object).isRequired,
  assets: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ChartToggles;

const ToggleContainer = styled(FlexContainer)`
  margin-left: auto;
`;

const LineButtons = styled(FlexContainer)`
  margin-right: 33px;
`;

const ChartButton = styled(FlexContainer)`
  margin-bottom: 0;
`;

const ToggleButton = styled.div`
  text-align: center;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  &.chart {
    padding: 5px 8px;
    margin: auto 8px 0;
    ${({ selected }) =>
      selected &&
      `background: #2c374b;
      color: #f7f6f6;`};
  }
  &.line {
    border: 1px solid #808793;
    border-radius: 10.5px;
    margin: auto 5px;
    padding: 2px;
    height: 21px;
    width: 64px;
    ${({ selected }) =>
      selected &&
      `background: #2c374b;
      color: #f7f6f6;`};
  }
`;

const Pointer = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 7px solid #2c374b;
  ${({ selected }) => !selected && `visibility: hidden`}
`;
