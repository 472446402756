import { useState, useEffect } from 'react';

export const useDisableSubmit = (errors, submitCount) => {
  const [disableSubmit, setDisableSubmit] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length && submitCount) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  }, [submitCount, errors]);

  return disableSubmit;
};
