import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import ChartContainer from 'components/global/charts/ChartContainer';
import ExecutionWindow from 'containers/inProgressPage/subcomponents/stock/ExecutionWindow';
import GameCompletedNotification from 'containers/inProgressPage/subcomponents/stock/GameCompletedNotification';
import InstructionsTooltip from 'containers/inProgressPage/subcomponents/stock/InstructionsTooltip';
import MultiBalanceTable from 'containers/inProgressPage/subcomponents/stock/MultiBalanceTable';
import PostStock from 'containers/inProgressPage/subcomponents/stock/PostStock';
import ProgressBarStock from 'components/global/progressBar/ProgressBarStock';
import QuestionStockInstructions from 'containers/inProgressPage/subcomponents/stock/QuestionStockInstructions';
import TransactionTable from 'components/global/tables/TransactionTable';
import { findBalance } from 'containers/inProgressPage/subcomponents/stock/helpers/findBalance';
import { findPortfolio } from 'containers/inProgressPage/subcomponents/stock/helpers/findPortfolio';
import { PlayerTypes } from 'containers/app/helpers/playerTypes';
import { NotificationTypes } from 'containers/app/helpers/notificationTypes';
import { StockBalanceTypes } from 'containers/app/helpers/stockBalanceTypes';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';
import { useHandleRequest } from 'containers/inProgressPage/hooks/useHandleRequest';

const QuestionStock = () => {
  const {
    currentSlot,
    currentSection: { section },
    assessmentAttempt: { requestTime }
  } = useAssessmentContext();

  const { handleCreateStockTick, handleCreateStockAction } = useHandleRequest();
  const [nextTickLoading, setNextTickLoading] = useState(false);
  const nextTickLoadingRef = useRef();
  const [completed, setCompleted] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [timeToRedirect, setTimeToRedirect] = useState(5);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [tickTimeout, setTickTimeout] = useState(null);
  const [currentTick, setCurrentTick] = useState(null);
  const [notification, setNotification] = useState(NotificationTypes.NONE);
  const currentTickRef = useRef();
  const { answer, question } = currentSlot;
  const {
    has_insurance,
    insurance_delta,
    insurance_premium,
    tick_count,
    tick_time_ms,
    starting_balance,
    is_tradeable
  } = question;
  const {
    balances,
    portfolios,
    tick_indexes: currentTicks,
    actions,
    answer_stock_assets,
    bots
  } = answer;

  const findCurrentTick = () => {
    // Find the in progress tick
    return currentTicks.find(
      tick => tick.started_at !== null && tick.submitted_at === null
    );
  };

  useEffect(() => {
    // Show summary view if all ticks or all but the last tick are completed
    if (
      (showSummary === false &&
        currentTicks.find(tick => tick.submitted_at === null) === undefined) ||
      (currentTicks[currentTicks.length - 1].started_at &&
        !currentTicks[currentTicks.length - 1].submitted_at)
    ) {
      setShowSummary(true);
    }

    // Find the current tick
    const tick = findCurrentTick();

    /* Set current tick to state, if no ticks are currently in progress
     (e.g. none started or all submitted) then set currentTick to null */
    setCurrentTick(tick || null);

    return () => clearTimeout(tickTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    currentTickRef.current = currentTick;
    // Need to set time remaining after currentTick to prevent race conditions
    if (currentTick) {
      // Get the expiration time for the current tick
      const expiresAt = Date.parse(currentTick.expires_at + '+00:00');
      const serverTime = Date.parse(currentTick.server_time + '+00:00');
      const currentTimeRemaining = expiresAt - serverTime - requestTime;
      if (currentTimeRemaining <= 0) {
        // If the tick is past expiration, request to submit and begin the next tick
        updateTick(currentTick._index)();
      } else {
        // Otherwise set the time remaining on the tick to state
        setTimeRemaining(currentTimeRemaining);
        setTickTimeout(
          setTimeout(() => {
            updateTick(currentTick._index)();
          }, currentTimeRemaining)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTick]);

  useEffect(() => {
    nextTickLoadingRef.current = nextTickLoading;
    const tick = findCurrentTick();
    if (tick) {
      // If second to last tick is submitted, start final countdown
      if (tick._index >= tick_count) {
        setCurrentTick(null);
        setCompleted(true);
        countDownAndUpdate();
      } else {
        setCurrentTick(tick);
      }
    } else {
      if (
        !completed &&
        currentTicks[currentTicks.length - 1].submitted_at !== null
      ) {
        setShowSummary(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextTickLoading]);

  useEffect(() => {
    // First check to see if any balances exist
    if (balances.length) {
      // Then find the last balance related to the player and see they went bankrupt
      let bankruptBalance = balances
        .reverse()
        .find(balance => balance.player_type === PlayerTypes.TAKER).balance;
      if (bankruptBalance.balance_type === StockBalanceTypes.BANKRUPT) {
        // Show a notification that will disappear after 20 seconds or when clicked
        setNotification(
          bankruptBalance.from_max_downturn
            ? NotificationTypes.DRAWDOWN_LIMIT
            : NotificationTypes.BANKRUPTCY
        );
        setTimeout(() => setNotification(NotificationTypes.NONE), 20000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balances.length]);

  const countDownAndUpdate = () => {
    // Starts countdown timer and makes final tick request
    let start = 5;
    let countDown = setInterval(() => {
      if (start === 1) {
        handleCreateStockTick().then(() => {
          clearInterval(countDown);
          setShowSummary(true);
        });
      }
      setTimeToRedirect((start -= 1));
    }, 1000);
  };

  const updateTick = tick_index_before => () => {
    /* 
    With tick_index_before here we don't need to clear timeout because it
    makes sure we don't updateTick more than once for an old timer.
    */
    if (
      !nextTickLoadingRef.current &&
      ((currentTickRef.current &&
        currentTickRef.current._index <= tick_count - 1 &&
        currentTickRef.current._index === tick_index_before) ||
        (!currentTickRef.current && !completed))
    ) {
      setNextTickLoading(true);
      // Send request to update tick
      return handleCreateStockTick().then(() => {
        setNextTickLoading(false);
        return true;
      });
    }
    return Promise.resolve(null);
  };

  const stockTransaction = (direction, quantity, assetId, actionType) => {
    // Find asset for action
    let asset = answer.answer_stock_assets.find(asset => asset.id === assetId);
    // If there is an action quantity, send action request
    if (parseInt(quantity)) {
      return handleCreateStockAction({
        answer_stock_tick_id: asset.ticks[asset.ticks.length - 1].id,
        answer_stock_asset_id: asset.id,
        stock_action: {
          action_type: actionType,
          quantity: quantity,
          side: direction
        },
        player_type: PlayerTypes.TAKER
      });
    }
    return Promise.resolve(null);
  };

  const renderInProgressStock = () => {
    return (
      <>
        <ProgressBarFixed>
          {currentTick && (
            <ProgressBarStock
              currentTicks={currentTicks}
              currentTick={currentTick}
              tickTime={tick_time_ms}
              timeRemaining={timeRemaining}
              tickCount={tick_count}
              updateTick={updateTick(currentTick ? currentTick._index : null)}
            />
          )}
          <div className="row">
            <div className="col-xs-9">
              {completed && <GameCompletedNotification time={timeToRedirect} />}
            </div>
          </div>
        </ProgressBarFixed>
        <div className="row between-xs">
          <div className={is_tradeable ? 'col-xs-9' : 'col-xs-12'}>
            <div className="row">
              <ChartContainer
                tickIndexes={currentTicks}
                assets={answer_stock_assets}
                actions={actions}
                ticks={answer_stock_assets[0].ticks}
                tickCount={tick_count}
                insuranceBalance={findBalance(balances, {
                  insuranceDelta: insurance_delta
                })}
                bots={bots}
                notification={notification}
                setNotification={setNotification}
                insurancePremium={insurance_premium}
                insuranceDelta={insurance_delta}
              />
            </div>
            {(is_tradeable || !!answer.bots.length) && (
              <div className="row">
                <div className="col-xs-7">
                  <MultiBalanceTable
                    assets={answer_stock_assets}
                    hasInsurance={has_insurance}
                    insuranceDelta={insurance_delta}
                    balances={balances}
                    portfolios={portfolios}
                    bots={answer.bots}
                    startingBalance={
                      starting_balance ?? section.starting_balance
                    }
                  />
                </div>
                <div className="col-xs-5">
                  <TransactionTable
                    assets={answer_stock_assets}
                    actions={actions}
                    insurancePremium={insurance_premium}
                    bots={bots}
                  />
                </div>
              </div>
            )}
          </div>
          {is_tradeable && (
            <ExecutionWindowContainer className="col-xs-3 execution-window">
              <ExecutionWindow
                stockTransaction={stockTransaction}
                assets={answer_stock_assets}
                type="stock"
                balances={balances}
                actions={actions}
                disabled={completed}
                currentPortfolio={findPortfolio(portfolios)}
              />
              {has_insurance && (
                <ExecutionWindow
                  stockTransaction={stockTransaction}
                  assets={answer_stock_assets}
                  type="insurance"
                  balances={balances}
                  actions={actions}
                  premium={insurance_premium}
                  disabled={completed}
                  insuranceBalance={findBalance(balances, {
                    insuranceDelta: insurance_delta
                  })}
                />
              )}
            </ExecutionWindowContainer>
          )}
        </div>
        <InstructionsTooltip question={question} />
      </>
    );
  };

  return (
    <StockWrapper>
      {answer && !answer.tick_indexes[0].started_at && question ? (
        <QuestionStockInstructions
          question={question}
          updateTick={updateTick(currentTick ? currentTick._index : null)}
        />
      ) : !showSummary ? (
        renderInProgressStock()
      ) : (
        <PostStock
          currentSlot={currentSlot}
          startingBalance={starting_balance ?? section.starting_balance}
        />
      )}
    </StockWrapper>
  );
};

export default QuestionStock;

const StockWrapper = styled.div`
  font-family: ${({ theme }) => theme.altFontFamily};
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 16px;
`;

const ProgressBarFixed = styled.div`
  position: fixed;
  width: 1024px;
  top: 50px;
  background: #efeeed;
  z-index: 1;
`;

const ExecutionWindowContainer = styled.div`
  &.execution-window {
    margin-top: 112px;
    padding-right: 0px;
  }
`;
