import React, { useEffect } from 'react';
import styled from 'styled-components';
import SectionLandingTop from 'containers/inProgressPage/subcomponents/SectionLandingTop';
import { parseHtml } from 'containers/app/helpers/parseHtml';
import { useHandleRequest } from 'containers/inProgressPage/hooks/useHandleRequest';
import { FlexContainer } from 'components/local/styles/StyledComponents';

const SectionStockLanding = ({ currentSection }) => {
  const { handleGetStockSectionMeta } = useHandleRequest();
  const { meta, section } = currentSection;

  useEffect(() => {
    handleGetStockSectionMeta();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <SectionLandingTop currentSection={currentSection} isStock={true} />
      <Description>{parseHtml(section.description)}</Description>
      <SummaryWrapper>
        <div>
          <FlexContainer alignItems="flex-end">
            Scenarios in this section
            <SmallText>
              Each scenario has its own context, please read carefully.
            </SmallText>
          </FlexContainer>
          {meta &&
            meta.map(({ id, title }, index) => (
              <Row key={id}>
                <Cell>
                  <RowCount>{index + 1}</RowCount>
                  {title}
                </Cell>
              </Row>
            ))}
        </div>
      </SummaryWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.altFontFamily};
  font-size: 14px;
  letter-spacing: 0.2px;
`;

const SummaryWrapper = styled.div`
  align-items: center;
  background: #f7f7f7;
  border: 1px solid #d0d0d0;
  display: flex;
  font-weight: 500;
  justify-content: space-around;
  margin: 30px 0;
  padding: 20px 16px;
  width: fit-content;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const Row = styled.div`
  align-items: center;
  display: flex;
  margin: 10px 0;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const Cell = styled.div`
  background: #f2f2f2;
  border: 1px solid #dfddda;
  border-radius: 4px 0 0 4px;
  font-size: 12px;
  height: 30px;
  letter-spacing: 0.4px;
  padding: 8px 20px;
  width: 100%;
`;

const RowCount = styled.span`
  background: #6ebc96;
  border-radius: 50px;
  color: ${({ theme }) => theme.white};
  font-weight: bold;
  margin-right: 10px;
  padding: 1px 5px;
`;

const SmallText = styled.div`
  color: #6ebc96;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 14px;
  margin: 0 10px;
`;

const Description = styled.div`
  color: #2c374b;
  opacity: 70%;
  div {
    font-weight: bold;
  }
  ol {
    counter-reset: item;
    padding-left: 25px;
  }
  li {
    counter-increment: item;
    list-style-type: none;
    margin: 15px 0;
  }
  li:before {
    content: counter(item) '.';
    font-weight: bold;
    margin-left: -25px;
    padding-right: 15px;
  }
`;

export default SectionStockLanding;
