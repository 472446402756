import PropTypes from 'prop-types';
import React from 'react';
import ProgressSlot from './ProgressSlot';
import { SlotContainer } from './styles/StyledComponents';

const ProgressBarClickable = ({
  currentSection,
  currentSlotIndex,
  disabled,
  formikProps,
  setSlot
}) => {
  const renderSlots = () => {
    return currentSection.slot_attempts.map(slot => {
      return (
        <ProgressSlot
          key={slot.sort_index}
          index={slot.sort_index}
          attemptState={slot.attempt_state}
          formikProps={formikProps}
          setSlot={setSlot}
          currentSection={currentSection}
          currentSlotIndex={currentSlotIndex}
        />
      );
    });
  };

  return <SlotContainer disabled={disabled}>{renderSlots()}</SlotContainer>;
};

ProgressBarClickable.propTypes = {
  currentSection: PropTypes.object.isRequired,
  currentSlotIndex: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  formikProps: PropTypes.object,
  setSlot: PropTypes.func
};

export default ProgressBarClickable;
