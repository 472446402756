import React from 'react';

export const checkMark = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 24 24"
  >
    <path
      d="M9 21.035l-9-8.638 2.791-2.87 6.156 5.874 12.21-12.436
     2.843 2.817z"
    />
  </svg>
);
