import React from 'react';
import pluralize from 'pluralize';
import styled from 'styled-components';
import Button from 'components/global/Buttons/Button';
import { attemptStateTypes } from 'containers/app/helpers/attemptStateTypes';
import { displaySectionTime } from 'containers/inProgressPage/helpers/displaySectionTime';
import { useHandleRequest } from 'containers/inProgressPage/hooks/useHandleRequest';
import MobileWarning from 'components/local/modals/MobileWarning';
import { checkMark } from 'components/global/progressBar/assets/checkMark';
import {
  PageWrapper,
  FlexContainer
} from 'components/local/styles/StyledComponents';
import {
  ProgressCircle,
  CheckMark
} from 'components/global/progressBar/styles/StyledComponents';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';

const PausedPage = () => {
  const { handleUpdateAssessment } = useHandleRequest();
  const {
    assessmentAttempt: { section_attempts }
  } = useAssessmentContext();

  const renderStatus = (attemptState, isFirstPendingSection) => {
    if (isFirstPendingSection) {
      return (
        <Button
          text="RESUME"
          onClick={() => handleUpdateAssessment(attemptStateTypes.IN_PROGRESS)}
        />
      );
    } else if (attemptState === attemptStateTypes.SUBMITTED) {
      return (
        <ProgressCircle color="#7ebf5a">
          <CheckMark>{checkMark}</CheckMark>
        </ProgressCircle>
      );
    }
    return null;
  };

  const displaySectionData = () => {
    let firstPendingSection;
    return section_attempts.map(({ section, attempt_state, id }, index) => {
      if (
        attempt_state === attemptStateTypes.PENDING &&
        firstPendingSection === undefined
      ) {
        firstPendingSection = id;
      }
      const isFirstPendingSection = firstPendingSection === id;
      const questionCount = section.standard_question_list.length;
      const questionLabel = pluralize('Question', questionCount, true);

      return (
        <Row
          key={id}
          justifyContent="space-evenly"
          alignItems="center"
          height="50px"
          padding="20px"
          first={index === 0}
          className={
            attempt_state === attemptStateTypes.PENDING &&
            !isFirstPendingSection &&
            'pending'
          }
        >
          <Cell width="25%">{section.title}</Cell>
          <Cell width="25%">{questionLabel}</Cell>
          <Cell width="25%">{displaySectionTime(section)}</Cell>
          <Cell justifyContent="center" width="25%">
            {renderStatus(attempt_state, isFirstPendingSection)}
          </Cell>
        </Row>
      );
    });
  };

  return (
    <PageWrapper>
      <MobileWarning />
      <FlexContainer direction="column" alignItems="center">
        <Header>Your assessment has not been completed</Header>
        <SummaryTable direction="column">{displaySectionData()}</SummaryTable>
      </FlexContainer>
    </PageWrapper>
  );
};

export default PausedPage;

const Header = styled.p`
  font-family: ${({ theme }) => theme.boldFontFamily};
  font-size: 20px;
  letter-spacing: 0.15px;
`;

const SummaryTable = styled(FlexContainer)`
  border: 1px solid #c7c7c7;
  border-radius: 4px;
`;

const Row = styled(FlexContainer)`
  border-top: ${({ first }) => !first && '1px solid #c7c7c7'};
  &.pending {
    background: #f4f4f4;
  }
`;

const Cell = styled(FlexContainer)`
  min-width: 150px;
`;
