import * as yup from 'yup';
import { QuestionTypes } from 'containers/app/helpers/questionTypes';

const ANSWER_LIMIT = Math.pow(10, 10);
// The only schema we have to validate forms is for banded questions
// So non banded questions just have an empty validation object
export const validateForm = (currentSlotId, type, currentSlot) => {
  switch (type) {
    case QuestionTypes.BANDED:
      return yup.object().shape({
        [currentSlotId]: yup
          .mixed()
          .test('isNumber', 'Response must be a number', value => {
            if (value === null || value === undefined) {
              return true;
            }
            if (value.includes('e') || value.includes('E')) {
              return false;
            }
            return !isNaN(value);
          })
          .test(
            'isNoBiggerThanLimit',
            `Response must be smaller than ${ANSWER_LIMIT}`,
            value => {
              return value < ANSWER_LIMIT;
            }
          )
      });
    case QuestionTypes.UPLOAD:
      let accept_types = currentSlot?.question?.accept_types;
      let errorString = getFileErrorMessage(accept_types);
      return yup.object().shape({
        [currentSlotId]: yup.mixed().test('isFile', errorString, value => {
          if (value === null || value === undefined || Array.isArray(value)) {
            return false;
          }
          return value instanceof File;
        })
      });
    default:
      break;
  }
};

function getFileErrorMessage(accept_types) {
  let errorMessage = 'Please upload a file';
  if (accept_types?.includes('video')) {
    errorMessage = `Please record a video file`;
  }
  return errorMessage;
}
