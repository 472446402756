import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { findBalance } from 'containers/inProgressPage/subcomponents/stock/helpers/findBalance';
import {
  FlexContainer,
  WindowWrapper,
  Header,
  WindowBody,
  Divider,
  IconWrapper,
  HoverContainer
} from 'containers/inProgressPage/subcomponents/stock/StyledComponents';
import BalanceTableRow from './subcomponents/BalanceTableRow';
import { user } from '../assets/user';

const BalanceTable = ({
  assets,
  hasInsurance,
  insuranceDelta,
  balances,
  currentPortfolio,
  header,
  botId,
  startingBalance
}) => {
  const insuranceBalance = hasInsurance
    ? findBalance(balances, { insuranceDelta, botId })
    : null;

  const renderStockInfo = () => {
    return (
      <FlexContainer flex="1">
        {assets.map(asset => {
          return (
            <FlexContainer
              key={asset.id}
              direction="column"
              justifyContent="flex-start"
              paddingTop="8px"
            >
              <HoverContainer>
                <BalanceTableRow
                  leftObj={{
                    text: `${asset.question_stock_asset.ticker_name}`
                  }}
                />
                <div className={'description'}>
                  <div className="pop-up stock-held">
                    {`${asset.question_stock_asset.ticker_name} Held`}
                  </div>
                </div>
              </HoverContainer>
              <BalanceTableRow
                leftObj={{
                  text: `${
                    findBalance(balances, { assetId: asset.id, botId })
                      ?.quantity
                  }`,
                  className: 'emphasis bold'
                }}
              />
              {hasInsurance && (
                <>
                  <BalanceTableRow
                    leftObj={{ text: 'Insurance', className: '' }}
                    rightObj={{
                      text: insuranceBalance ? insuranceBalance.quantity : 0,
                      className: 'bold'
                    }}
                    condense
                  />
                  <BalanceTableRow
                    leftObj={{ text: 'Insured Price', className: '' }}
                    rightObj={{
                      text: `$${insuranceBalance.insuredPrice}`,
                      className: 'bold'
                    }}
                  />
                </>
              )}
            </FlexContainer>
          );
        })}
      </FlexContainer>
    );
  };

  const renderPortfolioInfo = () => (
    <FlexContainer
      direction="column"
      flex="1"
      justifyContent="flex-start"
      paddingTop="8px"
    >
      <BalanceTableRow
        leftObj={{
          text: 'Portfolio Value'
        }}
      />
      <BalanceTableRow
        leftObj={{
          text: `$${currentPortfolio.portfolio_value}`,
          className: 'emphasis bold'
        }}
        rightObj={{
          text: `${
            currentPortfolio.portfolio_value >= startingBalance ? '+' : '-'
          }$${Math.abs(
            currentPortfolio.portfolio_value - startingBalance
          )} (${Math.round(
            (Math.abs(currentPortfolio.portfolio_value - startingBalance) /
              startingBalance) *
              100
          )}%)`,
          className: 'bold balance-change'
        }}
        portfolio={true}
        increase={currentPortfolio.portfolio_value >= startingBalance}
      />
      <BalanceTableRow
        leftObj={{
          text: 'Cash Remaining'
        }}
        rightObj={{
          text: `${
            currentPortfolio.cash_value < 0
              ? `($${Math.abs(currentPortfolio.cash_value)})`
              : `$${currentPortfolio.cash_value}`
          }`,
          className: `bold ${currentPortfolio.cash_value < 0 && 'negative'}`
        }}
      />
    </FlexContainer>
  );

  const renderTables = () => {
    return (
      <WindowWrapper color="#2c374b" height="150px" margin="20px 0 0">
        <TableHeader>
          {header !== 'Current Balance' && <IconWrapper>{user}</IconWrapper>}
          {header}
        </TableHeader>
        <WindowBody className="balance-table">
          <FlexContainer>
            {renderStockInfo()}
            <Divider vertical />
            {renderPortfolioInfo()}
          </FlexContainer>
        </WindowBody>
      </WindowWrapper>
    );
  };

  return renderTables();
};

BalanceTable.propTypes = {
  assets: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasInsurance: PropTypes.bool.isRequired,
  insuranceDelta: PropTypes.number,
  balances: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentPortfolio: PropTypes.object.isRequired,
  header: PropTypes.string,
  botId: PropTypes.number
};

BalanceTable.defaultProps = {
  header: 'Current Balance',
  botId: null,
  insuranceDelta: null
};

export default BalanceTable;

const TableHeader = styled(Header)`
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
