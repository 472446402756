import React from 'react';
import { Notification } from './StyledComponents';

const GameCompletedNotification = ({ time }) => {
  return (
    <Notification className="stock">
      You have completed this scenario and will be directed to the summary page
      in {time}…
    </Notification>
  );
};

export default GameCompletedNotification;
