import React from 'react';
import styled from 'styled-components';
import { FlexContainer } from 'components/local/styles/StyledComponents';
import { OpaqueWrapper } from 'components/local/modals/styles/StyledComponents';
import { screenSize } from 'components/local/modals/assets/screenSize';

const MobileWarning = () => {
  return (
    <Overlay>
      <WarningBody
        alignItems="center"
        direction="column"
        justifyContent="center"
      >
        {screenSize}
        <Warning>
          In order to ensure the best browsing experience, please use a desktop
          or a laptop.
        </Warning>
        (Screen size exceeding 960px is recommended)
      </WarningBody>
    </Overlay>
  );
};

export default MobileWarning;

const Overlay = styled(OpaqueWrapper)`
  display: none;
  padding: 32px;
  @media (max-width: 960px) {
    display: flex;
  }
`;

const WarningBody = styled(FlexContainer)`
  background: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 14px;
  height: 100%;
  letter-spacing: 0.47px;
  line-height: 16px;
  padding: 32px;
  position: relative;
  text-align: center;
  width: 100%;
`;

const Warning = styled.div`
  font-family: ${({ theme }) => theme.boldFontFamily};
  font-size: 16px;
  letter-spacing: 0.44px;
  line-height: 22px;
  margin: 24px 0;
`;
