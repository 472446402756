import React from 'react';

export const dropdownArrow = (
  <svg
    width="10px"
    height="10px"
    viewBox="0 0 10 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>dropdown Icon</title>
    <g
      id="Test-taker-flow"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Game_Scenario1_info"
        transform="translate(-854.000000, -550.000000)"
        fill="#858A92"
      >
        <g id="transaction" transform="translate(683.000000, 538.000000)">
          <g id="Group-26" transform="translate(-41.000000, 0.000000)">
            <g id="Group-27" transform="translate(212.000000, 12.000000)">
              <path
                d="M5,0 C7.76142375,0 10,2.23857625 10,5 C10,7.76142375 7.76142375,10 5,10 C2.23857625,10 0,7.76142375 0,5 C0,2.23857625 2.23857625,0 5,0 Z M7.03332316,4.01555601 L2.96667711,4.01555601 L5.00000014,6.76776728 L7.03332316,4.01555601 Z"
                id="dropdown-Icon"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
