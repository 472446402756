import { axios } from 'services/requests/helpers/AxiosClient';
import { attemptStateTypes } from 'containers/app/helpers/attemptStateTypes';
import { configureEndpoint } from 'services/requests/helpers/configureEndpoint';
import { setErrorPage } from 'services/requests/helpers/setErrorPage';
import { setError } from 'services/requests/helpers/setError';
import { updateAssessmentState } from 'services/requests/assessmentRequests';

const TAKER = 'taker';
const TAKER_ERROR = 'Your response could not be submitted';

// Create taker associated with attempt
export const createTaker = async (slug, uuid, dispatch, body) => {
  const requestUrl = configureEndpoint(TAKER, uuid);

  try {
    await axios.post(requestUrl, body);
    // updateAssessmentState takes care of requestTime monitoring
    updateAssessmentState(slug, uuid, attemptStateTypes.IN_PROGRESS, dispatch);
  } catch ({ response }) {
    response?.status === 410 && setErrorPage(response, dispatch);
    setError(TAKER_ERROR, dispatch);
  }
};
