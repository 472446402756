import React from 'react';
import styled from 'styled-components';
import { parseHtml } from 'containers/app/helpers/parseHtml';
import { questionMark } from './questionMark';

const InstructionsTooltip = ({ question }) => {
  return (
    <Instructions>
      <div className="tooltip">
        <div className="title">{question.content[0].title}</div>
        {parseHtml(question.content[0].text_display)}
      </div>
      <div className="icon">{questionMark}</div>
    </Instructions>
  );
};

const Instructions = styled.div`
  position: fixed;
  bottom: 40px;
  right: 40px;
  font-size: 12px;
  font-family: ${({ theme }) => theme.altFontFamily};
  color: #2c374b;
  letter-spacing: 0.4px;
  z-index: 2;
  .tooltip {
    position: absolute;
    bottom: 40px;
    right: 10px;
    height: 300px;
    width: 400px;
    padding: 30px;
    background: #f7f6f6;
    visibility: hidden;
    border: 1px solid #cecbc8;
    opacity: 0;
    transition: opacity 300ms, visibility 300ms;
    overflow: auto;
  }
  .icon {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  &:hover {
    width: 400px;
    height: 340px;
    .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
  .title {
    font-size: 14px;
    font-weight: bold;
  }
  .instruction-header {
    font-size: 14px;
    font-weight: 500;
    margin: 10px 0;
  }
`;

export default InstructionsTooltip;
