import React from 'react';

export const downloadArrow = (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>D0BC5F53-CC04-4792-86A4-515A892712DC@1.5x</title>
    <g
      id="V3_latest"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Artboard-Copy-34"
        transform="translate(-373.000000, -421.000000)"
        fill="#3549EC"
        fillRule="nonzero"
      >
        <g id="Group" transform="translate(373.000000, 420.000000)">
          <g id="54572" transform="translate(0.000000, 1.000000)">
            <path
              d="M8,0 C3.58201307,0 0,3.5814902 0,8 C0,12.4185098 3.58201307,16 8,16 C12.4179869,16 16,12.4179869 16,8 C16,3.58201307 12.4185098,0 8,0 Z M11.268,8.43950327 L8.43950327,11.268 C8.31950327,11.388 8.15552941,11.4249935 8,11.3974902 C7.84499346,11.4249935 7.68049673,11.3875033 7.56049673,11.268 L4.732,8.43950327 C4.53649673,8.244 4.53649673,7.92752941 4.732,7.73252288 C4.92750327,7.53701961 5.244,7.53701961 5.43900654,7.73252288 L7.5,9.79301961 L7.5,4.5 C7.5,4.224 7.724,4 8,4 C8.276,4 8.5,4.224 8.5,4.5 L8.5,9.79299346 L10.5604967,7.73249673 C10.756,7.53699346 11.0724967,7.53699346 11.2674771,7.73249673 C11.4630065,7.92750327 11.4630065,8.244 11.268,8.43950327 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
