import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Dropdown from 'components/global/dropdown/Dropdown';
import TransactionTable from 'components/global/tables/TransactionTable';
import { PlayerTypes } from 'containers/app/helpers/playerTypes';
import { FlexContainer } from 'components/local/styles/StyledComponents';
import { SectionHeader } from 'containers/inProgressPage/subcomponents/stock/StyledComponents';

const MultiTransactionTable = ({ assets, actions, bots, insurancePremium }) => {
  const { register, getValues, setValue } = useForm();
  const [selectValue, setSelectValue] = useState('PLAYER');
  const takerHeader = 'Your Transactions';
  const players = [{ player_name: takerHeader }, ...bots];

  const handleChange = () => {
    // Handles change to input by setting state to reflect input value
    const value = getValues().transactionFilter;
    setSelectValue(value);
  };

  const filterActionsByPlayer = player => {
    // Filters actions to include only player's actions
    return actions.filter(action => {
      if (!player.bot) {
        return action.player_type === PlayerTypes.TAKER;
      } else {
        return action.answer_bot_id && player.id === action.answer_bot_id;
      }
    });
  };

  const filterActionsByStock = asset => {
    return actions.filter(action => {
      return asset.id === action.answer_stock_asset_id;
    });
  };

  const renderStockTables = () => {
    // Iterates through stocks to create table for each
    return assets.map(asset => (
      <div className="col-xs-4" key={asset.id}>
        <TransactionTable
          assets={[asset]}
          actions={filterActionsByStock(asset)}
          bots={bots}
          header={asset.question_stock_asset.ticker_name}
          insurancePremium={insurancePremium}
          stocks={true}
          summary
        />
      </div>
    ));
  };

  const renderPlayerTables = () => {
    // Iterates through players to create table for each
    return players.map((player, index) => {
      return (
        <div className="col-xs-4" key={index}>
          <TransactionTable
            assets={assets}
            actions={filterActionsByPlayer(player)}
            bots={bots}
            header={
              player.player_name || `${player.bot.player_name}'s Transactions`
            }
            insurancePremium={insurancePremium}
            summary
          />
        </div>
      );
    });
  };

  const renderTables = () => {
    // Renders table based on display selected
    switch (selectValue) {
      case 'STOCK':
        return renderStockTables();
      default:
        return renderPlayerTables();
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-xs-12">
          <FlexContainer
            margin="32px 0 0"
            justifyContent="space-between"
            alignItems="center"
          >
            <SectionHeader>TRANSACTIONS</SectionHeader>
            <Dropdown
              name="transactionFilter"
              register={register}
              setValue={setValue}
              options={['PLAYER', 'STOCK']}
              handleChange={handleChange}
            />
          </FlexContainer>
        </div>
      </div>
      <div className="row">{renderTables()}</div>
    </>
  );
};

export default MultiTransactionTable;
