import PropTypes from 'prop-types';
import React from 'react';
import { StyledButton, BtnText } from './styles/StyledComponents';
import { useDisableSubmit } from './hooks/useDisableSubmit';
import LoadingSpinner from './components/LoadingSpinner';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';

const SubmitButton = ({
  disabled,
  isLoading,
  text,
  className,
  isFullWidth,
  formikProps,
  setShowSummary
}) => {
  const disableSubmit = useDisableSubmit(
    formikProps.errors,
    formikProps.submitCount
  );
  const { state } = useAssessmentContext();
  const { slotContentIsLoading } = state;

  return (
    <StyledButton
      disabled={
        slotContentIsLoading ||
        isLoading ||
        disableSubmit ||
        disabled ||
        !formikProps.isValid
      }
      className={className}
      isFullWidth={isFullWidth}
      formikProps={formikProps}
      type="submit"
      onClick={() => setShowSummary && setShowSummary(false)}
    >
      {isLoading ? (
        <LoadingSpinner className={className} />
      ) : (
        <BtnText>{text}</BtnText>
      )}
    </StyledButton>
  );
};

SubmitButton.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string,
  isFullWidth: PropTypes.bool,
  formikProps: PropTypes.object.isRequired,
  setShowSummary: PropTypes.func
};

SubmitButton.defaultProps = {
  disabled: false,
  loading: false,
  text: 'submit',
  className: '',
  isFullWidth: false
};

export default SubmitButton;
