import PropTypes from 'prop-types';
import React from 'react';
import InputContainer from 'components/global/InputContainer';
import RadioRow from 'components/global/radio/RadioRow';
import { useRenderErrors } from 'components/global/hooks/useRenderErrors';
import { RadioContainer } from './styles/StyledComponents';

const Radio = ({
  className,
  description,
  disabled,
  formikProps,
  label,
  name,
  optional,
  options,
  orientation
}) => {
  const { errors, submitCount } = formikProps;
  const showErrors = useRenderErrors(errors, submitCount, name);

  return (
    <InputContainer
      disabled={disabled}
      optional={optional}
      label={label}
      description={description}
      showErrors={showErrors}
      errors={errors}
      name={name}
    >
      <RadioContainer orientation={orientation}>
        {options.map((option, index) => {
          return (
            <RadioRow
              key={`option${index + 1}`}
              name={name}
              option={option}
              formikProps={formikProps}
              disabled={disabled}
              className={className}
            />
          );
        })}
      </RadioContainer>
    </InputContainer>
  );
};

Radio.propTypes = {
  className: PropTypes.oneOf(['', 'secondary']),
  description: PropTypes.string,
  disabled: PropTypes.bool,
  formikProps: PropTypes.object.isRequired,
  label: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  optional: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  orientation: PropTypes.oneOf(['vertical', 'horizontal'])
};

Radio.defaultProps = {
  className: '',
  description: '',
  disabled: false,
  label: '',
  optional: false,
  orientation: 'vertical'
};

export default Radio;
