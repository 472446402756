import React from 'react';

export const insuranceIcon = (
  <svg
    width="16px"
    height="19px"
    viewBox="0 0 16 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>shield (1)</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-551.000000, -365.000000)"
        fill="#6EBC96"
        fillRule="nonzero"
      >
        <g transform="translate(182.000000, 193.000000)">
          <g transform="translate(369.000000, 172.000000)">
            <path
              d="M3.36842105,5.81542716 C3.43810406,6.67160232 3.5981271,7.84439221 3.9602102,9.07580921 C4.72920214,11.690904 6.08767652,13.5503879 8,14.6070904 C9.91232348,13.5503879 11.2707979,11.690904 12.0397554,9.07580921 C12.4018385,7.84449829 12.5618271,6.67167304 12.6315789,5.81542716 L8,3.43696245 L3.36842105,5.81542716 Z"
              id="Path"
            ></path>
            <path
              d="M15.9911555,4.21700177 C15.9812485,4.02690458 15.8694416,3.85678979 15.6985114,3.77167953 L8.23734214,0.0557974386 C8.08792415,-0.0185991462 7.91207585,-0.0185991462 7.76265786,0.0557974386 L0.301488635,3.77167953 C0.130558422,3.85678979 0.0187514526,4.02690458 0.00884450593,4.21700177 C0.00318339353,4.32519576 -0.119521218,6.90134051 0.761524781,9.89225238 C1.28284247,11.6619114 2.04964014,13.2075348 3.04061787,14.4862327 C4.28942388,16.0975831 5.89449077,17.2829592 7.81123728,18.0095154 C7.87205886,18.0325286 7.93599405,18.0440529 8,18.0440529 C8.06400595,18.0440529 8.12794114,18.0325286 8.18876272,18.0095154 C10.1055092,17.2829945 11.7105761,16.0975831 12.9593821,14.4862327 C13.9503599,13.2075348 14.7171575,11.6618761 15.2384752,9.89225238 C16.1195212,6.90134051 15.9968166,4.32519576 15.9911555,4.21700177 Z M8.23521922,15.6853534 C8.16109403,15.7218644 8.08052932,15.74012 8,15.74012 C7.91947068,15.74012 7.83887059,15.7218644 7.76478078,15.6853534 C5.40154941,14.5215103 3.74199431,12.4100006 2.83225355,9.40953801 C2.37724164,7.90881335 2.21296324,6.49467912 2.15529065,5.57266896 C2.14202242,5.36093296 2.25715529,5.16184897 2.44765173,5.06697659 L7.76269324,2.41995949 C7.91211123,2.34559815 8.08795953,2.34556291 8.23737752,2.41995949 L13.5524544,5.06697659 C13.7429509,5.16181373 13.8580837,5.36096821 13.8448155,5.5727042 C13.7871075,6.49478484 13.6228291,7.90895432 13.1678172,9.40953801 C12.2580057,12.4099653 10.5984506,14.521475 8.23521922,15.6853534 L8.23521922,15.6853534 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
