import React from 'react';
import SectionLandingTop from 'containers/inProgressPage/subcomponents/SectionLandingTop';
import { parseHtml } from 'containers/app/helpers/parseHtml';
import {
  SectionLandingBodyContainer,
  SectionLandingHeader,
  SectionLandingDescription
} from 'containers/inProgressPage/styles/StyledComponents';

const SectionLanding = ({ currentSection }) => {
  const { section } = currentSection;

  return (
    <SectionLandingBodyContainer>
      <SectionLandingTop currentSection={currentSection} isStock={false} />
      {section.description && (
        <SectionLandingHeader>description:</SectionLandingHeader>
      )}
      <SectionLandingDescription>
        {section.description && parseHtml(section.description)}
        {/* This is placeholder copy to tell taker that this is a question level timing section */}
        {section.slot_durations_total !== null && (
          <p>
            Please note that this section has time limits on the question level.
            If the timer runs out for a question before you have submitted your
            response, the question will be submitted and you will be moved to
            the next question.
          </p>
        )}
      </SectionLandingDescription>
    </SectionLandingBodyContainer>
  );
};

export default SectionLanding;
