import { PlayerTypes } from 'containers/app/helpers/playerTypes';
import { StockBalanceTypes } from 'containers/app/helpers/stockBalanceTypes';

export const findBalance = (balances, options) => {
  const { assetId, botId, insuranceDelta, tickId } = options;
  balances.sort((a, b) => a.id - b.id);
  // Finds and returns a balance
  for (let i = balances.length - 1; i >= 0; i--) {
    const { balance, player_type, answer_bot_id } = balances[i];
    const isInsuranceBalance =
      balance.balance_type === StockBalanceTypes.INSURANCE;
    const isAssetBalance = balance.balance_type === StockBalanceTypes.ASSET;
    const matchingBotBalance =
      botId && answer_bot_id && botId === answer_bot_id;
    const matchingTakerBalance = player_type === PlayerTypes.TAKER && !botId;
    const matchingTickId = !tickId || tickId === balance.answer_stock_tick_id;
    /* Finds most recent balance of a given asset for a taker or bot (if bot id is provided) */
    if (matchingTickId) {
      if (
        assetId &&
        isAssetBalance &&
        balance.answer_stock_asset_id === assetId
      ) {
        /* If no botId is provided to method return taker balance, and if botId is 
        provided return that bot balances */
        if (matchingTakerBalance || matchingBotBalance) {
          return balance;
        }
      } else if (insuranceDelta && isInsuranceBalance) {
        /* Finds most recent insurance balance for a taker or bot (if bot id is 
        provided) */
        const newBalance = {
          ...balance,
          insuredPrice: balance.answer_stock_tick.price - insuranceDelta
        };
        /* If no botId is provided to method return taker balance, and if botId is 
        provided return that bot balances */
        if (matchingTakerBalance || matchingBotBalance) {
          return newBalance;
        }
      }
    }
  }
};
