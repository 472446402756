import styled from 'styled-components';

export const BarWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 667px) {
    flex-direction: column;
  }
`;

export const CheckMark = styled.div`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const ProgressContainer = styled.div`
  align-items: center;
  display: flex;
  margin-right: 20px;
`;

export const ProgressLabel = styled.span`
  color: ${({ theme }) => theme.color};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.25px;
  line-height: 20px;
  margin-left: 7px;
  padding-top: 4px;
  text-transform: capitalize;
`;

export const ProgressCircle = styled.div`
  border: ${({ color, theme }) => `2px solid ${color || theme.color}`};
  border-radius: 100px;
  height: 16px;
  position: relative;
  width: 16px;
  svg {
    fill: ${({ color, theme }) => color || theme.color};
  }
`;

export const Underline = styled.div`
  margin-top: 10px;
  height: 1px;
  width: 100%;
  background: #e5e5e5;
`;

export const OuterBar = styled.div`
  margin-bottom: 64px;
  display: flex;
  width: 100%;
  height: 12px;
  background: #f1f1f1;
  border-radius: 2px;
`;

export const InnerBar = styled.div`
  width: ${props => `${props.width}%`};
  background: ${({ theme }) => theme.color};
  height: 100%;
  border-radius: 2px;
  transition: 200ms all ease-out;
`;

export const SlotContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  pointer-events: ${props => props.disabled && 'none'};
  margin-bottom: 64px;
`;

export const BlankSlot = styled.div`
  background: #f1f1f1;
  height: 15px;
  min-width: 10px;
  margin: 2px;
  border-radius: 2px;
  flex-grow: 1;
  cursor: pointer;
`;

export const CurrentSlot = styled(BlankSlot)`
  background: ${({ theme }) => theme.color};
`;

export const SubmittedSlot = styled(CurrentSlot)`
  opacity: 30%;
`;
