import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;

export function init() {
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  ReactGA.initialize(TRACKING_ID, { debug: isDev });
}

export function sendPageview(path) {
  if (path === undefined) path = window.location.pathname;
  ReactGA.send({
    hitType: 'pageview',
    page: path,
    title: document.title
  });
}

export default function useGoogleAnalytics() {
  const location = useLocation();

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const currentPath = location.pathname + location.search;
    console.log('currentPath', currentPath);
    sendPageview(currentPath);
  }, [location]);
}
