import styled from 'styled-components';

export const CountdownWrapper = styled.div`
  position: fixed;
  right: 20px;
  top: 20px;
  max-width: 105px;
  display: flex;
  align-items: flex-start;
  opacity: ${props => props.disabled && '0.3'};
  .CircularProgressbar .CircularProgressbar-path {
    stroke: ${props => props.theme.color};
  }
`;

export const Countdown = styled.span`
  font-family: ${props => props.theme.boldFontFamily};
  letter-spacing: 1.25px;
  color: ${props => props.theme.color};
  margin-left: 5px;
  padding-top: 5px;
  font-size: 16px;
`;
