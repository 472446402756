import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useDelayRender } from 'containers/inProgressPage/hooks/useDelayRender';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const Loading = ({ delay }) => {
  const delayRender = useDelayRender(delay ? 500 : 0);
  const height1 = 20;
  const height2 = 80;
  const duration = 2;
  const circleDimension = 35;

  const renderBlock = (rows, height) => {
    let block = Array(rows).fill();
    return block.map((row, index) => {
      return (
        <div key={index}>
          <Skeleton duration={duration} height={height} key={index} />
          <SkeletonSpacer />
        </div>
      );
    });
  };

  return delayRender(() => (
    <SkeletonTheme color="#e9ebf0" highlightColor="#f6f7fa">
      <SkeletonTopWrapper>
        <Skeleton
          circle={true}
          duration={duration}
          height={circleDimension}
          width={circleDimension}
        />

        <SkeletonSub>
          {renderBlock(5, height1)}
          <Skeleton
            duration={duration}
            height={height1}
            width={`${height2}%`}
          />
        </SkeletonSub>
      </SkeletonTopWrapper>
      <SkeletonSpacer />
      <SkeletonSpacer />
      <SkeletonSpacer />
      <SkeletonBottomWrapper>{renderBlock(4, height2)}</SkeletonBottomWrapper>
    </SkeletonTheme>
  ));
};

Loading.propTypes = {
  delay: PropTypes.bool
};

Loading.defaultProps = {
  delay: true
};

export default Loading;

export const SkeletonTopWrapper = styled.div`
  display: flex;
  margin-top: 30px;
`;

export const SkeletonBottomWrapper = styled.div`
  margin-left: 50px;
`;

export const SkeletonSub = styled.div`
  width: 100%;
  margin-left: 10px;
`;

export const SkeletonSpacer = styled.div`
  height: 10px;
`;
