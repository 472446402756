import React from 'react';

export const upArrow = (
  <svg
    width="15px"
    height="15px"
    viewBox="0 0 15 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-635.000000, -1708.000000)" stroke="#F7F6F6">
        <g transform="translate(208.000000, 1579.000000)">
          <g transform="translate(434.000000, 136.000000) rotate(-315.000000) translate(-434.000000, -136.000000) translate(430.000000, 132.000000)">
            <line
              x1="4.44131892"
              y1="7.77444926"
              x2="4.40869119"
              y2="0.713883494"
              id="Line-3"
              strokeLinecap="square"
            ></line>
            <polyline
              transform="translate(4.408691, 3.713883) rotate(-135.000000) translate(-4.408691, -3.713883) "
              points="6.90869119 1.21388349 6.90869119 6.21388349 1.90869119 6.21388349"
            ></polyline>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
