// Configure enpoint url
export const configureEndpoint = (...args) => {
  let endpoint = `${process.env.REACT_APP_API_URL}/api/v1`;

  args.forEach(arg => {
    endpoint += `/${arg}`;
  });

  return endpoint;
};
