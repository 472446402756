import React, { useEffect, useState } from 'react';
import pluralize from 'pluralize';
import { attemptStateTypes } from 'containers/app/helpers/attemptStateTypes';
import { displaySectionTime } from 'containers/inProgressPage/helpers/displaySectionTime';
import { parseHtml } from 'containers/app/helpers/parseHtml';
import { useGetAssessmentInfo } from 'containers/landingPage/hooks/useGetAssessmentInfo';
import { useHandleRequest } from 'containers/inProgressPage/hooks/useHandleRequest';
import {
  InstructionContainer,
  InstructionList,
  TenantLogoContainer,
  TenantLogo,
  AssessmentTitle,
  Table,
  TableCell,
  TermsAndConditions
} from 'containers/landingPage/styles/StyledComponents';
import NetworkNotification from 'containers/inProgressPage/subcomponents/NetworkNotification';
import MobileWarning from 'components/local/modals/MobileWarning';
import LoadingButton from 'components/global/Buttons/LoadingButton';
import {
  PageWrapper,
  FlexContainer
} from 'components/local/styles/StyledComponents';
import { useThemeContext } from 'services/context/themeContext/useThemeContext';
import { createAssessmentAttempt } from 'services/requests/assessmentRequests';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';
import { StyledButton } from 'components/global/Buttons/styles/StyledComponents';

const LandingPage = () => {
  const {
    name,
    slug,
    taker_fields,
    uuid,
    dispatch,
    sections,
    assessment_detail_content: { description, instructions },
    tenantName
  } = useGetAssessmentInfo();
  const { themeState, themeDispatch } = useThemeContext();
  const {
    handleUpdateAssessment,
    handleValidateAssessmentStart
  } = useHandleRequest();
  const [isLoading, setIsLoading] = useState(false);
  const {
    assessmentAttempt: { id: attemptId, taker }
  } = useAssessmentContext();
  const [fieldObj, setFieldObj] = useState({});

  useEffect(() => {
    const fieldInfo = {};

    taker_fields?.forEach(({ content, id, field_type }) => {
      const name = content[0] && content[0].title;

      return (fieldInfo[name] = {
        taker_field_id: id,
        assessment_attempt_id: attemptId,
        field_type: `${field_type}_response`
      });
    });

    setFieldObj(fieldInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taker_fields]);

  const handleStartAssessment = async () => {
    setIsLoading(true);
    const taker_field_responses = [];

    for (let i in fieldObj) {
      taker_field_responses.push({
        assessment_attempt_id: fieldObj[i].assessment_attempt_id,
        result: '',
        field_type: fieldObj[i].field_type,
        taker_field_id: fieldObj[i].taker_field_id
      });
    }

    const body = {
      email: taker.email,
      taker_field_responses
    };

    handleUpdateAssessment(attemptStateTypes.PENDING);
    await handleValidateAssessmentStart(body);
    setIsLoading(false);
  };

  const handleClick = () => {
    if (uuid) {
      handleUpdateAssessment(attemptStateTypes.PENDING);
    } else {
      createAssessmentAttempt(slug, dispatch, themeDispatch, true);
    }
    setIsLoading(true);
  };

  const displaySectionData = () =>
    sections.map((section, index) => {
      const questionCount = section.standard_question_list.length;
      const questionLabel = pluralize('Question', questionCount, true);

      return (
        <tr key={index}>
          <TableCell className="section-cell">{section.title}</TableCell>
          <TableCell>{questionLabel}</TableCell>
          <TableCell>{displaySectionTime(section)}</TableCell>
        </tr>
      );
    });

  return (
    <PageWrapper id="landing-page">
      <MobileWarning />
      <div className="container-fluid container-height">
        <FlexContainer direction="column" alignItems="center">
          <NetworkNotification />
          <TenantLogoContainer>
            <TenantLogo src={themeState.logoUrl} alt={tenantName} />
          </TenantLogoContainer>
          <AssessmentTitle>{name}</AssessmentTitle>
          <Table>
            <tbody>{displaySectionData()}</tbody>
          </Table>
          <InstructionContainer>
            Instructions:
            {/* TODO #4031 */}
            <InstructionList>{parseHtml(instructions)}</InstructionList>
            {description}
          </InstructionContainer>
          {!taker?.user_id ? (
            <LoadingButton
              text={'Continue'}
              isLoading={isLoading}
              onClick={handleClick}
            />
          ) : (
            <StyledButton onClick={handleStartAssessment}>
              START ASSESSMENT
            </StyledButton>
          )}

          <TermsAndConditions>
            By starting this assessment, you agree to Correlation One's{' '}
            <a
              href="https://www.correlation-one.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy policy
            </a>{' '}
            and{' '}
            <a
              href="https://www.correlation-one.com/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms of use
            </a>
            .
          </TermsAndConditions>
        </FlexContainer>
      </div>
    </PageWrapper>
  );
};

export default LandingPage;
