import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { convertTime } from 'containers/inProgressPage/helpers/convertTime';
import {
  Countdown,
  CountdownWrapper
} from 'components/local/timer/styles/StyledComponents';
import 'react-circular-progressbar/dist/styles.css';

const Timer = ({ timedPortion, handleTimeout, handleReconciliation }) => {
  const [timeLeft, setTimeLeft] = useState(timedPortion.time_remaining);

  useEffect(() => {
    setTimeLeft(timedPortion.time_remaining);
  }, [timedPortion.time_remaining]);

  useEffect(() => {
    const currentTime = setTimeout(() => {
      if (timeLeft - 1 > 0) {
        setTimeLeft(timeLeft - 1);
      } else {
        handleTimeout();
      }
    }, 1000);
    return () => clearTimeout(currentTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  useEffect(() => {
    const driftCheck = setInterval(() => handleReconciliation(), 30000);
    return () => clearInterval(driftCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CountdownWrapper>
      <CircularProgressbar
        value={(timeLeft / timedPortion.time_allowed_seconds) * 100}
        strokeWidth={50}
        counterClockwise={true}
        styles={buildStyles({
          trailColor: '#e4e4e4',
          strokeLinecap: 'butt'
        })}
      />
      <Countdown>{timeLeft ? convertTime(timeLeft) : '00:00:00'}</Countdown>
    </CountdownWrapper>
  );
};

Timer.propTypes = {
  timedPortion: PropTypes.object.isRequired,
  handleTimeout: PropTypes.func.isRequired,
  handleReconciliation: PropTypes.func.isRequired
};

export default Timer;
