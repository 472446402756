import React from 'react';
import VideoUpload from 'components/global/videoUpload/VideoUpload';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';

const QuestionVideoUpload = ({ formikProps }) => {
  const {
    currentSlot,
    state: { currentSlotId }
  } = useAssessmentContext();
  const { content } = currentSlot.question;
  return (
    <VideoUpload
      formikProps={formikProps}
      label={content[0] ? content[0].text_display : 'No label given'}
      name={currentSlotId}
      showInitialErrors
    />
  );
};

export default QuestionVideoUpload;
