import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { StockActionTypes } from 'containers/app/helpers/stockActionTypes';
import { SideTypes } from 'containers/app/helpers/sideTypes';
import {
  WindowWrapper,
  HeaderWithToggle,
  DropdownArrow,
  IconWrapper
} from 'containers/inProgressPage/subcomponents/stock/StyledComponents';
import { dropdownArrow } from './assets/dropdownArrow';
import { insuranceIcon } from '../assets/insuranceIcon';
import { user } from './assets/user';
const TransactionTable = ({
  actions,
  assets,
  insurancePremium,
  header,
  stocks,
  bots,
  summary
}) => {
  const [showTransactions, setShowTransactions] = useState(true);

  // Check if action is insurance or buy/sale
  const determineActionType = ({ side, action_type: action }) => {
    return action === StockActionTypes.INSURANCE ? insuranceIcon : side;
  };

  // Find the asset tick that action was taken in
  const findTick = (actionTickId, ticks) => {
    return ticks.find(tick => tick.id === actionTickId);
  };

  // Check if action was buy or sell
  const evaluateCash = (cost, action) => {
    return action === SideTypes.SELL ? `+$${cost}` : <>&#8211;${cost}</>;
  };

  // Assign necessary data to Actions before rendering.
  const getActions = () => {
    return actions.map(action => {
      let asset = assets.find(
        asset => asset.id === action.answer_stock_asset_id
      );
      action.stockName = asset.question_stock_asset.ticker_name;
      action.currentTick = findTick(action.answer_stock_tick_id, asset.ticks);
      return action;
    });
  };

  // TODO: Need to alternate row color based based on ticks with transactions
  const renderTableRows = () => {
    const sortedActions = summary
      ? getActions().sort((a, b) => a.id - b.id)
      : getActions().sort((a, b) => b.id - a.id);

    return sortedActions.map(action => {
      const {
        id,
        stock_action,
        stockName,
        currentTick,
        answer_bot_id
      } = action;
      const actionType = determineActionType(stock_action);
      const price =
        typeof actionType === 'string' ? currentTick.price : insurancePremium;
      const cash = evaluateCash(stock_action.quantity * price, actionType);
      const bot = bots.find(bot => bot.id === answer_bot_id);

      return (
        <Row key={id}>
          <Col width="8%" paddingLeft="5px">
            {currentTick._index + 1}
          </Col>
          {bots.length > 0 && (!summary || stocks) && (
            <Col width="13%" textAlign="center">
              {bot ? bot.bot.player_name : 'You'}
            </Col>
          )}
          <Col
            width="10%"
            paddingLeft={actionType === insuranceIcon && '3px'}
            paddingTop={actionType === insuranceIcon && '4px'}
            weight="bold"
            transform="capitalize"
            color={actionType === SideTypes.BUY ? '#6ebc96' : '#eaa657'}
          >
            {actionType}
          </Col>
          {assets.length > 1 && (!summary || !stocks) && (
            <Col width="8%" textAlign="center">
              {stockName.split(' ').pop()}
            </Col>
          )}
          <Col width="8%" textAlign="right">
            {stock_action.quantity}
          </Col>
          <Col width="10%">${price}</Col>
          <Col width="18%" textAlign="right">
            {cash}
          </Col>
        </Row>
      );
    });
  };

  const renderHeader = () => {
    return header === 'Transactions' ? (
      header
    ) : (
      <>
        {!stocks && <IconWrapper>{user}</IconWrapper>}
        {header}
      </>
    );
  };

  return (
    <WindowWrapper margin="16px 0 0">
      <HeaderWithToggle>
        {renderHeader()}
        <DropdownArrow
          showTransactions={showTransactions}
          onClick={() => setShowTransactions(!showTransactions)}
        >
          {dropdownArrow}
        </DropdownArrow>
      </HeaderWithToggle>
      <Table>
        <Row header>
          <Col width="8%" paddingLeft="5px" col="tick">
            Tick
          </Col>
          {bots.length > 0 && (!summary || stocks) && (
            <Col width="13%" textAlign="center">
              {user}
            </Col>
          )}
          <Col width="10%">Trade</Col>
          {assets.length > 1 && (!summary || !stocks) && (
            <Col width="8%">Stock</Col>
          )}
          <Col width="8%" textAlign="right">
            Qty
          </Col>
          <Col width="10%">Price</Col>
          <Col width="18%" textAlign="right">
            Cash
          </Col>
        </Row>
        {showTransactions && <TableContent>{renderTableRows()}</TableContent>}
      </Table>
    </WindowWrapper>
  );
};

TransactionTable.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  assets: PropTypes.arrayOf(PropTypes.object).isRequired,
  insurancePremium: PropTypes.number,
  header: PropTypes.string,
  stocks: PropTypes.bool,
  bots: PropTypes.arrayOf(PropTypes.object),
  summary: PropTypes.bool
};

TransactionTable.defaultProps = {
  insurancePremium: null,
  header: 'Transactions',
  stocks: false,
  bots: null,
  summary: false
};

export default TransactionTable;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  color: #2c374b;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  min-height: 28px;
  align-items: center;
  background-color: ${({ fill }) => (fill ? '#ededed' : '#f7f6f6')};
  ${({ header }) =>
    header &&
    'color: rbga(44, 55, 75, 0.7); font-size: 12px; border-bottom: 1px solid #dfddda'}
`;

const TableContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: scroll;
  max-height: 256px;
`;

const Col = styled.div`
  width: ${({ width }) => width};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  padding-top: ${({ paddingTop }) => paddingTop};
  font-weight: ${({ weight }) => weight};
  text-transform: ${({ transform }) => transform};
  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};
  white-space: nowrap;
`;
