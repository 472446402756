import React from 'react';
import TopNavBar from 'components/local/navbar/TopNavBar';
import { PageWrapper } from 'components/local/styles/StyledComponents';

const SectionWrapper = ({
  background,
  children,
  formikProps,
  setNewSlot,
  setSubmitSection
}) => {
  return (
    <PageWrapper background={background}>
      <TopNavBar
        background={background}
        formikProps={formikProps}
        setNewSlot={setNewSlot}
        setSubmitSection={setSubmitSection}
      />
      <div className="container-fluid container-height middle-xs">
        <div className="row">
          <div className="col-xs-12">{children}</div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default SectionWrapper;
