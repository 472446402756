import React from 'react';
import styled from 'styled-components';

const LoadingSpinner = ({ className }) => (
  <SpinnerSvg viewBox="0 0 50 50" className={className}>
    <path
      d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,
        8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,
        14.615,6.543,14.615,14.615H43.935z"
    ></path>
  </SpinnerSvg>
);

const SpinnerSvg = styled.svg`
  animation: spin 700ms infinite linear;
  height: 16px;
  fill: ${({ theme, className }) =>
    className === 'inverted' ? theme.color : theme.white};
  margin-right: 0;
  width: 16px;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export default LoadingSpinner;
