import React from 'react';
import styled from 'styled-components/macro';
import { FlexContainer } from 'components/local/styles/StyledComponents';
import CelebrationGIF from '../assets/celebration.gif';

const CustomSubmission = ({ content }) => {
  const {
    completion_heading,
    completion_body,
    completion_button_url,
    completion_button_text
  } = content;

  return (
    <Container
      direction={'column'}
      alignItems={'center'}
      padding={'48px'}
      height={'100vh'}
    >
      <HeadingContainer>
        <CelebrationImage src={CelebrationGIF} />
        <HeadingText>{completion_heading}</HeadingText>
      </HeadingContainer>
      <BodyContainer dangerouslySetInnerHTML={{ __html: completion_body }} />
      <div>
        <Button href={completion_button_url} target={'_blank'}>
          {completion_button_text}
        </Button>
      </div>
    </Container>
  );
};

export default CustomSubmission;

const HeadingText = styled.h5`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #5db5e5;
  margin: 0;
`;

const HeadingContainer = styled.div`
  text-align: center;
`;

const BodyContainer = styled.div``;

const Container = styled(FlexContainer)`
  max-width: 1100px;
  margin: 0 auto;
  gap: 32px;
  ${BodyContainer} {
    text-align: center;
    max-width: 550px;
    a {
      color: #5598f7;
    }
  }
`;

const CelebrationImage = styled.img`
  max-width: 200px;
  width: 100%;
`;

const Button = styled.button.attrs(() => ({
  as: 'a'
}))`
  align-items: center;
  background: #28b2eb;
  border-radius: 2px;
  color: #ffff;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  margin: 0;
  max-width: 450px;
  text-transform: uppercase;
  width: 450px;
  text-decoration: none;
  transition: all 0.2s linear;
  margin-bottom: 32px;
  &:hover {
    opacity: 0.8;
  }
`;
