import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Button from 'components/global/Buttons/Button';
import ActionInput from 'containers/inProgressPage/subcomponents/stock/ActionInput';
// import InsuranceDetail from "containers/inProgressPage/subcomponents/stock/InsuranceDetail";
import { findBalance } from 'containers/inProgressPage/subcomponents/stock/helpers/findBalance';
import { SideTypes } from 'containers/app/helpers/sideTypes';
import { StockActionTypes } from 'containers/app/helpers/stockActionTypes';
import { WindowTypes } from 'containers/app/helpers/windowTypes';
import {
  FlexContainer,
  WindowWrapper,
  Header,
  WindowBody,
  Divider,
  AssetInfoWrapper,
  AssetName,
  InputErrorContainer
} from './StyledComponents';
import { ErrorSpan } from 'components/global/styles/StyledComponents';

const ExecutionWindow = ({
  stockTransaction,
  assets,
  balances,
  actions,
  type,
  premium,
  disabled,
  insuranceBalance,
  currentPortfolio
}) => {
  const { register, setValue } = useForm();
  const [shares, setShares] = useState(0);
  const [quantityError, setQuantityError] = useState(null);
  const [disableButtons, setDisableButtons] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    /* 
    This effect null's quantityError if user clicks outside
    the container.
    It also makes sure to remove old mousedown handlers on reload.
    */
    const handleClickOutside = event => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setQuantityError(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef, setQuantityError]);

  const stockType = type === WindowTypes.STOCK;
  const maxQuantityGlobal = assets.reduce(
    (total, asset) => Math.max(total, asset.question_stock_asset.max_quantity),
    0
  );
  // Check if max_quantity setting on
  const limitedQuantity =
    assets.reduce(
      (total, asset) =>
        Math.min(total, asset.question_stock_asset.max_quantity),
      999999
    ) < 999999;

  const setShareValue = value => {
    if (value < 0 || (!value && value !== 0)) {
      value = '';
    } else {
      value = parseInt(value);
    }
    if (value > maxQuantityGlobal) {
      setQuantityError(`Cannot trade more than ${maxQuantityGlobal}`);
    } else {
      setQuantityError(null);
    }
    value = Math.min(value, maxQuantityGlobal);
    setValue('shares', value);
    setShares(value);
  };

  const handleClick = (SideType, assetId, StockActionType) => {
    setDisableButtons(true);
    stockTransaction(SideType, shares, assetId, StockActionType).then(_ => {
      setDisableButtons(false);
    });
  };

  const getRemainingTradable = asset => {
    const asset_actions_this_tick = actions.filter(
      ({ player_type, answer_stock_tick_id }) => {
        return (
          player_type === 'player_taker' &&
          asset.ticks[asset.ticks.length - 1].id === answer_stock_tick_id
        );
      }
    );
    const total_quantity = asset_actions_this_tick.reduce((total, action) => {
      return total + action.stock_action.quantity;
    }, 0);
    return asset.question_stock_asset.max_quantity - total_quantity;
  };

  const renderActionButtons = asset => {
    const quantity_disabled = 0 === getRemainingTradable(asset);

    return (
      <FlexContainer>
        <Button
          onClick={() =>
            !disableButtons
              ? handleClick(
                  SideTypes.BUY,
                  asset.id,
                  stockType
                    ? StockActionTypes.ASSET
                    : StockActionTypes.INSURANCE
                )
              : null
          }
          text={stockType ? 'BUY' : 'BUY OPTION'}
          isFullWidth={!stockType}
          disabled={disabled || quantity_disabled}
          className={stockType ? 'small-btn' : ''}
        />
        {stockType && (
          <Button
            onClick={() =>
              !disableButtons
                ? handleClick(SideTypes.SELL, asset.id, StockActionTypes.ASSET)
                : null
            }
            text="SELL/SHORT"
            disabled={disabled || quantity_disabled}
            className="talentstat-secondary small-btn"
          />
        )}
      </FlexContainer>
    );
  };

  const renderAssetAction = () => {
    return assets.map((asset, index) => {
      let lastTick = asset.ticks[asset.ticks.length - 1];
      return (
        <div key={asset.id}>
          {stockType ? (
            <AssetInfoWrapper>
              <AssetName index={index}>
                {asset.question_stock_asset.ticker_name}
              </AssetName>{' '}
              {renderRow('Price per share', `$${lastTick.price}`)}
              {renderRow(
                'Total Value',
                `$${shares * lastTick.price}`,
                !limitedQuantity
              )}
              {limitedQuantity &&
                renderRow(
                  'Quantity Tradable',
                  `${getRemainingTradable(asset)}`,
                  true
                )}
              {renderActionButtons(asset)}
            </AssetInfoWrapper>
          ) : (
            <>
              {renderRow('Price per option', `$${premium}`)}
              {renderRow('Total Cost', `$${shares * premium}`, true)}
              {renderActionButtons(asset)}
            </>
          )}
        </div>
      );
    });
  };

  // const renderAssetCount = () => {
  //   return assets.map(asset => (
  //     <FlexContainer key={asset.id}>
  //       <div>{asset.question_stock_asset.ticker_name} Held</div>
  //       <div className="bold">
  //         {findBalance(balances, { assetId: asset.id }).quantity}
  //       </div>
  //     </FlexContainer>
  //   ));
  // };

  const renderRow = (left, right, bottom) => {
    return (
      <FlexContainer className={bottom && 'bottom'} fontSize="14px">
        <div>{left}</div>
        <div className="bold">{right}</div>
      </FlexContainer>
    );
  };

  /*
    NOTE: Andy has asked me to remove this from the execution windows
    this may be a temporary change, so I chose to simply comment it out
  */
  // const renderWindowBottom = () => {
  //   if (stockType) {
  //     return (
  //       <>
  //         {renderAssetCount()}
  //         {renderRow("Portfolio Value", `$${currentPortfolio.portfolio_value}`)}
  //         {renderRow("Cash Remaining", `$${currentPortfolio.cash_value}`)}
  //       </>
  //     );
  //   } else {
  //     return (
  //       <>
  //         {(Boolean(shares) || Boolean(insuranceBalance.quantity)) && (
  //           <InsuranceDetail
  //             balance={insuranceBalance}
  //             shares={shares || insuranceBalance.quantity}
  //           />
  //         )}
  //       </>
  //     );
  //   }
  // };

  return (
    <WindowWrapper margin="0 0 8px">
      <Header>
        {stockType
          ? 'Number of shares to trade'
          : 'Number of put options to trade'}
      </Header>
      <WindowBody className="execution-window">
        <InputErrorContainer ref={containerRef}>
          <ActionInput
            isCleared={shares <= 0}
            register={register}
            setShareValue={setShareValue}
            shares={shares}
            type={type}
            firstAssetQuantity={
              findBalance(balances, { assetId: assets[0].id }).quantity
            }
            disabled={disabled}
            error={quantityError ? 'error' : null}
          />
          <ErrorSpan>
            {quantityError || <span> placeholder span </span>}
          </ErrorSpan>
        </InputErrorContainer>
        {renderAssetAction()}
        {stockType && (
          <>
            <Divider />
            {renderRow('Buying Power', `$${currentPortfolio.available_margin}`)}
          </>
        )}
        {/* <Divider /> */}
        {/* {renderWindowBottom()} */}
      </WindowBody>
    </WindowWrapper>
  );
};

ExecutionWindow.propTypes = {
  stockTransaction: PropTypes.func.isRequired,
  assets: PropTypes.arrayOf(PropTypes.object).isRequired,
  balances: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.oneOf(['stock', 'insurance']),
  premium: PropTypes.number,
  disabled: PropTypes.bool,
  insuranceBalance: PropTypes.object,
  currentPortfolio: PropTypes.object
};

ExecutionWindow.defaultProps = {
  type: 'stock',
  premium: null,
  disabled: false,
  insuranceBalance: null,
  currentPortfolio: null
};

export default ExecutionWindow;
