import PropTypes from 'prop-types';
import React from 'react';
import Button from 'components/global/Buttons/Button';
import LoadingButton from 'components/global/Buttons/LoadingButton';
import { FlexContainer } from 'components/local/styles/StyledComponents';
import { NavWrapper } from 'components/local/navbar/styles/StyledComponents';

const BottomNavBar = ({
  background,
  buttonText,
  buttonTextSecondary,
  isLoading,
  flat,
  handleClick,
  handleClickSecondary
}) => {
  return (
    <NavWrapper background={background} flat={flat} height="72px">
      <FlexContainer
        width="100%"
        justifyContent={buttonTextSecondary ? 'space-between' : 'center'}
        maxWidth="1024px"
      >
        {buttonTextSecondary && (
          <Button
            className="secondary nav"
            text={buttonTextSecondary}
            onClick={handleClickSecondary}
          />
        )}
        <LoadingButton
          isLoading={isLoading}
          text={buttonText}
          onClick={handleClick}
        />
      </FlexContainer>
    </NavWrapper>
  );
};

BottomNavBar.propTypes = {
  background: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  buttonTextSecondary: PropTypes.string,
  flat: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  handleClickSecondary: PropTypes.func
};

BottomNavBar.defaultProps = {
  background: '#ffffff',
  buttonTextSecondary: null,
  flat: false,
  handleClickSecondary: () => {}
};

export default BottomNavBar;
