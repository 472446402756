import { useEffect } from 'react';
import { attemptStateTypes } from 'containers/app/helpers/attemptStateTypes';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';
import { SET_CURRENT_SLOT } from 'services/context/assessmentContext/assessmentContext';

export const useSetCurrentSlot = () => {
  const {
    state,
    dispatch,
    currentSection,
    currentSlot
  } = useAssessmentContext();

  useEffect(() => {
    const findCurrentSlot = attemptState => {
      return currentSection.slot_attempts.find(slot => {
        return slot.attempt_state === attemptState;
      });
    };

    const inProgressSlot = findCurrentSlot(attemptStateTypes.IN_PROGRESS);
    const pendingSlot = findCurrentSlot(attemptStateTypes.PENDING);

    const formatDispatch = slot => {
      dispatch({
        type: SET_CURRENT_SLOT,
        payload: {
          currentSlotId: slot.id,
          currentSlotIndex: slot.sort_index
        }
      });
    };

    if (inProgressSlot !== undefined) {
      formatDispatch(inProgressSlot);
    } else if (pendingSlot !== undefined) {
      formatDispatch(pendingSlot);
    } else {
      formatDispatch(currentSection.slot_attempts[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { state, dispatch, currentSection, currentSlot };
};
