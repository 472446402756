import {
  getSlot,
  updateSlotToInProgress,
  updateSlotToSubmitted,
  updateClickableSlotToSubmitted,
  createStockTick,
  createStockAction
} from 'services/requests/slotRequests';
import {
  getSection,
  updateSectionToInProgress,
  updateSectionToSubmitted,
  getStockSectionMeta
} from 'services/requests/sectionRequests';
import { updateAssessmentState } from 'services/requests/assessmentRequests';
import { validateAttemptLimit } from 'services/requests/validateAttemptLimitRequest';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';
import { createTaker } from 'services/requests/takerRequests';
export const useHandleRequest = () => {
  const {
    dispatch,
    assessmentAttempt,
    currentSection,
    currentSlot,
    state
  } = useAssessmentContext();
  const { currentSectionIndex, currentSlotIndex } = state;
  const { assessment, uuid } = assessmentAttempt;

  const handleUpdateAssessment = state => {
    updateAssessmentState(assessment.slug, uuid, state, dispatch);
  };

  const handleStartSection = () => {
    updateSectionToInProgress(
      assessmentAttempt,
      assessment.slug,
      uuid,
      currentSection.id,
      currentSectionIndex,
      dispatch
    );
  };

  const handleSubmitSection = () => {
    updateSectionToSubmitted(
      assessmentAttempt,
      assessment.slug,
      uuid,
      currentSection.id,
      currentSectionIndex,
      dispatch
    );
  };

  const handleStartSlot = () => {
    updateSlotToInProgress(
      assessmentAttempt,
      currentSectionIndex,
      currentSlotIndex,
      assessment.slug,
      uuid,
      currentSection.id,
      currentSlot.id,
      dispatch
    );
  };

  const handleSubmitSlot = (answer, submitSection = false) => {
    updateSlotToSubmitted(
      assessmentAttempt,
      currentSectionIndex,
      currentSlotIndex,
      assessment.slug,
      uuid,
      currentSection.id,
      currentSlot,
      dispatch,
      answer,
      submitSection
    );
  };

  const handleSubmitClickableSlot = (
    newSlot = null,
    answer = null,
    submitSection = false
  ) => {
    updateClickableSlotToSubmitted(
      assessmentAttempt,
      currentSectionIndex,
      currentSlotIndex,
      assessment.slug,
      uuid,
      currentSection.id,
      currentSlot,
      dispatch,
      newSlot,
      answer,
      submitSection
    );
  };

  const handleValidateAssessmentStart = async body => {
    const {
      is_attempt_allowed: isNewAttemptAllowed,
      attempt_limit: attemptLimit
    } = await validateAttemptLimit(
      body.email,
      assessment.configuration.assessment_id,
      uuid
    );

    if (!isNewAttemptAllowed) {
      return { wasMaxAttemptCompleted: true, attemptLimit };
    } else {
      handleCreateTaker(body);
      return { wasMaxAttemptCompleted: false, attemptLimit };
    }
  };

  const handleCreateTaker = body => {
    createTaker(assessment.slug, uuid, dispatch, body);
  };

  const handleCreateStockTick = () => {
    return createStockTick(
      assessment.slug,
      uuid,
      currentSection.id,
      currentSlot.id,
      dispatch,
      assessmentAttempt,
      currentSectionIndex,
      currentSlotIndex
    );
  };

  const handleCreateStockAction = body => {
    return createStockAction(
      assessment.slug,
      uuid,
      currentSection.id,
      currentSlot.id,
      dispatch,
      body,
      assessmentAttempt,
      currentSectionIndex,
      currentSlotIndex
    );
  };

  const handleGetSection = () => {
    getSection(
      assessmentAttempt,
      assessment.slug,
      uuid,
      currentSection.id,
      currentSectionIndex,
      dispatch
    );
  };

  const handleGetSlot = () => {
    getSlot(
      assessmentAttempt,
      currentSectionIndex,
      currentSlotIndex,
      assessment.slug,
      uuid,
      currentSection.id,
      currentSlot.id,
      dispatch
    );
  };

  const handleGetStockSectionMeta = () => {
    getStockSectionMeta(
      assessmentAttempt,
      assessment.slug,
      uuid,
      currentSection.id,
      currentSectionIndex,
      dispatch
    );
  };

  return {
    handleUpdateAssessment,
    handleStartSection,
    handleSubmitSection,
    handleStartSlot,
    handleSubmitSlot,
    handleSubmitClickableSlot,
    handleCreateTaker,
    handleCreateStockTick,
    handleCreateStockAction,
    handleGetSection,
    handleGetSlot,
    handleGetStockSectionMeta,
    handleValidateAssessmentStart
  };
};
