import { useContext } from 'react';
import { ThemeContext } from './themeContext';

export const useThemeContext = () => {
  const { themeState, themeDispatch } = useContext(ThemeContext);

  return {
    themeState,
    themeDispatch
  };
};
