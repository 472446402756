export const reconcileAnswer = (
  actions,
  currentSlot,
  currentSlotId,
  answer
) => {
  if (currentSlot.question.can_select_multiple) {
    const answerOptions = currentSlot.question.options.filter(opt => {
      return answer.options.map(ans => ans.id).includes(opt.id);
    });
    const formattedAnswer = answerOptions.map(
      opt => opt.content[0].text_display
    );
    actions.setValues({
      [currentSlotId]: formattedAnswer
    });
  } else {
    const answerOption = currentSlot.question.options.find(opt => {
      return answer.options[0] && opt.id === answer.options[0].id;
    });
    const formattedAnswer = answerOption
      ? answerOption.content[0].text_display
      : [];
    actions.setValues({
      [currentSlotId]: formattedAnswer
    });
  }
};
