import { useContext } from 'react';
import { AssessmentContext } from './assessmentContext';

export const useAssessmentContext = () => {
  const { state, dispatch } = useContext(AssessmentContext);
  const { assessmentAttempt, currentSectionIndex, currentSlotIndex } = state;

  let currentSection;
  let currentSlot;

  if (Number.isInteger(currentSectionIndex)) {
    currentSection = assessmentAttempt?.section_attempts[currentSectionIndex];
  }

  if (Number.isInteger(currentSlotIndex)) {
    currentSlot = currentSection?.slot_attempts[currentSlotIndex];
  }

  return {
    state,
    dispatch,
    assessmentAttempt,
    currentSection,
    currentSlot
  };
};
