import React from 'react';
import Checkbox from 'components/global/checkbox/Checkbox';
import Radio from 'components/global/radio/Radio';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';

const QuestionSelect = ({ formikProps }) => {
  const {
    currentSlot,
    state: { currentSlotId }
  } = useAssessmentContext();
  const { can_select_multiple, content, options } = currentSlot.question;

  const renderSelect = formikProps => {
    // TODO: handle languages instead of hardcoding to first
    let stringOptions = options.map(option =>
      option.content[0] ? option.content[0].text_display : 'No option given'
    );
    switch (can_select_multiple) {
      case true:
        return (
          <Checkbox
            name={currentSlotId}
            label={content[0] ? content[0].text_display : 'No label given'}
            description="Please select one or more options below"
            formikProps={formikProps}
            options={stringOptions}
            selectTotal={stringOptions.length}
          />
        );
      default:
        return (
          <Radio
            name={currentSlotId}
            label={content[0] ? content[0].text_display : 'No label given'}
            description="Please select a single option below"
            formikProps={formikProps}
            options={stringOptions}
          />
        );
    }
  };

  return <>{renderSelect(formikProps)}</>;
};

export default QuestionSelect;
