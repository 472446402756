import PropTypes from 'prop-types';
import React from 'react';
import { StyledButton, BtnText } from './styles/StyledComponents';
import LoadingSpinner from './components/LoadingSpinner';

const Button = ({
  disabled,
  loading,
  text,
  className,
  isFullWidth,
  onClick,
  textTransform
}) => {
  return (
    <StyledButton
      disabled={disabled}
      className={className}
      isFullWidth={isFullWidth}
      onClick={onClick}
      type="button"
      textTransform={textTransform}
    >
      {loading && !disabled && <LoadingSpinner className={className} />}
      <BtnText>{text}</BtnText>
    </StyledButton>
  );
};

Button.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string,
  isFullWidth: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  textTransform: PropTypes.oneOf([
    'capitalize',
    'uppercase',
    'lowercase',
    'none'
  ])
};

Button.defaultProps = {
  disabled: false,
  loading: false,
  text: 'button',
  className: '',
  isFullWidth: false,
  textTransform: 'none'
};

export default Button;
