export const shouldSubmitAssessment = (
  totalSections,
  currentSectionIndex,
  totalSlots,
  currentSlotIndex
) => {
  const totalSectionsLength = totalSections - 1;
  const totalSlotsLength = totalSlots - 1;

  if (totalSectionsLength === 0) {
    return true;
  }
  if (
    totalSectionsLength === currentSectionIndex &&
    totalSlotsLength === currentSlotIndex
  ) {
    return true;
  }
  return false;
};
