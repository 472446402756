import PropTypes from 'prop-types';
import React from 'react';
import Button from 'components/global/Buttons/Button';
import {
  OpaqueWrapper,
  ModalDescription,
  ModalWrapper
} from 'components/local/modals/styles/StyledComponents';

const Modal = ({ handleAccept, handleClose, modalType }) => {
  const handleContinue = () => {
    handleClose();
    handleAccept();
  };

  const modalMessages = {
    submitSlot: {
      description:
        "You haven't answered this question yet, are you sure you want to skip it?",
      warning: 'Once you agree, you cannot come back!',
      accept: 'Yes, Next Question',
      close: 'back'
    },
    submitSection: {
      description: 'You are attempting to finish this section.',
      warning: 'Once you agree, you cannot come back!',
      accept: 'Yes, Complete Section',
      close: 'back'
    },
    submitAssessment: {
      description: 'You are attempting to submit this assessment.',
      warning: 'Once you agree, you cannot come back!',
      accept: 'Yes, Submit Assessment',
      close: 'back'
    },
    leaveAssessment: {
      description: 'Are you sure you want to leave this assessment?',
      warning: 'You are able to resume anytime',
      accept: 'leave assessment',
      close: 'continue'
    },
    copyDetected: {
      warning:
        'We have detected a potential attempt at copying assessment information from this system, which is strictly prohibited and violates our terms of service.',
      description: 'Copy detected',
      accept:
        'I understand and will not copy any information from this system.',
      close: 'Close'
    },
    tabChangeDetected: {
      warning:
        'We have detected you are leaving this page during the live assessment. To prevent potential cheating, we require you to remain in the browser for the duration of the assessment or until it has been submitted, whichever occurs first.',
      description: 'Tab change detected',
      accept: 'I understand and will not leave the assessment tab.',
      close: 'Close'
    },
    browserCaptureDetected: {
      warning:
        'We have detected a potential attempt at using keyboard shortcuts to navigate the assessment. This is strictly prohibited and violates our terms of service.',
      description: 'Web capture detected',
      accept:
        'I understand and will not use any third party tools to capture my screen.',
      close: 'Close'
    }
  };

  const { description, warning, accept, close } = modalMessages[modalType];

  return (
    <OpaqueWrapper id="modal">
      <ModalWrapper>
        <ModalDescription className="bold">{description}</ModalDescription>
        <ModalDescription margin="0 0 24px 0">{warning}</ModalDescription>
        <div className="buttonContainer">
          <div className="btn-left">
            <Button
              className="inverted"
              isFullWidth={true}
              onClick={handleClose}
              text={close}
              textTransform="uppercase"
            />
          </div>
          <div className="btn-right">
            <Button
              className="warning"
              isFullWidth={true}
              onClick={handleContinue}
              text={accept}
              textTransform="uppercase"
            />
          </div>
        </div>
      </ModalWrapper>
    </OpaqueWrapper>
  );
};

Modal.propTypes = {
  handleAccept: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  modalType: PropTypes.oneOf([
    'submitSlot',
    'submitSection',
    'submitAssessment',
    'leaveAssessment'
  ]).isRequired
};

export default Modal;
