import PropTypes from 'prop-types';
import React from 'react';
import CheckboxRow from 'components/global/checkbox/CheckboxRow';
import InputContainer from 'components/global/InputContainer';
import { useRenderErrors } from 'components/global/hooks/useRenderErrors';

const Checkbox = ({
  description,
  disabled,
  formikProps,
  label,
  name,
  optional,
  options,
  selectTotal
}) => {
  const { errors, submitCount } = formikProps;
  const showErrors = useRenderErrors(errors, submitCount, name);

  const renderBoxes = () => {
    return options.map((option, index) => {
      return (
        <CheckboxRow
          key={`option${index + 1}`}
          name={name}
          option={option}
          disabled={disabled}
          selectTotal={selectTotal}
          formikProps={formikProps}
        />
      );
    });
  };

  return (
    <InputContainer
      disabled={disabled}
      optional={optional}
      label={label}
      description={description}
      showErrors={showErrors}
      errors={errors}
      name={name}
    >
      {renderBoxes()}
    </InputContainer>
  );
};

Checkbox.propTypes = {
  description: PropTypes.string,
  disabled: PropTypes.bool,
  formikProps: PropTypes.object.isRequired,
  label: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  optional: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectTotal: PropTypes.number.isRequired
};

Checkbox.defaultProps = {
  description: '',
  disabled: false,
  label: '',
  optional: false
};

export default Checkbox;
