import React from 'react';
import styled from 'styled-components';

export const renderDropdownSvg = (disabled, setToggle, toggle) => {
  return (
    <DropdownSvg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="14"
      viewBox="0 0 24 24"
      fill="#484848"
      className={disabled ? 'disabled dropdown' : 'dropdown'}
      onClick={() => setToggle(!toggle)}
    >
      <path
        d={
          toggle
            ? 'M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z'
            : 'M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z'
        }
        className="dropdown"
      />
    </DropdownSvg>
  );
};

const DropdownSvg = styled.svg`
  position: absolute;
  z-index: 3;
  top: 24px;
  right: 20px;
  transform: translate(0, -50%);
  cursor: pointer;
  background: #f5f5f5;
  padding-left: 4px;
  &.disabled {
    pointer-events: none;
  }
`;
