import PropTypes from 'prop-types';
import React from 'react';
import { DotsItem, useTheme } from '@nivo/core';

const LinePoint = ({
  point: { id, x, y, color, borderColor, label },
  props: { pointSymbol, pointSize, pointBorderWidth, pointLabelYOffset },
  lastPoint
}) => {
  const theme = useTheme();
  return (
    <DotsItem
      key={id}
      x={x}
      y={y}
      datum={{}}
      symbol={pointSymbol}
      size={lastPoint ? pointSize : pointSize / 2}
      color={color}
      borderWidth={pointBorderWidth}
      borderColor={borderColor}
      label={label}
      labelYOffset={pointLabelYOffset}
      theme={theme}
    />
  );
};

LinePoint.propTypes = {
  point: PropTypes.object.isRequired,
  props: PropTypes.object.isRequired,
  lastPoint: PropTypes.bool
};

LinePoint.defaultProps = {
  lastPoint: false
};

export default LinePoint;
