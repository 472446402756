import PropTypes from 'prop-types';
import React from 'react';
import { InnerBar, OuterBar } from './styles/StyledComponents';

const ProgressBarLinear = ({ slots, currentSlot }) => {
  const progressPercentage = ((currentSlot + 1) / slots) * 100;

  return (
    <OuterBar>
      <InnerBar width={progressPercentage}></InnerBar>
    </OuterBar>
  );
};

ProgressBarLinear.propTypes = {
  slots: PropTypes.number.isRequired,
  currentSlot: PropTypes.number.isRequired
};

export default ProgressBarLinear;
