import React from 'react';
import styled from 'styled-components';
import BottomNavBar from 'components/local/navbar/BottomNavBar';
import Button from 'components/global/Buttons/Button';
import { parseHtml } from 'containers/app/helpers/parseHtml';
import { useHandleRequest } from 'containers/inProgressPage/hooks/useHandleRequest';

const QuestionStockInstructions = ({ question, updateTick }) => {
  const { handleSubmitSlot } = useHandleRequest();

  return (
    <Flex>
      <InstructionsWrapper>
        <Wrapper>
          <Title>{question.content[0].title}</Title>
        </Wrapper>
        <Divider />
        <Wrapper>
          <SmallText>
            You will be able to access this information during trading.
          </SmallText>
          {parseHtml(question.content[0].text_display)}
          {process.env.REACT_APP_QA === '1' && (
            <Button
              text="SKIP"
              className="small"
              onClick={() => handleSubmitSlot()}
            />
          )}
        </Wrapper>
      </InstructionsWrapper>
      <BottomNavBar
        handleClick={updateTick}
        buttonText="START TRADING"
        background="#dfddda"
        flat={true}
      />
    </Flex>
  );
};

const SmallText = styled.div`
  color: #6ebc96;
  font-size: 12px;
  letter-spacing: 0.4px;
  font-weight: 500;
  position: absolute;
  right: 40px;
  @media (max-width: 992px) {
    position: static;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 8%;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const InstructionsWrapper = styled.div`
  font-family: ${({ theme }) => theme.altFontFamily};
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  background: #f7f7f7;
  position: relative;
  padding: 40px 0;
  width: 60%;
  color: #2c374b;
  .instruction-header {
    font-size: 14px;
    letter-spacing: 0.2px;
    font-weight: 500;
    margin: 10px 0;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #dfddda;
  margin: 20px 0;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 0 40px;
`;

export default QuestionStockInstructions;
