import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import uploadIcon from '../assets/uploadIcon';
import { useAssesmentContext } from 'services/context/assessmentContext/assessmentContext';

const KILO_BYTES_PER_BYTE = 1000;
const convertBytesToKB = bytes => Math.round(bytes / KILO_BYTES_PER_BYTE);
export const FileUploadInput = ({
  label,
  setFieldValue,
  name,
  multiple,
  handleChange,
  formikProps
}) => {
  const fileInputField = useRef(null);
  const {
    state: { files },
    dispatch
  } = useAssesmentContext();

  useEffect(() => {
    // Remove any files that may be in the context when the component mounts
    dispatch({
      type: 'SET_FILE',
      payload: {}
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addFile = file => {
    if (multiple) {
      dispatch({
        type: 'SET_FILE',
        payload: { ...files, [file.name]: file }
      });
    } else {
      formikProps.setFieldValue(name, file);

      dispatch({
        type: 'SET_FILE',
        payload: {}
      });
      dispatch({
        type: 'SET_FILE',
        payload: { [file.name]: file }
      });
    }
  };

  const handleNewFileUpload = e => {
    const files = e.target.files;
    handleChange(e);
    if (files.length > 0) {
      addFile(files[0]);
      setFieldValue(name, files[0]);
    }
  };

  const removeFile = fileName => {
    formikProps.resetForm({
      ...formikProps.values,
      [name]: null
    });
    formikProps.setFieldValue(name, null);

    dispatch({
      type: 'SET_FILE',
      payload: {}
    });
  };

  return (
    <>
      <FileUploadContainer>
        <InputLabel>{label}</InputLabel>
        <DragDropText>
          Drag and drop your file here, or click to select a file
        </DragDropText>
        {Object.keys(files).length === 0 && uploadIcon}
        {Object.keys(files).length > 0 && (
          <FilePreviewContainer>
            <PreviewList>
              {Object.keys(files).map((fileName, index) => {
                let file = files[fileName];
                let isImageFile = file.type.split('/')[0] === 'image';
                return (
                  <PreviewContainer key={fileName}>
                    <div>
                      {isImageFile && (
                        <ImagePreview
                          src={URL.createObjectURL(file)}
                          alt={`file preview ${index}`}
                        />
                      )}
                      <FileMetaData isImageFile={isImageFile}>
                        <span>{file.name}</span>
                        <aside>
                          <span>{convertBytesToKB(file.size)} kb</span>
                          <p onClick={() => removeFile(fileName)}>X</p>
                        </aside>
                      </FileMetaData>
                    </div>
                  </PreviewContainer>
                );
              })}
            </PreviewList>
          </FilePreviewContainer>
        )}
        <UploadInput
          name={name}
          type="file"
          ref={fileInputField}
          onChange={e => handleNewFileUpload(e)}
          title=""
          value=""
          multiple={multiple}
        />
      </FileUploadContainer>
    </>
  );
};

const FileUploadContainer = styled.section`
  align-items: center;
  background-color: white;
  border: 2px dotted lightgray;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin: 25px 0 15px;
  padding: 35px 20px;
  position: relative;
`;

const UploadInput = styled.input`
  border: none;
  bottom: 0;
  display: block;
  font-size: 18px;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  text-transform: none;
  top: 0;
  width: 100%;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const InputLabel = styled.label`
  color: black;
  font-size: 13px;
  left: 0;
  position: absolute;
  top: -21px;
`;

const DragDropText = styled.p`
  font-family: AvenirLTPro-Roman;
  margin-top: 0;
  text-align: center;
`;

const FilePreviewContainer = styled.article`
  z-index: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  span {
    font-size: 14px;
  }
`;

const PreviewList = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  @media only screen and (max-width: 400px) {
    flex-direction: column;
  }
`;

const FileMetaData = styled.div`
  background-color: rgba(5, 5, 5, 0.55);
  border-radius: 6px;
  bottom: 0;
  color: white;
  display: ${props => (props.isImageFile ? 'none' : 'flex')};
  flex-direction: column;
  font-weight: bold;
  left: 0;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 0;

  aside {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }

  p {
    cursor: pointer;
  }
`;

const PreviewContainer = styled.section`
  border-radius: 6px;
  box-sizing: border-box;
  height: 120px;
  padding: 0.25rem;
  width: 200px;
  &:hover {
    opacity: 0.55;

    ${FileMetaData} {
      display: flex;
    }
  }

  & > div:first-of-type {
    height: 100%;
    position: relative;
  }

  @media only screen and (max-width: 750px) {
    width: 25%;
  }

  @media only screen and (max-width: 500px) {
    width: 50%;
  }

  @media only screen and (max-width: 400px) {
    width: 100%;
    padding: 0 0 0.4em;
  }
`;

const ImagePreview = styled.img`
  object-fit: cover;
  border-radius: 6px;
  height: 100%;
  width: 100%;
`;
