import React from 'react';
import { parseHtml } from 'containers/app/helpers/parseHtml';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';

const SlotStatic = () => {
  const { currentSlot } = useAssessmentContext();

  return <>{parseHtml(currentSlot.static_page.text_display)}</>;
};

export default SlotStatic;
