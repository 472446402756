import React from 'react';
import BalanceTable from 'components/global/tables/balanceTable/BalanceTable';
import { findPortfolio } from 'containers/inProgressPage/subcomponents/stock/helpers/findPortfolio';

const MultiBalanceTable = ({
  assets,
  hasInsurance,
  insuranceDelta,
  balances,
  portfolios,
  bots,
  summary,
  startingBalance
}) => {
  const renderBotTables = () => {
    return bots.map(bot => (
      <div className={summary ? 'col-xs-6' : 'col-xs-12'} key={bot.id}>
        <BalanceTable
          assets={assets}
          hasInsurance={hasInsurance}
          insuranceDelta={insuranceDelta}
          balances={balances}
          currentPortfolio={findPortfolio(portfolios, bot.id)}
          header={bot.bot.player_name}
          botId={bot.id}
          startingBalance={startingBalance}
        />
      </div>
    ));
  };

  return (
    <>
      <div className={summary ? 'col-xs-6' : 'col-xs-12'}>
        <BalanceTable
          assets={assets}
          hasInsurance={hasInsurance}
          insuranceDelta={insuranceDelta}
          balances={balances}
          currentPortfolio={findPortfolio(portfolios)}
          header={bots.length ? 'Your Balance' : 'Current Balance'}
          startingBalance={startingBalance}
        />
      </div>
      {!!bots.length && renderBotTables()}
    </>
  );
};

export default MultiBalanceTable;
