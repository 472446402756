import { axios } from 'services/requests/helpers/AxiosClient';
import { saveAs } from 'file-saver';
import { configureEndpoint } from 'services/requests/helpers/configureEndpoint';
import { determineAnswerType } from 'containers/inProgressPage/helpers/determineAnswerType';
import { moveToNextSlot } from 'services/requests/helpers/moveToNextSlot';
import { setErrorPage } from 'services/requests/helpers/setErrorPage';
import { setError } from 'services/requests/helpers/setError';
import { updateAssessmentData } from 'services/requests/helpers/updateAssessmentData';
import {
  SET_ERROR,
  SUBMIT_SLOT,
  SET_QUESTION_VALUE
} from 'services/context/assessmentContext/assessmentContext';
const PROCTORING = 'proctoring';
const SUBMISSION_ERROR = 'Your response could not be submitted';

// TODO: REFACTOR ALL REQUESTS TO BE MORE MODULAR AND LESS BAD

// Fetch slot
export const getSlot = async (
  assessment,
  sectionIndex,
  slotIndex,
  slug,
  uuid,
  sectionId,
  slotId,
  dispatch
) => {
  const requestUrl = configureEndpoint(
    PROCTORING,
    slug,
    uuid,
    sectionId,
    slotId
  );
  try {
    const response = await axios.get(requestUrl);

    assessment.section_attempts[sectionIndex].slot_attempts[slotIndex] =
      response.data.data;
    assessment.requestTime = response.headers['request-duration'];

    updateAssessmentData(assessment, dispatch);
  } catch ({ response }) {
    response?.status === 410 && setErrorPage(response, dispatch);
    setError(SUBMISSION_ERROR, dispatch);
  }
};

// Set slot to in progress
export const updateSlotToInProgress = async (
  assessment,
  sectionIndex,
  slotIndex,
  slug,
  uuid,
  sectionId,
  slotId,
  dispatch
) => {
  const requestUrl = configureEndpoint(
    PROCTORING,
    slug,
    uuid,
    sectionId,
    slotId
  );

  try {
    const response = await axios.put(requestUrl);

    dispatch({
      type: SET_ERROR,
      payload: null
    });

    dispatch({
      type: SET_QUESTION_VALUE,
      payload: null
    });

    // if tick_index in data, calibrate time
    if (response.data.data.tick_index) {
      response.data.data.tick_index.request_time =
        response.headers['request-duration'];
    }

    assessment.section_attempts[sectionIndex].slot_attempts[slotIndex] =
      response.data.data;
    assessment.requestTime = response.headers['request-duration'];

    updateAssessmentData(assessment, dispatch);
  } catch ({ response }) {
    response?.status === 410 && setErrorPage(response, dispatch);
    setError(SUBMISSION_ERROR, dispatch);
  }
};

export const putFileToSubmitted = async (slotId, fileContent, onError) => {
  const requestUrl = configureEndpoint(PROCTORING, slotId, 'file');
  const formData = new FormData();
  formData.append('file', fileContent);

  try {
    const response = await axios.put(requestUrl, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response;
  } catch (error) {
    onError && onError();
    console.error('Error uploading file:', error);
  }
};

// Set slot to submitted
export const updateSlotToSubmitted = async (
  assessment,
  sectionIndex,
  slotIndex,
  slug,
  uuid,
  sectionId,
  slot,
  dispatch,
  answer,
  submitSection
) => {
  const requestUrl = configureEndpoint(
    PROCTORING,
    slug,
    uuid,
    sectionId,
    slot.id
  );
  const answerObj = determineAnswerType(slot.question, answer);

  try {
    dispatch({
      type: SUBMIT_SLOT
    });

    const response = await axios.post(requestUrl, answerObj);

    dispatch({
      type: SET_ERROR,
      payload: null
    });

    assessment.section_attempts[sectionIndex].slot_attempts[slotIndex] =
      response.data.data;
    assessment.requestTime = response.headers['request-duration'];

    moveToNextSlot(
      slotIndex,
      sectionIndex,
      assessment,
      dispatch,
      submitSection
    );
  } catch ({ response }) {
    response?.status === 410 && setErrorPage(response, dispatch);
    // Do not display error here as we currently have issue with stock questions
    // spamming slot submission and throwing 409s. #4080
  }
};

// Set clickable slot to submitted
export const updateClickableSlotToSubmitted = async (
  assessment,
  sectionIndex,
  slotIndex,
  slug,
  uuid,
  sectionId,
  slot,
  dispatch,
  newSlotIndex,
  answer,
  submitSection
) => {
  const requestUrl = configureEndpoint(
    PROCTORING,
    slug,
    uuid,
    sectionId,
    slot.id
  );
  const answerObj = determineAnswerType(slot.question, answer);
  try {
    const response = await axios.post(requestUrl, answerObj);

    dispatch({
      type: SET_ERROR,
      payload: null
    });

    assessment.section_attempts[sectionIndex].slot_attempts[slotIndex] =
      response.data.data;
    assessment.requestTime = response.headers['request-duration'];

    moveToNextSlot(
      newSlotIndex,
      sectionIndex,
      assessment,
      dispatch,
      submitSection
    );
  } catch ({ response }) {
    response?.status === 410 && setErrorPage(response, dispatch);
    // Do not display error here as we currently have issue with stock questions
    // spamming slot submission and throwing 409s. #4080
  }
};

export const createStockTick = async (
  slug,
  uuid,
  sectionId,
  slotId,
  dispatch,
  assessment,
  sectionIndex,
  slotIndex
) => {
  const requestUrl = configureEndpoint(
    PROCTORING,
    'stock-attempt',
    'tick',
    slug,
    uuid,
    sectionId,
    slotId
  );
  try {
    const response = await axios.post(requestUrl);
    const {
      portfolios,
      balances,
      actions,
      tick_index,
      previous_tick_index
    } = response.data.data;
    const newAssessment = { ...assessment };

    const answer =
      newAssessment.section_attempts[sectionIndex].slot_attempts[slotIndex]
        .answer;

    // Append new balances portfolios and actions to the current stat of the answer object
    answer.portfolios = answer.portfolios.concat(portfolios);

    answer.balances = answer.balances.concat(balances);

    answer.actions = answer.actions.concat(actions);

    // If the tick_index exists replace it with the updated state from the request
    if (tick_index !== null)
      answer.tick_indexes[tick_index._index] = tick_index;

    // On the first tick previous tick index will be the first tick.
    if (previous_tick_index._index !== tick_index._index) {
      if (answer.tick_indexes[previous_tick_index._index].started_at === null) {
        // Previous tick must have been skipped, so reload slot.
        await getSlot(
          assessment,
          sectionIndex,
          slotIndex,
          slug,
          uuid,
          sectionId,
          slotId,
          dispatch
        );
        return;
      }
      answer.tick_indexes[previous_tick_index._index] = previous_tick_index;
    }

    newAssessment.requestTime = response.headers['request-duration'];
    // Append the ticks from the "tick_index" into the asset object
    // TODO: perhaps worth refactoring the location of where these ticks are held at some point?
    for (let i in tick_index.ticks) {
      // Find asset
      answer.answer_stock_assets
        .find(asset => asset.id === tick_index.ticks[i].answer_stock_asset_id)
        .ticks.push(tick_index.ticks[i]);
    }
    newAssessment.section_attempts[sectionIndex].slot_attempts[
      slotIndex
    ].answer = answer;
    updateAssessmentData(newAssessment, dispatch);
    // return response;
  } catch ({ response }) {
    response?.status === 410 && setErrorPage(response, dispatch);
    // Do not display error here as we currently have issue with stock questions
    // spamming slot submission and throwing 409s. #4080
    return Promise.resolve();
  }
};

export const createStockAction = async (
  slug,
  uuid,
  sectionId,
  slotId,
  dispatch,
  body,
  assessment,
  sectionIndex,
  slotIndex
) => {
  const requestUrl = configureEndpoint(
    PROCTORING,
    'stock-attempt',
    'action',
    slug,
    uuid,
    sectionId,
    slotId
  );
  try {
    const response = await axios.post(requestUrl, body);
    assessment.section_attempts[sectionIndex].slot_attempts[
      slotIndex
    ].answer.actions.push(response.data.data['action']);

    // Get all balances
    let _balances =
      assessment.section_attempts[sectionIndex].slot_attempts[slotIndex].answer
        .balances;
    // Get all portfolios
    let _portfolios =
      assessment.section_attempts[sectionIndex].slot_attempts[slotIndex].answer
        .portfolios;

    // Get the updated balance from the action request
    let balance = response.data.data['balance'];
    // Get the updated portfolio from the action request
    let portfolio = response.data.data['portfolio'];

    // Iterate backwards to speed up the search in some cases.
    // This is helpful because as the scenario progresses there is more data to search through
    // so moving backwards will optimize for the growth in data.
    for (let i = _balances.length - 1; i >= 0; i--) {
      // replace the old balance with the new balance
      if (_balances[i].id === balance.id) {
        assessment.section_attempts[sectionIndex].slot_attempts[
          slotIndex
        ].answer.balances[i] = balance;
        break;
      }
    }
    // Same for portfolios
    for (let i = _portfolios.length - 1; i >= 0; i--) {
      if (_portfolios[i].id === portfolio.id) {
        assessment.section_attempts[sectionIndex].slot_attempts[
          slotIndex
        ].answer.portfolios[i] = portfolio;
        break;
      }
    }
    assessment.requestTime = response.headers['request-duration'];

    updateAssessmentData(assessment, dispatch);
    return response;
  } catch ({ response }) {
    response?.status === 410 && setErrorPage(response, dispatch);
    return Promise.resolve();
  }
};

export const downloadFile = async (fileUrl, fileName, dispatch) => {
  try {
    saveAs(fileUrl, fileName);
  } catch (error) {
    setError(`Error downloading file ${fileName} ${error}`, dispatch);
  }
};
