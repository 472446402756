import styled from 'styled-components';

export const PageWrapper = styled.div`
  background: ${({ background, theme }) => background || theme.white};
  color: ${({ color }) => color};
  font-family: ${({ theme }) => theme.fontFamily};
  min-height: 100vh;
  padding: 116px 16px;
`;

export const FlexContainer = styled.div`
  align-items: ${({ alignItems }) => alignItems};
  display: flex;
  flex-direction: ${({ direction }) => direction};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  height: ${({ height }) => height};
  justify-content: ${({ justifyContent }) => justifyContent};
  margin: ${({ margin }) => margin};
  max-width: ${({ maxWidth }) => maxWidth};
  padding: ${({ padding }) => padding};
  width: ${({ width }) => width};
`;
