import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { convertTime } from 'containers/inProgressPage/helpers/convertTime';
import {
  Countdown,
  CountdownWrapper
} from 'components/local/timer/styles/StyledComponents';
import 'react-circular-progressbar/dist/styles.css';

const DisabledTimer = ({ time }) => {
  return (
    <CountdownWrapper disabled={true}>
      <CircularProgressbar value={100} strokeWidth={50} />
      <Countdown>{convertTime(time)}</Countdown>
    </CountdownWrapper>
  );
};

export default DisabledTimer;
