import PropTypes from 'prop-types';
import React from 'react';
import ChartContainer from 'components/global/charts/ChartContainer';
import MultiBalanceTable from 'containers/inProgressPage/subcomponents/stock/MultiBalanceTable';
import MultiTransactionTable from 'containers/inProgressPage/subcomponents/stock/MultiTransactionTable';
import PortfolioTable from 'components/global/tables/PortfolioTable';
import TransactionTable from 'components/global/tables/TransactionTable';
import { SectionHeader } from 'containers/inProgressPage/subcomponents/stock/StyledComponents';
import { findBalance } from 'containers/inProgressPage/subcomponents/stock/helpers/findBalance';

const StockSummary = ({ currentSlot, startingBalance }) => {
  const {
    question: { tick_count, has_insurance, insurance_delta, insurance_premium },
    answer: {
      answer_stock_assets,
      tick_indexes,
      portfolios,
      balances,
      bots,
      actions
    }
  } = currentSlot;

  const hasBots = !!bots.length;

  return (
    <>
      <div className="row">
        <div className="col-xs-12">
          <ChartContainer
            tickIndexes={tick_indexes}
            assets={answer_stock_assets}
            actions={actions}
            ticks={answer_stock_assets[0].ticks}
            tickCount={tick_count}
            insuranceBalance={findBalance(balances, {
              insuranceDelta: insurance_delta
            })}
            bots={bots}
            portfolios={portfolios}
            startingBalance={startingBalance}
            v
            insurancePremium={insurance_premium}
            insuranceDelta={insurance_delta}
            summary
          />
        </div>
      </div>
      {hasBots ? (
        <MultiTransactionTable
          assets={answer_stock_assets}
          actions={actions}
          insurancePremium={insurance_premium}
          bots={bots}
        />
      ) : (
        <div className="row">
          <div className="col-xs-4">
            <SectionHeader margin="32px 0 0">TRANSACTIONS</SectionHeader>
            <TransactionTable
              assets={answer_stock_assets}
              actions={actions}
              insurancePremium={insurance_premium}
              bots={bots}
              summary
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-xs-12">
          <SectionHeader margin="32px 0 0">PORTFOLIO VALUE</SectionHeader>
          <PortfolioTable
            portfolios={portfolios}
            bots={hasBots ? bots : null}
            startingBalance={startingBalance}
            balances={balances}
            assets={answer_stock_assets}
          />
        </div>
      </div>
      <div className="row">
        <SectionHeader margin="32px 0 0" className="col-xs-12">
          CURRENT BALANCE
        </SectionHeader>
      </div>
      <div className="row between-xs">
        <MultiBalanceTable
          assets={answer_stock_assets}
          hasInsurance={has_insurance}
          insuranceDelta={insurance_delta}
          portfolios={portfolios}
          balances={balances}
          bots={bots}
          summary={true}
          startingBalance={startingBalance}
        />
      </div>
    </>
  );
};

StockSummary.propTypes = {
  currentSlot: PropTypes.object.isRequired,
  startingBalance: PropTypes.number.isRequired
};

export default StockSummary;
