import axios from 'axios';

const axiosClient = axios.create();

axiosClient.interceptors.request.use(config => {
  config.headers['request-startTime'] = Date.now();
  return config;
});

axiosClient.interceptors.response.use(response => {
  const start = response.config.headers['request-startTime'];
  const end = Date.now();
  const time_diff = end - start;
  response.headers['request-duration'] = time_diff;
  return response;
});

export { axiosClient as axios };
