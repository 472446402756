import React, { createContext, useReducer } from 'react';

// ASSESSMENT CONTEXT
// This context aims to be the global store of high-level assessment data
// slug, uuid, attempt state

// 1. Setting the context initial state value
export const initialAssessmentState = {
  assessmentAttempt: null,
  assessmentIsLoading: true,
  sectionIsLoading: true,
  currentSectionIndex: null,
  slotIsLoading: true,
  currentSlotIndex: null,
  currentSlotId: null,
  // slotContentIsLoading should be set to false to allow submit button to work for starting assessment
  slotContentIsLoading: false,
  error: null,
  assessmentError: null,
  questionValue: null,
  files: {}
};

// 2. Assigning initial state to context
export const AssessmentContext = createContext(initialAssessmentState);

// 3. Establishing variables to define reducer actions
export const SET_PAGE = 'SET_PAGE';
export const UPDATE_ASSESSMENT = 'UPDATE_ASSESSMENT';
export const SET_CURRENT_SECTION = 'SET_CURRENT_SECTION';
export const SET_CURRENT_SLOT = 'SET_CURRENT_SLOT';
export const MOVE_TO_NEW_SLOT = 'MOVE_TO_NEW_SLOT';
export const MOVE_TO_NEXT_SECTION = 'MOVE_TO_NEXT_SECTION';
export const SET_ERROR = 'SET_ERROR';
export const SUBMIT_SLOT = 'SUBMIT_SLOT';
export const SET_QUESTION_VALUE = 'SET_QUESTION_VALUE';
export const SET_FILE = 'SET_FILE';

// 4. Setting up reducer
const assessmentReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_PAGE: {
      return {
        ...state,
        assessmentAttempt: payload.assessment,
        assessmentIsLoading: payload.assessmentIsLoading,
        assessmentError: payload.assessmentError
      };
    }
    case UPDATE_ASSESSMENT: {
      return {
        ...state,
        assessmentAttempt: payload.assessment,
        slotIsLoading: false,
        slotContentIsLoading: false
      };
    }
    case SET_CURRENT_SECTION: {
      return {
        ...state,
        currentSectionIndex: payload.currentSectionIndex,
        sectionIsLoading: payload.sectionIsLoading
      };
    }
    case SET_CURRENT_SLOT: {
      return {
        ...state,
        currentSlotId: payload.currentSlotId,
        currentSlotIndex: payload.currentSlotIndex,
        slotContentIsLoading: true
      };
    }
    case MOVE_TO_NEW_SLOT: {
      return {
        ...state,
        assessmentAttempt: payload.assessment,
        currentSlotId: payload.currentSlotId,
        currentSlotIndex: payload.currentSlotIndex,
        slotContentIsLoading: true
      };
    }
    case MOVE_TO_NEXT_SECTION: {
      return {
        ...state,
        assessmentAttempt: payload.assessment,
        currentSectionIndex: payload.currentSectionIndex,
        sectionIsLoading: payload.sectionIsLoading,
        currentSlotIndex: 0,
        currentSlotId: null
      };
    }
    case SET_ERROR: {
      return {
        ...state,
        error: payload,
        slotContentIsLoading: false
      };
    }
    case SUBMIT_SLOT: {
      return {
        ...state,
        slotContentIsLoading: true
      };
    }
    case SET_QUESTION_VALUE: {
      return {
        ...state,
        questionValue: payload
      };
    }
    case SET_FILE: {
      return {
        ...state,
        files: payload
      };
    }
    default: {
      return state;
    }
  }
};

// 5. Passing in reducer and state to create a parent level provider
const AssessmentProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    assessmentReducer,
    initialAssessmentState
  );

  return (
    <AssessmentContext.Provider value={{ state, dispatch }}>
      {children}
    </AssessmentContext.Provider>
  );
};

export const useAssesmentContext = () => {
  const context = React.useContext(AssessmentContext);
  if (!context) {
    throw new Error(
      'useAssesmentContext must be used within a AssessmentProvider'
    );
  }
  return context;
};

export default AssessmentProvider;

// Other Notes:
// Provider gets import into index.js to provide global access to consumers
// Context gets consumed with useAssessmentContext hook
