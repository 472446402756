import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useGlobalEventListener } from 'components/global/hooks/useGlobalEventListener';
import { useInputFocus } from 'components/global/hooks/useInputFocus';
import {
  Input,
  InputLabel,
  InputDescriptor,
  InputWrapper,
  InputHolder,
  InputUnderlineInner,
  InputUnderlineOuter,
  Option,
  DropdownOptions
} from '../styles/StyledComponents';
import { renderDropdownSvg } from './assets/renderDropdownSvg';

const Dropdown = ({
  name,
  label,
  description,
  placeholder,
  disabled,
  options,
  register,
  setValue,
  handleChange
}) => {
  const { focus, onFocus, onBlur } = useInputFocus();
  const [toggle, setToggle] = useState(false);

  const clickListener = ({ target }) => {
    if (options.includes(target.id)) {
      setToggle(false);
      onBlur();
      setValue(name, target.id);
      handleChange();
    } else if (target.name !== name && !target.classList.contains('dropdown')) {
      setToggle(false);
      onBlur();
    }
  };

  useGlobalEventListener('click', clickListener);
  useEffect(() => {
    setValue(name, options[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderChildren = () => {
    return options.map(option => {
      return (
        <Option id={option} key={option}>
          {option}
        </Option>
      );
    });
  };

  return (
    <InputWrapper className={disabled && 'disabled'} marginBottom="0">
      {label && <InputLabel>{label}</InputLabel>}
      {description && <InputDescriptor>{description}</InputDescriptor>}
      <InputHolder>
        {renderDropdownSvg(disabled, setToggle, toggle)}
        <Input
          name={name}
          onFocus={() => {
            onFocus();
            setToggle(true);
          }}
          placeholder={placeholder}
          disabled={disabled}
          ref={register}
          readOnly
        />
        {toggle && <DropdownOptions>{renderChildren()}</DropdownOptions>}
      </InputHolder>
      <InputUnderlineOuter>
        <InputUnderlineInner
          className={focus ? 'focus' : 'unfocus'}
        ></InputUnderlineInner>
      </InputUnderlineOuter>
    </InputWrapper>
  );
};

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired
};

Dropdown.defaultProps = {
  label: '',
  description: '',
  placeholder: 'Select',
  disabled: false
};

export default Dropdown;
