import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { alertIcon } from './assets/alertIcon';
import { clockIcon } from './assets/clockIcon';
import { privateIcon } from './assets/privateIcon';
import { starIcon } from './assets/starIcon';
import {
  FlexContainer,
  PageWrapper
} from 'components/local/styles/StyledComponents';
import { useThemeContext } from 'services/context/themeContext/useThemeContext';

import { useFlags } from 'launchdarkly-react-client-sdk';

const ASSESSMENT_STATE = Object.freeze({
  IS_EXPIRED: 'expired',
  IS_COMPLETED: 'completed'
});

const ErrorPage = ({ error }) => {
  const { proctorIsMaintenanceScheduled } = useFlags();
  const isMaintenanceError =
    proctorIsMaintenanceScheduled &&
    (parseInt(error.status) === 500 || parseInt(error.status) === 503);
  const { themeState } = useThemeContext();

  const renderHeader = () =>
    error.message.includes(ASSESSMENT_STATE.IS_COMPLETED) &&
    !isMaintenanceError ? (
      <ErrorHeader>Hold On</ErrorHeader>
    ) : isMaintenanceError ? (
      <ErrorHeader>System offline</ErrorHeader>
    ) : (
      <ErrorHeader>Oops!</ErrorHeader>
    );

  const renderIcon = () => {
    if (isMaintenanceError) {
      return <ErrorIcon>{clockIcon}</ErrorIcon>;
    }

    if (error.status === 403) {
      return <ErrorIcon>{privateIcon}</ErrorIcon>;
    }

    if (error.status === 404 || error.status === 500) {
      return <ErrorIcon>{alertIcon}</ErrorIcon>;
    }

    if (error.message.includes(ASSESSMENT_STATE.IS_COMPLETED)) {
      return <ErrorIcon>{starIcon}</ErrorIcon>;
    }

    if (error.message.includes(ASSESSMENT_STATE.IS_EXPIRED)) {
      return <ErrorIcon>{clockIcon}</ErrorIcon>;
    }

    return <ErrorIcon>{alertIcon}</ErrorIcon>;
  };

  // TODO: add link to feedback form for certain error codes

  const renderErrorCopy = () => {
    if (isMaintenanceError) {
      return (
        <ErrorCopy>
          <ErrorBody>
            Our platform is currently offline for scheduled maintenance.
            Assessments will be back online in approximately one hour. Thank you
            for your patience.
          </ErrorBody>
        </ErrorCopy>
      );
    } else {
      return (
        <>
          <ErrorBody>{error.message}</ErrorBody>
          <ErrorEmail>
            Please reach out to support@correlation-one.com if you need further
            assistance.
          </ErrorEmail>
          {error?.status && <ErrorCode>Error code: {error.status}</ErrorCode>}
        </>
      );
    }
  };

  return (
    <>
      <ErrorPageBanner>
        <ImgLogo src={themeState?.c1LogoUrl} />
      </ErrorPageBanner>
      <ErrorPageWrapper>
        <FlexContainer justifyContent="center" alignItems="center">
          <ErrorBox>
            {renderIcon()}
            {renderHeader()}
            {renderErrorCopy()}
          </ErrorBox>
        </FlexContainer>
      </ErrorPageWrapper>
    </>
  );
};

ErrorPage.propTypes = {
  error: PropTypes.object
};

ErrorPage.defaultProps = {
  error: {
    status: '404',
    message: "We can't seem to find the page you're looking for."
  }
};

export default ErrorPage;

const ErrorBody = styled.div`
  color: ${props => props.theme.black};
  font-family: ${({ theme }) => theme.mediumFontFamily};
  font-size: 16px;
  font-weight: 500;
  height: 24px;
  letter-spacing: 0.53px;
  text-align: center;
`;

const ErrorBox = styled.div`
  background: #fcfbfb;
  border-radius: 0px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  height: 722px;
  padding-top: 145px;
  text-align: center;
`;

const ErrorCode = styled.div`
  color: #787878;
  display: inline-block;
  font-family: ${({ theme }) => theme.mediumFontFamily};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.47px;
  margin: auto;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
`;

const ErrorEmail = styled.div`
  color: ${props => props.theme.black};
  font-family: ${({ theme }) => theme.mediumFontFamily};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.53px;
  margin-bottom: 64px;
  text-align: center;
  width: 800px;
`;

const ErrorHeader = styled.div`
  color: ${({ theme }) => theme.color};
  font-family: ${({ theme }) => theme.blackFontFamily};
  font-size: 80px;
  font-weight: 400;
  height: 80px;
  letter-spacing: 2.67px;
  margin-bottom: 48px;
  width: inline-block;
`;

const ErrorIcon = styled.div`
  margin-bottom: 68px;
`;

const ErrorPageBanner = styled.div`
  background: #292945;
  border-radius: 0px;
  width: auto;
`;

const ErrorPageWrapper = styled(PageWrapper)`
  background: #f0f2f4;
  color: ${({ theme }) => theme.white};
  min-height: 95vh;
  padding-bottom: 65px;
  padding-left: 208px;
  padding-right: 208px;
  padding-top: 113px;
`;

const ImgLogo = styled.img`
  margin-bottom: 16px;
  margin-left: 208px;
  margin-right: 1075px;
  margin-top: 18px;
  width: 157px;
`;

const ErrorCopy = styled.div`
  margin: 0 auto;
  max-width: 60%;
`;
