import { ResponsiveLine } from '@nivo/line';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import styled from 'styled-components';
import LinePoint from 'components/global/charts/LinePoint';
import { StockActionTypes } from 'containers/app/helpers/stockActionTypes';
import {
  WindowWrapper,
  Header,
  WindowBody
} from 'containers/inProgressPage/subcomponents/stock/StyledComponents';
import { insuranceIcon } from '../assets/insuranceIcon';

/*
  TODO: This should be broken out into at least two components
  1. The responsive line itself
  2. The tooltip

  Other possibilities:
  3. Insurance point/tooltip
  4. All four custom layers (either into one custom layers file or each to their own)
*/
const LineChart = ({
  showAssets,
  chartData,
  range,
  setRange,
  tickCount,
  actions,
  insuranceBalance,
  ticks,
  bots,
  omit,
  insurancePremium,
  insuranceDelta
}) => {
  const [showInsuranceTooltip, setShowInsuranceTooltip] = useState(true);
  // This custom layer appends a dot to the end of each line
  const LastPoints = ({ points, ...props }) => {
    const lastPoints = [];

    props.data.forEach(playerData => {
      lastPoints.push(playerData.data[playerData.data.length - 1]);
    });

    return (
      <g>
        {lastPoints.map(point => {
          let pointData = points.find(
            changethispoint => changethispoint.data.id === point.id
          );
          return (
            <LinePoint
              key={point.id}
              point={pointData}
              props={props}
              lastPoint
            />
          );
        })}
      </g>
    );
  };

  // // This custom layer places a dot on each line where a transaction has occurred
  const TransactionPoints = ({ points, ...props }) => {
    return points.map(point => {
      return (
        actions.filter(action => {
          if (showAssets) {
            if (
              action.answer_stock_tick_index_id === point.data.tickId &&
              !omit.includes(
                action.answer_bot_id
                  ? bots.find(bot => bot.id === action.answer_bot_id).bot
                      .player_name
                  : 'you'
              )
            ) {
              return action;
            } else return null;
          } else {
            if (
              action.answer_stock_tick_index_id === point.data.tickId &&
              !omit.includes(action.stockName) &&
              (action.answer_bot_id
                ? point.serieId ===
                  bots.find(bot => bot.id === action.answer_bot_id).bot
                    .player_name
                : point.serieId === 'you')
            ) {
              return action;
            } else return null;
          }
        }).length && <LinePoint key={point.id} point={point} props={props} />
      );
    });
  };

  /*
      This custom layer checks to see if there has been an insurance action
      on the current tick. If there has been it will display the insurance icon
      on the chart
    */
  const InsurancePoint = ({ points, ...props }) => {
    let insuranceActions;
    if (insuranceBalance) {
      insuranceActions = actions.filter(
        action => action.stock_action.action_type === StockActionTypes.INSURANCE
      );

      if (insuranceActions.length) {
        const x = props.xScale(ticks.length + 1);
        const y = props.yScale(insuranceBalance.insuredPrice);
        const lastInsuranceAction =
          insuranceActions[insuranceActions.length - 1];
        const currentTick = ticks[ticks.length - 1];
        if (insuranceBalance.insuredPrice < range.min) {
          setRange({
            ...range,
            min: Math.floor(insuranceBalance.insuredPrice / 20) * 20
          });
        }
        if (
          lastInsuranceAction.answer_stock_tick_index_id ===
            currentTick.answer_stock_tick_index_id &&
          insuranceBalance.quantity !== 0
        ) {
          return (
            <g
              key={1}
              transform={`translate(${x - 8}, ${y - 8})`}
              onClick={() => setShowInsuranceTooltip(!showInsuranceTooltip)}
              style={{ cursor: 'pointer' }}
            >
              {insuranceIcon}
              {showInsuranceTooltip && renderInsuranceTooltip(insuranceBalance)}
            </g>
          );
        }
      }
    }
  };

  /* 
    This tooltip is rendered either to the right or left of the icon
    dependant on how far through the scenario the taker is.
  */
  const renderInsuranceTooltip = ({ quantity, insuredPrice }) => {
    const renderLeft = ticks.length / tickCount > 0.58;
    const backgroundX = renderLeft ? '-295' : '22';
    const closeX = renderLeft ? '-14' : '302';
    const textX = renderLeft ? '-282' : '34';

    return (
      <>
        <rect
          x={backgroundX}
          y="-62"
          width="290px"
          height="70px"
          fill="#c5e4d5"
        />
        <text
          x={closeX}
          y="-51"
          fontSize="12"
          fontWeight="400"
          opacity="70%"
          width="307"
          fill="#2c374b"
        >
          x
        </text>
        <text x={textX} y="-39" fontSize="14" width="307" fill="#2c374b">
          {quantity} shares held will be sold automatically at
        </text>
        <text x={textX} y="-22" fontSize="14" width="307" fill="#2c374b">
          the price of <tspan fontWeight="bold">${insuredPrice}/share</tspan> if
          the market price
        </text>
        <text x={textX} y="-5" fontSize="14" width="307" fill="#2c374b">
          of the share drops below ${insuredPrice} next tick.
        </text>
      </>
    );
  };

  /* 
    The "slice" here comes from nivo. Each slice is essentially
    a vertical line cutting up the chart along each point on the 
    x-axis. 
  */
  const ToolTip = ({ ...props }) => {
    if (props.slice) {
      const { x: tickNum } = props.slice.points[0].data;
      return (
        <WindowWrapper width="fit-content">
          <Header>Tick {tickNum}</Header>
          <WindowBody>{renderContent(props.slice.points)}</WindowBody>
        </WindowWrapper>
      );
    }
  };

  /*
    This function finds all actions (if any) tied to each asset
    at the slice you are hovered over.
  */
  const renderContent = tickPoints => {
    // linePointInfo holds the name and price of each asset at the hovered tick.
    const linePointInfo = {};
    let currentTickId;
    tickPoints.forEach(assetPoint => {
      const { tickId, y: price } = assetPoint.data;
      linePointInfo[assetPoint.serieId] = {
        price,
        tickId,
        color: showAssets ? assetPoint.color : '#8b8782',
        playerColor: assetPoint.color
      };
      currentTickId = tickId;
    });

    // tickActions is an array that holds all actions for each asset on a given tick
    const tickActions = findTickActions(currentTickId);

    return renderTickActions(tickActions, linePointInfo);
  };

  // filters the list of actions to show only the actions for the current tick
  const findTickActions = currentTickId => {
    if (showAssets) {
      const tickActions = actions.filter(action => {
        return (
          !omit.includes(
            action.answer_bot_id
              ? bots.find(bot => bot.id === action.answer_bot_id).bot
                  .player_name
              : 'you'
          ) && currentTickId === action.answer_stock_tick_index_id
        );
      });

      return tickActions;
    } else {
      const tickActions = actions.filter(action => {
        return (
          !omit.includes(action.stockName) &&
          currentTickId === action.answer_stock_tick_index_id
        );
      });

      return tickActions;
    }
  };

  // Renders info about actions taken or displays the stock prices for the hovered slice
  const renderTickActions = (tickActions, linePointInfo) => {
    // If there are no actions just show the asset prices at this tick
    if (tickActions.length > 0) {
      // group actions by taker
      const actionsByTaker = {};
      tickActions.forEach(action => {
        let takerName = action.answer_bot_id
          ? bots.find(bot => bot.id === action.answer_bot_id).bot.player_name
          : 'you';
        actionsByTaker[takerName]
          ? actionsByTaker[takerName].push(action)
          : (actionsByTaker[takerName] = [action]);
      });
      // render each takers actions - the reverse sort keeps "You" first and maintains order
      return Object.keys(actionsByTaker)
        .sort()
        .reverse()
        .map(taker => {
          return renderTakersActions(
            taker,
            actionsByTaker[taker],
            linePointInfo
          );
        });
    } else {
      return Object.keys(linePointInfo)
        .sort()
        .map((assetName, index) => {
          if (showAssets) {
            return (
              <div key={index}>
                <StockName color={linePointInfo[assetName].color}>
                  {assetName}
                </StockName>{' '}
                at ${linePointInfo[assetName].price}
              </div>
            );
          } else {
            return (
              <div key={index}>
                <StockName color={linePointInfo[assetName].playerColor}>
                  {assetName}
                </StockName>{' '}
                at ${linePointInfo[assetName].price}
              </div>
            );
          }
        });
    }
  };

  // first combines like actions then passes the combined actions to a render function
  const renderTakersActions = (taker, takersActions, linePointInfo) => {
    const combinedActions = {};
    takersActions.forEach(action => {
      let currentActionType = '';
      if (action.stock_action.action_type === StockActionTypes.INSURANCE) {
        currentActionType = 'insurance';
      }
      if (action.stock_action.side === 'sell') {
        currentActionType += 'sell';
      } else {
        currentActionType += 'buy';
      }

      if (combinedActions[action.stockName]) {
        const combinedAction =
          combinedActions[action.stockName][currentActionType];
        if (combinedAction) {
          combinedAction.total_quantity += action.stock_action.quantity;
        } else {
          combinedActions[action.stockName][currentActionType] = {
            ...action,
            total_quantity: action.stock_action.quantity,
            player: action.answer_bot_id
              ? bots.find(bot => bot.id === action.answer_bot_id).bot
                  .player_name
              : 'you'
          };
        }
      } else {
        combinedActions[action.stockName] = {
          [currentActionType]: {
            ...action,
            total_quantity: action.stock_action.quantity,
            player: action.answer_bot_id
              ? bots.find(bot => bot.id === action.answer_bot_id).bot
                  .player_name
              : 'you'
          }
        };
      }
    });

    return (
      <div
        key={
          takersActions[0].answer_bot_id ||
          takersActions[0].answer_stock_asset_id
        }
      >
        <TakerName
          color={showAssets ? `#aea9a3` : linePointInfo[taker].playerColor}
        >
          {taker}:
        </TakerName>
        {Object.keys(combinedActions).map(asset =>
          renderCombinedActions(combinedActions[asset], linePointInfo)
        )}
      </div>
    );
  };

  const renderCombinedActions = (combinedActions, linePointInfo) => {
    return Object.keys(combinedActions).map(action => {
      const {
        id,
        stockName,
        total_quantity,
        stock_action: { side },
        currentTick: { price },
        player
      } = combinedActions[action];
      return (
        <div key={id}>
          {action.includes('insurance') && (
            <span>{pluralize('Option', total_quantity)} for </span>
          )}
          {total_quantity} {pluralize('share', total_quantity)} of{' '}
          <StockName
            color={
              linePointInfo[stockName]
                ? linePointInfo[stockName].color
                : linePointInfo[player].color
            }
          >
            {stockName}
          </StockName>{' '}
          <ActionType color={side === 'buy' ? '#6ebc96' : '#eaa657'}>
            {side === 'buy' ? 'bought' : 'sold'}
          </ActionType>{' '}
          {action.includes('insurance')
            ? side === 'buy'
              ? `for $${insurancePremium *
                  total_quantity} insuring price of $${price - insuranceDelta} `
              : `at $${combinedActions[action].stock_action.strike_price}`
            : `at $${price}`}
        </div>
      );
    });
  };

  const adjustedGridXValues = () => {
    const xAxisValues = [...Array(tickCount + 1).keys()];

    return xAxisValues.slice(2, xAxisValues.length).map(xValue => xValue);
  };

  return (
    <ChartArea height="360px" width="100%">
      <ResponsiveLine
        data={chartData}
        margin={{
          top: 45,
          right: 15,
          bottom: 30,
          left: 38
        }}
        xScale={{
          type: 'linear',
          min: 1,
          max: tickCount + 1
        }}
        // The order of the layers is important as the ones closer to the bottom overlap the ones above
        layers={[
          'grid',
          'markers',
          'axes',
          'areas',
          'crosshair',
          'lines',
          'points',
          'slices',
          'mesh',
          'legends',
          LastPoints,
          TransactionPoints,
          ToolTip,
          InsurancePoint
        ]}
        yScale={{
          type: 'linear',
          max: range.max,
          min: range.min,
          stacked: false,
          reverse: false
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0
        }}
        colors={{ datum: 'color' }}
        borderColor={{
          theme: 'background'
        }}
        pointSize={8}
        pointColor={{
          from: 'color',
          modifiers: []
        }}
        pointBorderWidth={8}
        pointBorderColor={{
          from: 'serieColor'
        }}
        enablePoints={false}
        enablePointLabel={false}
        useMesh={true}
        gridXValues={adjustedGridXValues()}
        theme={{
          textColor: '#bfbfbf',
          fontSize: 12,
          grid: {
            line: {
              stroke: '#e2e0df',
              strokeWidth: 1
            }
          }
        }}
        enableSlices="x"
        sliceTooltip={ToolTip}
      />
    </ChartArea>
  );
};

LineChart.propTypes = {
  showAssets: PropTypes.bool.isRequired,
  chartData: PropTypes.arrayOf(PropTypes.object).isRequired,
  range: PropTypes.object.isRequired,
  setRange: PropTypes.func.isRequired,
  tickCount: PropTypes.number.isRequired,
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  insuranceBalance: PropTypes.object,
  ticks: PropTypes.arrayOf(PropTypes.object).isRequired,
  bots: PropTypes.arrayOf(PropTypes.object).isRequired,
  omit: PropTypes.arrayOf(PropTypes.string).isRequired,
  insurancePremium: PropTypes.number,
  insuranceDelta: PropTypes.number
};

export default LineChart;

const ChartArea = styled.div`
  height: ${props => props.height};
  width: ${props => props.width};
`;

const StockName = styled.span`
  color: ${({ color }) => color};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
`;

const ActionType = styled.span`
  color: ${({ color }) => color};
`;

const TakerName = styled.div`
  padding: 5px 0 1px;
  color: ${({ color }) => color || `#aea9a3`};
  text-transform: capitalize;
`;
