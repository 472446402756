import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { InnerBar, OuterBar } from './styles/StyledComponents';
import { skipTick } from './assets/skipTick';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';

const ProgressBarStock = ({
  currentTicks,
  currentTick,
  tickTime,
  timeRemaining,
  tickCount,
  updateTick
}) => {
  const [disableButton, setDisableButton] = useState(false);
  let disabledTimer;
  const {
    assessmentAttempt: { requestTime }
  } = useAssessmentContext();

  useEffect(() => {
    return () => clearTimeout(disabledTimer);
  }, [disabledTimer]);

  const renderTicks = () => {
    return currentTicks.map((tick, index) => {
      if (index < currentTicks.length - 1) {
        return (
          <TickContainer
            key={tick.id}
            width={100 / (currentTicks.length - 1)}
            ticking={tick._index === currentTick._index}
            ticked={tick._index < currentTick._index}
            lastVisibleTick={currentTicks.length - 2 === index}
          >
            {tick._index <= currentTick._index && (
              <TickTimeElapsed
                tickTime={tickTime}
                ticking={tick._index === currentTick._index}
                timeRemaining={timeRemaining}
              />
            )}
          </TickContainer>
        );
      } else {
        return null;
      }
    });
  };

  const handleClick = () => {
    // Note we need this 2500 millisecond thing in case someone tries to press
    // the button at the exact same time as the next tick timer expires.
    if (
      Date.parse(currentTick.expires_at + '+00:00') -
        Date.parse(currentTick.server_time + '+00:00') -
        requestTime >
      2500
    ) {
      setDisableButton(true);
      updateTick().then(_ => {
        setDisableButton(false);
      });
    }
  };

  return (
    <>
      <ProgressBar>{renderTicks()}</ProgressBar>
      <TickInfo>
        <TickCounter>CURRENT TICK: {currentTick._index + 1}</TickCounter>
        {currentTick._index < tickCount && (
          <NextTick
            onClick={!disableButton ? handleClick : null}
            disableButton={disableButton}
          >
            {skipTick}
            <NextTickText>NEXT TICK</NextTickText>
          </NextTick>
        )}
      </TickInfo>
    </>
  );
};

export default ProgressBarStock;

const ProgressBar = styled(OuterBar)`
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 0;
`;

const TickContainer = styled(InnerBar)`
  background: #d3d3d4;
  border-radius: 0;
  ${props =>
    !props.lastVisibleTick
      ? props.ticked && !props.ticking
        ? 'border-right: white solid 2px;'
        : 'border-right: #c2c3c6 solid 2px;'
      : null}
`;

const TickTimeElapsed = styled.div`
  background: #2c374b;
  height: 100%;
  ${props =>
    props.ticking
      ? `animation: fillbar ${props.timeRemaining}ms linear;`
      : `width: 100%;`}

  @keyframes fillbar {
    from {
      width: ${({ tickTime, timeRemaining }) =>
        `${((tickTime - timeRemaining) / tickTime) * 100}%;`};
    }
    to {
      width: 100%;
    }
  }
`;

const TickInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  top: 10px;
  font-size: 14px;
  background: #efeeed;
`;

const TickCounter = styled.div`
  color: #2c374b;
  margin-right: 20px;
  font-weight: 600;
`;

const NextTick = styled.div`
  display: flex;
  align-items: center;
  border-radius: 16px;
  padding: 1px 6px 2px;
  cursor: pointer;
  &:hover {
    background: #dfddda;
  }
  ${({ disableButton }) => disableButton && `cursor: not-allowed; opacity: 0.5`}
`;

const NextTickText = styled.div`
  font-family: ${props => props.theme.boldFontFamily};
  color: #2c374b;
  margin-left: 5px;
  margin-top: 3px;
`;
