import React from 'react';
import BottomNavBar from 'components/local/navbar/BottomNavBar';
import StockSummary from 'containers/inProgressPage/subcomponents/stock/StockSummary';
import { useHandleRequest } from 'containers/inProgressPage/hooks/useHandleRequest';
import { BUTTON_TEXTS } from 'containers/app/constants';

const PostStock = ({ currentSlot, startingBalance }) => {
  const { handleSubmitSlot } = useHandleRequest();
  const { child_count } = currentSlot;

  return (
    <>
      <StockSummary
        currentSlot={currentSlot}
        startingBalance={startingBalance}
      />
      <BottomNavBar
        handleClick={() => handleSubmitSlot()}
        buttonText={child_count ? 'ANSWER QUESTIONS' : BUTTON_TEXTS.NEXT}
        background="#dfddda"
        flat={true}
      />
    </>
  );
};

export default PostStock;
