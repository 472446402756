import { useState, useEffect } from 'react';

export const useHasWindowFocus = (onWindowFocus, onWindowBlur) => {
  const [focus, setFocus] = useState(document.hasFocus());

  useEffect(() => {
    const onFocus = () => {
      onWindowFocus();
      setFocus(true);
    };
    const onBlur = () => {
      onWindowBlur();
      setFocus(false);
    };

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return focus;
};
