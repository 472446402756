import React from 'react';

export const clockIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="108px"
    height="130px"
    viewBox="0 0 108 130"
    version="1.1"
  >
    <title>1ABB8782-44B4-4BC9-BC69-2267C799FB00@1.5x</title>
    <g
      id="Error-Pages"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Expired-Card"
        transform="translate(-666.000000, -250.000000)"
        fill="#2D2A3E"
        fillRule="nonzero"
      >
        <g
          id="noun_clock_2124312"
          transform="translate(666.000000, 250.000000)"
        >
          <g id="Group" transform="translate(0.000000, 0.000000)">
            <path
              d="M75.0379646,64.1577155 L49.0076987,66.4790666 L28.7458781,41.3112017 C28.1303155,40.545138 27.0062448,40.4257746 26.2372377,41.0404071 C25.4700149,41.6568211 25.3469024,42.7809751 25.9660334,43.5470388 L45.9816289,68.4102598 L30.7049727,90.1112411 C30.1393689,90.9182803 30.333851,92.0299635 31.1385429,92.5964943 C31.452569,92.8156241 31.8094168,92.9207352 32.1644804,92.9207352 C32.7265158,92.9207352 33.2778457,92.6552852 33.6257723,92.1635792 L49.1986123,70.0403721 L75.3555592,67.7065501 C76.3368908,67.6192545 77.0612919,66.7552059 76.9738642,65.7735754 C76.8846522,64.7937265 76.0460598,64.0739829 75.0379646,64.1577155"
              id="Path"
            />
            <path
              d="M48.2137122,25.0047341 C49.2967454,25.6300558 50.1763754,25.1205344 50.1763754,23.8734539 C50.1763754,22.6245919 49.2967454,21.1031538 48.2137122,20.4813951 C47.130679,19.8542918 46.2528333,20.3620317 46.2528333,21.6126752 C46.2546175,22.8615372 47.130679,24.3794123 48.2137122,25.0047341"
              id="Path"
            />
            <path
              d="M8.66783413,43.2780257 C7.58480093,42.6527039 6.70517099,43.1622254 6.70517099,44.4093058 C6.70517099,45.6581678 7.58480093,47.1778244 8.66783413,47.8013646 C9.75086734,48.4249049 10.628713,47.920728 10.628713,46.668303 C10.628713,45.4212225 9.75086734,43.9033475 8.66783413,43.2780257"
              id="Path"
            />
            <path
              d="M48.2137122,111.669699 C47.130679,111.044377 46.2528333,111.553899 46.2528333,112.802761 C46.2528333,114.051623 47.130679,115.571279 48.2137122,116.193038 C49.2967454,116.820141 50.1763754,116.312401 50.1763754,115.061758 C50.1763754,113.812896 49.2967454,112.295021 48.2137122,111.669699"
              id="Path"
            />
            <path
              d="M87.7613745,93.3964073 C88.8426235,94.0235106 89.7222534,93.5139892 89.7222534,92.2651272 C89.7222534,91.0162652 88.8444077,89.4966086 87.7613745,88.8730684 C86.6783413,88.2477466 85.7969271,88.7554865 85.7969271,90.0043485 C85.7969271,91.2532105 86.6765571,92.7710856 87.7613745,93.3964073"
              id="Path"
            />
            <path
              d="M83.2793656,67.3858723 C84.0608624,67.3163921 84.3284983,66.3169462 83.8770857,65.1553798 C83.4256732,63.9938135 82.4282835,63.1101679 81.6450025,63.1796481 C80.8617215,63.2491283 80.5940856,64.2485742 81.0454981,65.4101405 C81.4969106,66.5699254 82.4996531,67.4553525 83.2793656,67.3858723"
              id="Path"
            />
            <path
              d="M69.4033372,42.0077852 C69.8547497,41.3664295 69.5888981,40.0587766 68.805617,39.0878354 C68.022336,38.1168942 67.0231621,37.8496627 66.5717495,38.4910183 C66.120337,39.1305924 66.3879729,40.4382454 67.1694697,41.4109681 C67.9527507,42.3819093 68.9519247,42.6491408 69.4033372,42.0077852"
              id="Path"
            />
            <path
              d="M29.259739,19.556774 C30.0412358,19.4872938 30.3088716,18.4878479 29.8574591,17.3262816 C29.4060466,16.1647152 28.4068726,15.2810697 27.6253758,15.3487683 C26.8420948,15.42003 26.5744589,16.4194759 27.0258715,17.5810423 C27.477284,18.7408271 28.4782422,19.6262542 29.259739,19.556774"
              id="Path"
            />
            <path
              d="M12.5503387,24.0373559 C12.0989262,24.6787116 12.366562,25.9863645 13.1480588,26.9573057 C13.9313398,27.9282469 14.9305138,28.1954785 15.3819263,27.5559043 C15.8333388,26.9145487 15.565703,25.6068958 14.7842062,24.634173 C14.0009252,23.6632318 13.0017512,23.3977818 12.5503387,24.0373559"
              id="Path"
            />
            <path
              d="M13.1480588,69.2885607 C12.366562,69.3580409 12.0989262,70.3574868 12.5503387,71.5190532 C13.0017512,72.6806196 14.0009252,73.5660467 14.7824219,73.4965665 C15.5639187,73.4253047 15.8333388,72.4258588 15.3819263,71.266074 C14.9305138,70.1045077 13.9295556,69.2190805 13.1480588,69.2885607"
              id="Path"
            />
            <path
              d="M27.0258715,94.6666478 C26.5744589,95.3080035 26.8420948,96.6156564 27.6235916,97.5865976 C28.4068726,98.5575388 29.4060466,98.8247704 29.8574591,98.1834147 C30.3088716,97.5438406 30.0412358,96.2361877 29.259739,95.2634649 C28.476458,94.2925237 27.477284,94.0235106 27.0258715,94.6666478"
              id="Path"
            />
            <path
              d="M67.1712539,117.117659 C66.3897571,117.187139 66.120337,118.186585 66.5717495,119.348151 C67.0249463,120.509718 68.022336,121.395145 68.805617,121.325665 C69.5871138,121.254403 69.856534,120.256739 69.4051214,119.095172 C68.9537089,117.933606 67.9509665,117.048179 67.1712539,117.117659"
              id="Path"
            />
            <path
              d="M83.87887,112.637077 C84.3302825,111.995721 84.0626466,110.688069 83.2811498,109.717127 C82.4978688,108.746186 81.4986949,108.478955 81.0454981,109.12031 C80.5940856,109.759884 80.8617215,111.067537 81.6450025,112.04026 C82.4282835,113.011201 83.4274575,113.278433 83.87887,112.637077"
              id="Path"
            />
            <path
              d="M60.6801916,6.59248202 C47.6427557,-0.922068447 35.3136626,-2.05334857 25.9553279,3.40886379 C25.948191,3.41420842 25.9374855,3.41420842 25.9303486,3.41777151 L14.6985627,9.90793447 C14.6307616,9.94534688 14.559392,9.97741466 14.4915909,10.0183902 C14.4202214,10.0575841 14.3542045,10.1074673 14.282835,10.1484428 L13.9723773,10.3283787 C13.9438295,10.3444126 13.9295556,10.3711358 13.902792,10.3907328 C4.93698992,15.9046099 0,26.9341457 0,41.5730886 C0,71.656232 21.2288782,108.37028 47.3215926,123.413634 C54.9188832,127.792668 62.27887,130 68.9537089,130 C73.5927309,130 77.8945316,128.927511 81.7128036,126.777188 C81.7377829,126.764717 81.7663307,126.759372 81.7913101,126.743338 L93.7492813,119.836294 C93.768908,119.825605 93.7796134,119.806008 93.7974558,119.795319 C102.959524,114.350922 108,103.278629 108,88.4312456 C108.001784,58.3481021 86.772906,21.6376168 60.6801916,6.59248202 M49.1058318,120.326219 C23.9962333,105.851178 3.56847844,70.5196073 3.56847844,41.571307 C3.56847844,28.0351395 8.02015529,17.980108 16.1009747,13.2126977 L16.386453,13.0487956 C19.6783744,11.1728303 23.4181398,10.2410831 27.4844209,10.2410831 C33.5276392,10.2410831 40.2845531,12.2898581 47.3215926,16.3482142 C72.4311911,30.8232551 92.858946,66.1530442 92.858946,95.103126 C92.858946,108.73906 88.346605,118.847538 80.1533785,123.572191 C71.960152,128.296844 60.9353378,127.144186 49.1058318,120.326219"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
