import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
  CheckboxRowWrapper,
  CheckboxInput,
  CheckboxLabel
} from './styles/StyledComponents';
import useParseHtml from '../hooks/useParseHtml';

const CheckboxRow = ({ disabled, formikProps, name, option, selectTotal }) => {
  const [checkboxDisabled, setCheckboxDisabled] = useState(false);
  const { values, setFieldValue, setValues } = formikProps;

  useEffect(() => {
    if (
      values[name]?.length === selectTotal &&
      !values[name]?.includes(option)
    ) {
      setCheckboxDisabled(true);
    } else {
      setCheckboxDisabled(false);
    }
  }, [values, name, option, selectTotal]);

  /* this function checks to see if selected user option already exists in list
   if it's not there, it is added else it's already there, it is removed */
  const manageList = () => {
    if (!values[name]?.includes(option)) {
      setFieldValue(name, [...values[name], option]);
    } else {
      let newValues = values[name].filter(val => val !== option);
      setValues({ ...values, [name]: newValues });
    }
  };

  const parsedHtml = useParseHtml(option);

  return (
    <CheckboxRowWrapper
      disabled={disabled}
      option={option}
      value={values[name]}
    >
      <CheckboxInput
        type="checkbox"
        id={option}
        value={option}
        disabled={checkboxDisabled || disabled}
        onChange={manageList}
        checked={values[name]?.includes(option)}
      />
      <CheckboxLabel
        checkboxDisabled={checkboxDisabled}
        htmlFor={option}
        disabled={disabled}
      >
        {parsedHtml}
      </CheckboxLabel>
    </CheckboxRowWrapper>
  );
};

CheckboxRow.propTypes = {
  disabled: PropTypes.bool.isRequired,
  formikProps: PropTypes.object.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  option: PropTypes.string.isRequired,
  selectTotal: PropTypes.number.isRequired
};

export default CheckboxRow;
