import React from 'react';

export const user = (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-391.000000, -566.000000)"
        fill="#898989"
        fillRule="nonzero"
      >
        <g transform="translate(391.000000, 566.000000)">
          <path d="M8,0 C3.588875,0 0,3.588875 0,8 C0,12.411125 3.588875,16 8,16 C12.411125,16 16,12.411125 16,8 C16,3.588875 12.411125,0 8,0 Z M8,2.33334375 C10.1135937,2.33334375 11.8333438,4.0530625 11.8333438,6.1666875 C11.8333438,8.2803125 10.1135937,10 8,10 C5.88640625,10 4.16665625,8.28028125 4.16665625,6.16665625 C4.16665625,4.05303125 5.88640625,2.33334375 8,2.33334375 Z M8,14.6666563 C5.82165625,14.6666563 3.89,13.6118125 2.67271875,11.9911875 C4.00634375,11.3495 5.910875,10.6666563 8,10.6666563 C10.0892812,10.6666563 11.9939688,11.3495938 13.3273125,11.991125 C12.1100313,13.6117812 10.178375,14.6666563 8,14.6666563 Z"></path>
        </g>
      </g>
    </g>
  </svg>
);
