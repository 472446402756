import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ComingSoonPage from 'containers/errorPage/ComingSoonPage';
import ErrorPage from 'containers/errorPage/ErrorPage';
import InProgressPage from 'containers/inProgressPage/InProgressPage';
import LandingPage from 'containers/landingPage/LandingPage';
import PageLoading from 'components/local/loading/PageLoading';
import PausedPage from 'containers/pausedPage/PausedPage';
import PendingPage from 'containers/pendingPage/PendingPage';
import SubmittedPage from 'containers/submittedPage/SubmittedPage';
import {
  getAssessmentAttempt,
  getPublicAssessment
} from 'services/requests/assessmentRequests';
import { parseSlugAndUuid } from 'containers/app/helpers/parseSlugAndUuid';
import { getUTCDate } from 'containers/app/helpers/getUTCDate';
import useGoogleAnalytics from 'containers/app/helpers/googleAnalytics';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';
import { useThemeContext } from 'services/context/themeContext/useThemeContext';
import { SET_PAGE } from 'services/context/assessmentContext/assessmentContext';
import { AssessmentPoliceProvider } from 'services/context/assesmentPolice/assesmentPoliceContext';

const App = () => {
  const { urlSlug, urlUuid } = parseSlugAndUuid();
  const { state, dispatch } = useAssessmentContext();
  const { themeDispatch } = useThemeContext();
  const { assessmentAttempt, assessmentIsLoading, assessmentError } = state;

  useGoogleAnalytics();

  useEffect(() => {
    let sentryId = null;
    if (assessmentAttempt?.taker?.id) {
      sentryId = assessmentAttempt.taker.id;
    } else if (assessmentAttempt?.uuid) {
      sentryId = assessmentAttempt.uuid;
    }

    Sentry.setUser({
      id: sentryId
    });
  }, [assessmentAttempt]);

  useEffect(() => {
    if (urlUuid !== null && urlSlug !== null) {
      getAssessmentAttempt(urlSlug, urlUuid, dispatch, themeDispatch);
    } else if (urlSlug !== null) {
      getPublicAssessment(urlSlug, dispatch, themeDispatch);
    } else {
      dispatch({
        type: SET_PAGE,
        payload: {
          assessmentIsLoading: false
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (assessmentIsLoading) return <PageLoading />;

  const getValidRoute = () => {
    const {
      uuid,
      attempt_state,
      assessment: { slug }
    } = assessmentAttempt;

    const routes = {
      PUBLIC: <Route path={`/${slug}`} component={LandingPage} />,
      INITIALIZED: <Route path={`/${slug}/${uuid}`} component={LandingPage} />,
      PENDING: (
        <Route
          exact
          path={`/${slug}/${uuid}/pending`}
          component={PendingPage}
        />
      ),
      PAUSED: (
        <Route exact path={`/${slug}/${uuid}/paused`} component={PausedPage} />
      ),
      IN_PROGRESS: (
        <Route
          exact
          path={`/${slug}/${uuid}/in-progress`}
          component={InProgressPage}
        />
      ),
      SUBMITTED: (
        <Route
          exact
          path={`/${slug}/${uuid}/submitted`}
          component={SubmittedPage}
        />
      ),
      COMPLETE: (
        <Route
          exact
          path={`/${slug}/${uuid}/submitted`}
          component={SubmittedPage}
          assessmentAttempt={assessmentAttempt}
        />
      )
    };

    return routes[attempt_state];
  };

  let validRoute = null;
  if (assessmentAttempt) {
    validRoute = getValidRoute();
    const openDate = assessmentAttempt.assessment.open_date;
    const closeDate = assessmentAttempt.assessment.close_date;
    const todayUTC = getUTCDate();

    const beforeOpenDate = openDate && new Date(openDate) > todayUTC;
    const afterOpenDate = closeDate && new Date(closeDate) < todayUTC;
    if (beforeOpenDate) {
      return <ComingSoonPage openDate={new Date(openDate)} />;
    } else if (afterOpenDate) {
      return (
        <ErrorPage
          error={{ message: 'This assessment has expired.', status: 410 }}
        />
      );
    }
  }

  return (
    <AssessmentPoliceProvider>
      <Switch>
        {validRoute}
        {validRoute && <Redirect to={validRoute.props.path} />}
        <Route render={() => <ErrorPage error={assessmentError} />} />
      </Switch>
    </AssessmentPoliceProvider>
  );
};

export default App;
