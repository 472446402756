import { takerFieldTypes } from 'containers/app/helpers/takerFieldTypes';

export const formatTakerResponse = (values, fieldObj) => {
  let taker_field_responses = [];
  for (let val in values) {
    if (val !== 'email') {
      switch (fieldObj[val].field_type) {
        case `${takerFieldTypes.text}_response`:
          fieldObj[val].result = values[val];
          break;
        case `${takerFieldTypes.select}_response`:
          // TODO: Add support for multi select.
          fieldObj[val].options = [{ id: values[val].id }];
          break;
        default:
          console.error('Unsupported field type!', fieldObj[val].field_type);
          break;
      }

      taker_field_responses.push(fieldObj[val]);
    }
  }
  return {
    email: values.email.toLowerCase(),
    taker_field_responses
  };
};
