import styled from 'styled-components';

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ marginBottom }) => marginBottom || '15px'};
  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
    input,
    label {
      cursor: not-allowed;
    }
  }
`;

/**
 * Generates the CSS styles for indenting text in a question body.
 * We are using Quill for the question creation, and we need to apply the same
 * indent styles in the question body.
 * The indent style goes from ql-indent-1 to ql-indent-9.
 * @returns {string} The CSS styles for indenting text.
 */
export const generateIndentStyles = () => {
  let indentStyles = '';
  for (let i = 1; i <= 9; i++) {
    indentStyles += `
      .ql-indent-${i} {
        margin-left: ${i * 40}px;
      }
    `;
  }
  return indentStyles;
};

export const InputLabel = styled.div`
  color: ${props => props.theme.black};
  display: inline-block;
  font-family: ${props => props.theme.fontFamily};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.44px;
  line-height: 28px;
  margin-bottom: 16px;
  &.capitalized {
    text-transform: capitalize;
  }
  ${generateIndentStyles()}
`;

export const InputDescriptor = styled.span`
  font-family: ${props => props.theme.fontFamily};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.67px;
  line-height: 16px;
  margin-bottom: 8px;
  color: ${props => props.theme.color};
`;

export const Input = styled.input`
  font-family: ${props => props.theme.fontFamily};
  padding: 20px 16px 16px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.78px;
  line-height: 16px;
  background: #f5f5f5;
  border: 2px solid #f5f5f5;
  border-bottom: 0;
  border-top: 0;
  outline: none;
  height: 48px;
  width: 100%;
  appearance: none;
  margin: 0;
  &::placeholder {
    color: #bfbfbf;
    text-transform: capitalize;
  }
  &.error {
    border: 1px solid #dc212b;
    transition: all 150ms ease-out;
  }
  &.small {
    height: 32px;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
`;

export const TextAreaInput = styled.textarea`
  font-family: ${props => props.theme.fontFamily};
  padding: 16px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.78px;
  line-height: 14px;
  background: #f5f5f5;
  border: none;
  outline: none;
  resize: vertical;
  min-height: 100px;
  appearance: none;
  &::placeholder {
    color: #bfbfbf;
    text-transform: capitalize;
  }
  &.error {
    border: 1px solid #dc212b;
    transition: all 150ms ease-out;
  }
`;

export const InputUnderlineOuter = styled.div`
  background: #d3d3d3;
  height: 2px;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 2px;
  margin-bottom: 5px;
  &.error {
    visibility: hidden;
  }
`;

export const InputUnderlineInner = styled.div`
  background: ${props => props.theme.color};
  height: 100%;
  border-radius: 2px;
  transition: all 150ms ease-out;
  &.focus {
    width: 100%;
  }
  &.unfocus {
    width: 0%;
  }
`;

export const ErrorSpan = styled.span`
  font-family: ${props => props.theme.fontFamily};
  color: #d93b0a;
  font-style: oblique;
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  letter-spacing: 0.67px;
  span {
    visibility: hidden;
  }
`;

export const OptionalLabel = styled.span`
  color: #888791;
  display: inline-block;
  margin-left: 8px;
`;

export const InputHolder = styled.div`
  position: relative;
  &.hidden {
    visibility: hidden;
  }
`;

// base for radio/multiselect rows
export const RowWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  border: 1px solid;
  border-radius: 4px;
  cursor: pointer;
  margin: 6px 6px 6px 0;
  &.disabled {
    cursor: not-allowed;
  }
`;

// base for radio/multiselect inputs
export const SelectInput = styled.input`
  appearance: none;
  position: absolute;
  height: 16px;
  width: 16px;
  min-width: 16px;
  min-height: 16px;
  top: 17px;
  left: 16px;
  outline: none;
  transition: 150ms all ease-in-out;
  border: 1px solid #979797;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
  &:checked {
    background: ${props => props.theme.color};
    border-color: ${props => props.theme.color};
  }
`;

// option for dropdowns
export const Option = styled.li`
  appearance: none;
  list-style: none;
  color: ${props => props.theme.black};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.89px;
  line-height: 16px;
  padding: 16px;
  background: ${props => props.theme.white};
  word-wrap: break-word;
  cursor: pointer;
  transition: all ease-in 50ms;
  &:hover {
    background: #f5f5f5;
  }
`;

// option container for dropdowns
export const DropdownOptions = styled.ul`
  background: ${props => props.theme.white};
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  margin: 3px 0 0;
  max-height: 350px;
  overflow: auto;
  padding: 0;
  position: absolute;
  transition: all ease-in-out 200ms;
  width: 100%;
  z-index: 5;
`;

export const InputTitle = styled.div`
  display: inline-block;
  vertical-align: middle;
`;
