import React from 'react';
import styled from 'styled-components';
import CandidateDetails from 'containers/pendingPage/subComponents/CandidateDetails';
import MobileWarning from 'components/local/modals/MobileWarning';
import ProgressBarChecks from 'components/global/progressBar/ProgressBarChecks';
import { PageWrapper } from 'components/local/styles/StyledComponents';

const PendingPage = () => {
  return (
    <PageWrapper>
      <MobileWarning />
      <CandidateWrapper>
        {/* Hardcoded for taker data only, but should eventually include disclaimer, message, etc */}
        <ProgressBarChecks currentSlot={0} slotLabels={['Candidate Details']} />
        <CandidateDetails />
      </CandidateWrapper>
    </PageWrapper>
  );
};

const CandidateWrapper = styled.div`
  padding: 0 16px;
  max-width: 960px;
  margin: auto;
`;

export default PendingPage;
