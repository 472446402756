// Parses url on initial render to grab slug and uuid
export const parseSlugAndUuid = () => {
  const pathname = window.location.pathname;

  const splitPathName = pathname.split('/').filter(path => {
    return path !== '';
  });

  let urlSlug = splitPathName[0] ? splitPathName[0] : null;
  let urlUuid = splitPathName[1] ? splitPathName[1] : null;

  return { urlSlug, urlUuid };
};
