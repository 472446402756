import { axios } from 'services/requests/helpers/AxiosClient';
import { configureEndpoint } from 'services/requests/helpers/configureEndpoint';

const URL_PROCTORING = 'proctoring';
const URL_FRONTEND_EVENT = 'frontend-event';
const EVENT_URL = configureEndpoint(URL_PROCTORING, URL_FRONTEND_EVENT);

export async function postFrontendEvent(
  assessment_attempt_uuid,
  event_name,
  question_id,
  additional_info
) {
  // Sends a frontend event to the event log on the backend
  const requestUrl = EVENT_URL;
  const event = {
    assessment_attempt_uuid,
    event_name,
    question_id,
    additional_info
  };
  try {
    await axios.post(requestUrl, event);
  } catch (err) {
    console.error(err);
    console.warn('There was a problem sending the detection the event.');
    console.warn('data', event);
  }
}
