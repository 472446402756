import { useState, useEffect } from 'react';

//hook to determine whether or not to render errors;
export const useRenderErrors = (
  errors,
  submitCount,
  name,
  showInitialErrors = false
) => {
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    if (errors[name] && (submitCount || showInitialErrors)) {
      setShowErrors(true);
    } else {
      setShowErrors(false);
    }
  }, [submitCount, errors, name, showInitialErrors]);

  return showErrors;
};
