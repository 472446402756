import { axios } from 'services/requests/helpers/AxiosClient';
import { configureEndpoint } from 'services/requests/helpers/configureEndpoint';
import { moveToNextSection } from 'services/requests/helpers/moveToNextSection';
import { setErrorPage } from 'services/requests/helpers/setErrorPage';
import { updateAssessmentData } from 'services/requests/helpers/updateAssessmentData';
import { setError } from 'services/requests/helpers/setError';

const PROCTORING = 'proctoring';
const GENERAL_ERROR = 'Your request could not be processed';

// Fetch section
export const getSection = async (
  assessment,
  slug,
  uuid,
  sectionId,
  sectionIndex,
  dispatch
) => {
  const requestUrl = configureEndpoint(PROCTORING, slug, uuid, sectionId);
  try {
    const response = await axios.get(requestUrl);

    assessment.requestTime = response.headers['request-duration'];
    assessment.section_attempts[sectionIndex].time_remaining =
      response.data.data.time_remaining;
    assessment.section_attempts[sectionIndex].time_allowed_seconds =
      response.data.data.time_allowed_seconds;

    updateAssessmentData(assessment, dispatch);
  } catch ({ response }) {
    response?.status === 410 && setErrorPage(response, dispatch);
    setError(GENERAL_ERROR, dispatch);
  }
};

// Set section to in progress
export const updateSectionToInProgress = async (
  assessment,
  slug,
  uuid,
  sectionId,
  sectionIndex,
  dispatch
) => {
  const requestUrl = configureEndpoint(PROCTORING, slug, uuid, sectionId);

  try {
    const response = await axios.put(requestUrl);

    assessment.requestTime = response.headers['request-duration'];
    assessment.section_attempts[sectionIndex] = response.data.data;

    updateAssessmentData(assessment, dispatch);
  } catch ({ response }) {
    response?.status === 410 && setErrorPage(response, dispatch);
    setError(GENERAL_ERROR, dispatch);
  }
};

// Set section to submitted
export const updateSectionToSubmitted = async (
  assessment,
  slug,
  uuid,
  sectionId,
  sectionIndex,
  dispatch
) => {
  const requestUrl = configureEndpoint(PROCTORING, slug, uuid, sectionId);

  try {
    const response = await axios.post(requestUrl);

    assessment.requestTime = response.headers['request-duration'];
    assessment.section_attempts[sectionIndex] = response.data.data;

    moveToNextSection(sectionIndex, assessment, dispatch);
  } catch ({ response }) {
    response?.status === 410 && setErrorPage(response, dispatch);
    setError(GENERAL_ERROR, dispatch);
  }
};

// Fetch StockSection metadata
export const getStockSectionMeta = async (
  assessment,
  slug,
  uuid,
  sectionId,
  sectionIndex,
  dispatch
) => {
  const requestUrl = configureEndpoint(
    PROCTORING,
    'stock-attempt',
    'meta',
    slug,
    uuid,
    sectionId
  );
  try {
    const response = await axios.get(requestUrl);

    assessment.requestTime = response.headers['request-duration'];
    assessment.section_attempts[sectionIndex].meta = response.data.data;

    updateAssessmentData(assessment, dispatch);
  } catch ({ response }) {
    response?.status === 410 && setErrorPage(response, dispatch);
    setError(GENERAL_ERROR, dispatch);
  }
};
