import PropTypes from 'prop-types';
import React from 'react';
import DisabledTimer from 'components/local/timer/DisabledTimer';
import Timer from 'components/local/timer/Timer';
import { attemptStateTypes } from 'containers/app/helpers/attemptStateTypes';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';
import { useHandleRequest } from 'containers/inProgressPage/hooks/useHandleRequest';
import { useThemeContext } from 'services/context/themeContext/useThemeContext';
import { AssessLogo } from 'containers/inProgressPage/styles/StyledComponents';
import { TopNavWrapper } from 'components/local/navbar/styles/StyledComponents';

const TopNavBar = ({
  background,
  formikProps,
  setNewSlot,
  setSubmitSection
}) => {
  const {
    handleGetSection,
    handleGetSlot,
    handleSubmitSlot
  } = useHandleRequest();
  const {
    currentSection,
    currentSlot,
    state: {
      currentSlotId,
      assessmentAttempt: {
        assessment: {
          tenant: { name: tenantName }
        }
      }
    }
  } = useAssessmentContext();
  const { themeState } = useThemeContext();
  const {
    section: { is_timed }
  } = currentSection;
  const sectionPending =
    currentSection.attempt_state === attemptStateTypes.PENDING;

  const handleSlotTimeout = () => {
    const { submitForm, errors } = formikProps;

    if (errors[currentSlotId]) {
      handleSubmitSlot(null);
    } else {
      submitForm();
    }
  };

  const handleSectionTimeout = () => {
    setNewSlot && setNewSlot(null);
    setSubmitSection && setSubmitSection(true);
  };

  const renderPendingTimer = () => {
    return is_timed ? (
      <DisabledTimer time={currentSection.time_allowed_seconds} />
    ) : null;
  };

  const renderTimer = () => {
    if (is_timed) {
      return (
        <Timer
          timedPortion={currentSection}
          handleTimeout={handleSectionTimeout}
          handleReconciliation={handleGetSection}
        />
      );
    } else if (currentSlot.time_allowed_seconds && formikProps) {
      return (
        <Timer
          timedPortion={currentSlot}
          handleTimeout={handleSlotTimeout}
          handleReconciliation={handleGetSlot}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <TopNavWrapper background={background}>
      {themeState.showNavLogo && (
        <AssessLogo src={themeState.logoUrl} alt={tenantName} />
      )}
      {sectionPending ? renderPendingTimer() : renderTimer()}
    </TopNavWrapper>
  );
};

TopNavBar.propTypes = {
  background: PropTypes.string,
  formikProps: PropTypes.object,
  setNewSlot: PropTypes.func,
  setSubmitSection: PropTypes.func
};

TopNavBar.defaultProps = {
  background: null,
  formikProps: null,
  setNewSlot: null,
  setSubmitSection: null
};

export default TopNavBar;
