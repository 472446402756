import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { PlayerTypes } from 'containers/app/helpers/playerTypes';
import { FlexContainer } from 'components/local/styles/StyledComponents';
import {
  Header,
  WindowWrapper,
  HoverContainer
} from 'containers/inProgressPage/subcomponents/stock/StyledComponents';
import { user } from './assets/user';
import { upArrowPortfolio } from './assets/upArrowPortfolio';
import { downArrowPortfolio } from './assets/downArrowPortfolio';

const PortfolioTable = ({
  portfolios,
  bots,
  startingBalance,
  balances,
  assets
}) => {
  const [showAll, setShowAll] = useState(false);

  const renderTableHeaders = () => {
    return bots ? (
      <FlexContainer width="100%">
        <Header className="portfolio-table">
          <IconWrapper>{user}</IconWrapper>Your Portfolio
        </Header>
        {bots.map(bot => (
          <Header className="portfolio-table" key={bot.id}>
            <IconWrapper>{user}</IconWrapper>
            {bot.bot.player_name}
          </Header>
        ))}
      </FlexContainer>
    ) : (
      <Header>Portfolio Value</Header>
    );
  };

  const renderColumnHeaders = () => {
    return (
      <FlexContainer width="100%" style={{ padding: '0 8px' }}>
        <HeaderRow>{columnHeaders()}</HeaderRow>
        {bots &&
          bots.map(bot => (
            <HeaderRow key={bot.id}>{columnHeaders('bot')}</HeaderRow>
          ))}
      </FlexContainer>
    );
  };

  const columnHeaders = bot => (
    <>
      {' '}
      <Col className={bot && 'hide'} color="#4e4d52" weight="bold" width="25px">
        Tick
      </Col>
      <FlexContainer width="90px" justifyContent="space-evenly">
        {assets.map(asset => (
          <HoverContainer key={asset.id}>
            <Col width="25px">
              {asset.question_stock_asset.ticker_name.split(' ').pop()}
            </Col>
            <div className={'description'}>
              <div className="pop-up portfolio-stock-held">
                {`${asset.question_stock_asset.ticker_name} Held`}
              </div>
            </div>
          </HoverContainer>
        ))}
      </FlexContainer>
      <Col width="50px" className="money">
        Cash
      </Col>
      <Col width="130px" className="money">
        Portfolio Value
      </Col>
    </>
  );

  const renderColumnRows = () => {
    return (
      <>
        <FlexContainer width="100%" style={{ padding: '0 8px 10px' }}>
          <FlexContainer width="100%" direction="column">
            {renderRows(
              portfolios.filter(
                portfolio => portfolio.player_type === PlayerTypes.TAKER
              )
            )}
          </FlexContainer>
          {bots &&
            bots.map(bot => (
              <FlexContainer key={bot.id} width="100%" direction="column">
                {renderRows(
                  portfolios.filter(portfolio => {
                    return (
                      portfolio.player_type === PlayerTypes.BOT &&
                      portfolio.answer_bot_id === bot.id
                    );
                  }),
                  true
                )}
              </FlexContainer>
            ))}
        </FlexContainer>
      </>
    );
  };

  const renderRows = (portfolios, botRows = false) => {
    const visibleRows = showAll ? portfolios : portfolios.slice(0, 10);
    return visibleRows.map((portfolio, index) => {
      const { id, cash_value, asset_value } = portfolio;
      const arrow =
        asset_value + cash_value > startingBalance
          ? upArrowPortfolio
          : downArrowPortfolio;

      return (
        <Row key={id}>
          <Col className={botRows && 'hide'} background="#d3d3d4" width="25px">
            {index + 1}
          </Col>
          <FlexContainer
            width="90px"
            alignItems="stretch"
            justifyContent="space-evenly"
          >
            {assets.map(asset => (
              <Col key={asset.id} width="25px">
                {findStockHeld(asset, portfolio)}
              </Col>
            ))}
          </FlexContainer>
          <Col width="50px" className={`money ${cash_value < 0 && 'negative'}`}>
            {cash_value < 0 && '('}${Math.abs(cash_value)}
            {cash_value < 0 && ')'}
          </Col>
          <Col width="130px" weight="bold" className="money">
            ${asset_value + cash_value}
            {asset_value + cash_value !== startingBalance && (
              <Percentage
                color={
                  asset_value + cash_value > startingBalance
                    ? '#6ebc96'
                    : '#f79181'
                }
              >
                {' '}
                (<ArrowWrapper>{arrow}</ArrowWrapper>
                {asset_value + cash_value > startingBalance ? (
                  '+'
                ) : (
                  <>&#8211;</>
                )}
                {Math.round(
                  (Math.abs(asset_value + cash_value - startingBalance) /
                    startingBalance) *
                    100
                )}
                %)
              </Percentage>
            )}
          </Col>
        </Row>
      );
    });
  };

  const findStockHeld = (asset, portfolio) => {
    const {
      player_type,
      answer_stock_tick_index_id: portfolioAnswerStockTickId,
      answer_bot_id: portfolioBotId
    } = portfolio;
    // Find the balance that corresponds to the portfolio and return the quantity held
    let rowBalance = balances.find(balance => {
      const {
        balance: { balance_type }
      } = balance;

      // if the player has gone bankrupt we can just return 0 since everything resets
      if (balance_type !== 'answer_balance_bankrupt') {
        const {
          answer_bot_id,
          balance: {
            answer_stock_tick: {
              answer_stock_asset_id,
              answer_stock_tick_index_id: balanceAnswerStockTickId
            }
          }
        } = balance;
        if (player_type === PlayerTypes.TAKER) {
          if (answer_stock_asset_id === asset.id) {
            return balanceAnswerStockTickId === portfolioAnswerStockTickId;
          }
        } else {
          if (answer_stock_asset_id === asset.id) {
            if (answer_bot_id === portfolioBotId) {
              return balanceAnswerStockTickId === portfolioAnswerStockTickId;
            }
          }
        }
      }
      return 0;
    });

    return rowBalance.balance ? rowBalance.balance.quantity : rowBalance;
  };

  return (
    <FlexContainer>
      <PortfolioTableWrapper width="100%">
        {renderTableHeaders()}
        {renderColumnHeaders()}
        {renderColumnRows()}
        {portfolios.length > 10 && (
          <VisibleRowToggle
            weight="bold"
            color="#8b8782"
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? 'show less' : 'show all'}
          </VisibleRowToggle>
        )}
      </PortfolioTableWrapper>
    </FlexContainer>
  );
};

PortfolioTable.propTypes = {
  portfolios: PropTypes.arrayOf(PropTypes.object).isRequired,
  bots: PropTypes.array,
  startingBalance: PropTypes.number,
  balances: PropTypes.arrayOf(PropTypes.object).isRequired,
  assets: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default PortfolioTable;

const PortfolioTableWrapper = styled(WindowWrapper)`
  margin: 16px 0 0;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 23px;
  align-items: stretch;
  color: #2c374b;
  background: ${({ theme }) => theme.white};
  margin: 2px 0;
  font-size: 14px;
`;

const HeaderRow = styled(Row)`
  background: transparent;
  color: #aea9a3;
  font-size: 12px;
  border: none;
`;

const Col = styled.div`
  padding-top: 3px;
  background: ${({ background }) => background};
  font-weight: ${({ weight }) => weight};
  width: ${({ width }) => width};
  color: ${({ color }) => color};
  text-align: center;
  height: 100%;
  white-space: nowrap;
  &.money {
    text-align: left;
  }
  &.negative {
    color: #f79181;
  }
  &.hide {
    visibility: hidden;
  }
`;

const VisibleRowToggle = styled(Col)`
  text-transform: uppercase;
  padding: 0 0 10px;
  height: auto;
  font-size: 10px;
  cursor: pointer;
`;

const Percentage = styled.span`
  color: ${props => props.color};
  font-weight: normal;
`;

const IconWrapper = styled.span`
  margin: 5px 5px 0;
`;

const ArrowWrapper = styled.span`
  padding: 0 1px 0 3px;
`;
