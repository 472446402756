import PropTypes from 'prop-types';
import React from 'react';
import { attemptStateTypes } from 'containers/app/helpers/attemptStateTypes';
import {
  BlankSlot,
  CurrentSlot,
  SubmittedSlot
} from './styles/StyledComponents';

const ProgressSlot = ({
  currentSection,
  currentSlotIndex,
  attemptState,
  index,
  formikProps,
  setSlot
}) => {
  const handleClick = index => {
    formikProps.submitForm();
    setSlot(index);
  };

  const id = `pill-${index}`;

  const renderProgressSlot = () => {
    const sectionStatus = currentSection.attempt_state;
    if (
      currentSlotIndex === index &&
      sectionStatus === attemptStateTypes.IN_PROGRESS
    ) {
      return <CurrentSlot id={id} />;
    } else if (
      attemptState === attemptStateTypes.PENDING ||
      attemptState === attemptStateTypes.IN_PROGRESS
    ) {
      return <BlankSlot id={id} onClick={() => handleClick(index)}></BlankSlot>;
    } else if (
      attemptState === attemptStateTypes.SUBMITTED ||
      attemptState === attemptStateTypes.COMPLETE
    ) {
      return (
        <SubmittedSlot
          id={id}
          onClick={() => handleClick(index)}
        ></SubmittedSlot>
      );
    }
  };

  return renderProgressSlot();
};

ProgressSlot.propTypes = {
  currentSection: PropTypes.object.isRequired,
  currentSlotIndex: PropTypes.number.isRequired,
  attemptState: PropTypes.oneOf([
    'PENDING',
    'IN_PROGRESS',
    'SUBMITTED',
    'COMPLETE'
  ]),
  index: PropTypes.number.isRequired,
  formikProps: PropTypes.object,
  setSlot: PropTypes.func
};

export default ProgressSlot;
