import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  color: ${({ theme }) => theme.color};
`;

export const celebration = (
  <Svg width="82px" height="82px" viewBox="0 0 82 82" version="1.1">
    <g id="Page-1" stroke="none" fill="none" strokeWidth="1" fillRule="evenodd">
      <g
        id="ending-02"
        transform="translate(-679.000000, -326.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <g id="Group" transform="translate(537.000000, 326.000000)">
          <g
            id="noun_Celebration_1121193"
            transform="translate(142.000000, 0.000000)"
          >
            <g id="Group">
              <path
                d="M39.1,15.6 C38,14.5 36.5,13.8 34.9,13.8 C33.3,13.8 31.8,14.4 
                30.7,15.6 C29.6,16.7 28.9,18.2 28.9,19.8 C28.9,21.4 29.5,22.9 
                30.7,24 C31.8,25.1 33.3,25.8 34.9,25.8 C36.5,25.8 38,25.2 
                39.1,24 C40.2,22.9 40.9,21.4 40.9,19.8 C40.9,18.2 40.2,16.7 
                39.1,15.6 Z"
                id="Path"
              ></path>
              <path
                d="M3.9,80.7 L43.6,65.1 L46.4,64 L52,61.8 C53,61.4 53.5,60.3
                53.2,59.3 C48.2,45 37.1,33.9 22.8,28.9 C21.8,28.5 20.7,29.1
                20.3,30.1 L18,35.7 L16.9,38.5 L1.4,78.1 C1.1,78.8 1.3,79.7
                1.8,80.2 C2.2,80.6 2.7,80.8 3.2,80.8 C3.5,80.9 3.7,80.8
                3.9,80.7 Z"
                id="Path"
              ></path>
              <path
                d="M41.1,34.4 C41.4,34.5 41.7,34.6 42,34.6 C42.7,34.6 43.4,34.2
                43.8,33.5 C48.8,23.5 48.7,11.6 43.6,1.6 C43.1,0.6 41.9,0.2
                40.9,0.7 C39.9,1.2 39.5,2.4 40,3.4 C44.6,12.2 44.6,22.8
                40.2,31.7 C39.7,32.7 40.1,33.9 41.1,34.4 Z"
                id="Path"
              ></path>
              <path
                d="M23.2,24.9 C23.4,24.9 23.6,25 23.7,25 C24.6,25 25.4,24.4 
                25.6,23.5 C27.2,17.9 25.9,11.8 22.3,7.2 C21.6,6.3 20.4,6.2 
                19.5,6.9 C18.6,7.6 18.5,8.8 19.2,9.7 C22,13.3 23,18.1 21.8,22.5
                C21.5,23.5 22.1,24.6 23.2,24.9 Z"
                id="Path"
              ></path>
              <path
                d="M80.4,38.5 C70.5,33.4 58.5,33.3 48.5,38.3 C47.5,38.8 47.1,40
                47.6,41 C48.1,42 49.3,42.4 50.3,41.9 C59.2,37.5 69.8,37.6 
                78.6,42.1 C78.9,42.3 79.2,42.3 79.5,42.3 C80.2,42.3 80.9,41.9
                81.3,41.2 C81.8,40.2 81.4,39 80.4,38.5 Z"
                id="Path"
              ></path>
              <path
                d="M58.6,56.4 C57.5,56.7 56.9,57.8 57.2,58.9 C57.5,60 58.6,60.6
                59.7,60.3 C64.1,59.1 68.9,60 72.5,62.9 C72.9,63.2 73.3,63.3
                73.7,63.3 C74.3,63.3 74.9,63 75.3,62.5 C76,61.6 75.8,60.4
                75,59.7 C70.3,56.1 64.2,54.9 58.6,56.4 Z"
                id="Path"
              ></path>
              <path
                d="M75.7,10 C75.4,9.3 74.7,8.9 73.9,8.8 L70.2,8.8 L68.2,5.7
                C67.8,5.1 67.1,4.7 66.3,4.8 C65.5,4.9 64.9,5.4 64.6,6.1 
                L63.3,9.7 L59.6,10.7 C58.9,10.9 58.3,11.5 58.2,12.3 C58.1,13
                58.4,13.8 59,14.3 L61.9,16.5 L61.6,20.2 C61.5,21 61.9,21.7
                62.6,22.1 C62.9,22.3 63.2,22.4 63.6,22.4 C64,22.4 64.4,22.3
                64.7,22 L67.9,19.8 L71.4,21.1 C72.1,21.4 72.9,21.2 73.4,20.7
                C74,20.2 74.2,19.4 74,18.7 L73,15.1 L75.4,12.1 C75.9,11.5
                76,10.7 75.7,10 Z"
                id="Path"
              ></path>
              <path
                d="M55.6,32.5 C57.1,32.5 58.7,31.9 59.8,30.7 C59.8,30.7 
                59.8,30.7 59.8,30.7 C60.9,29.6 61.6,28.1 61.6,26.5 C61.6,24.9
                61,23.4 59.8,22.3 C57.5,20 53.7,20 51.3,22.3 C50.2,23.4 
                49.5,24.9 49.5,26.5 C49.5,28.1 50.1,29.6 51.3,30.7 C52.5,31.9
                54.1,32.5 55.6,32.5 Z"
                id="Path"
              ></path>
              <path
                d="M61.7,40.7 C60.1,40.7 58.6,41.3 57.5,42.5 C56.4,43.6 
                55.7,45.1 55.7,46.7 C55.7,48.3 56.3,49.8 57.5,50.9 C58.6,52
                60.1,52.7 61.7,52.7 C63.3,52.7 64.8,52.1 65.9,50.9 C65.9,50.9
                65.9,50.9 65.9,50.9 C65.9,50.9 65.9,50.9 65.9,50.9 C67,49.8
                67.7,48.3 67.7,46.7 C67.7,45.1 67.1,43.6 65.9,42.5 
                C64.8,41.3 63.3,40.7 61.7,40.7 Z"
                id="Path"
              ></path>
              <path
                d="M6.2,27.4 L8.1,30.5 C8.5,31.1 9.1,31.4 9.8,31.4 C9.9,31.4
                9.9,31.4 10,31.4 C10.8,31.3 11.4,30.8 11.7,30.1 L13.1,26.5
                L16.8,25.5 C17.5,25.3 18.1,24.7 18.2,24 C18.3,23.3 18.1,22.5
                17.5,22 L14.6,19.8 L14.9,16.1 C15,15.3 14.6,14.6 13.9,14.2
                C13.2,13.8 12.4,13.8 11.8,14.3 L8.6,16.5 L5,15.2 C4.3,14.9
                3.5,15.1 3,15.6 C2.4,16.1 2.2,16.9 2.4,17.6 L3.4,21.2 
                L0.9,24.2 C0.4,24.8 0.3,25.6 0.6,26.3 C0.9,27 1.6,27.4
                2.4,27.5 L6.2,27.4 Z"
                id="Path"
              ></path>
              <path
                d="M51.8,70.4 C51.1,70.7 50.6,71.3 50.5,72.1 C50.4,72.9
                50.8,73.6 51.5,74 L54.7,75.9 L54.8,79.5 C54.8,80.3 55.3,80.9
                56,81.3 C56.3,81.4 56.5,81.5 56.8,81.5 C57.2,81.5 57.7,81.3
                58.1,81.1 L61.1,78.7 L64.8,79.7 C65.5,79.9 66.3,79.7 
                66.8,79.1 C67.3,78.5 67.4,77.7 67.2,77 L65.8,73.6 L67.9,70.5
                C68.3,69.9 68.4,69 68,68.4 C67.6,67.7 66.9,67.4 66.1,67.4 
                L62.3,67.7 L60,64.8 C59.5,64.2 58.8,64 58,64.1 C57.3,64.3 
                56.7,64.8 56.5,65.5 L55.5,69.1 L51.8,70.4 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Svg>
);
