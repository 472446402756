import React from 'react';

export const warningIcon = (
  <svg
    width="17px"
    height="16px"
    viewBox="0 0 17 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>DC96E20F-5B4B-4E2F-99FB-056A5B526A15@1.50x</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-440.000000, -122.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g transform="translate(432.000000, 114.000000)">
          <g transform="translate(8.000000, 8.000000)">
            <path
              d="M16.5739504,12.2297521 L10.5949421,1.23880992 C9.63431405,-0.378413223 7.29259504,-0.380561983 6.33067769,1.23880992 L0.351966942,12.2297521 C-0.630049587,13.882281 0.558876033,15.9749091 2.48360331,15.9749091 L14.4418182,15.9749091 C16.3649256,15.9749091 17.5559669,13.8839669 16.5739504,12.2297521 Z M8.46280992,13.991438 C7.91609917,13.991438 7.47107438,13.5464132 7.47107438,12.9997025 C7.47107438,12.4529917 7.91609917,12.0079669 8.46280992,12.0079669 C9.00952066,12.0079669 9.45454545,12.4529917 9.45454545,12.9997025 C9.45454545,13.5464132 9.00952066,13.991438 8.46280992,13.991438 Z M9.45454545,10.0244959 C9.45454545,10.5712066 9.00952066,11.0162314 8.46280992,11.0162314 C7.91609917,11.0162314 7.47107438,10.5712066 7.47107438,10.0244959 L7.47107438,5.06581818 C7.47107438,4.51910744 7.91609917,4.07408264 8.46280992,4.07408264 C9.00952066,4.07408264 9.45454545,4.51910744 9.45454545,5.06581818 L9.45454545,10.0244959 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
