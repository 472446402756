import styled from 'styled-components';
import { SelectInput, RowWrapper } from '../../styles/StyledComponents';

export const CheckboxRowWrapper = styled(RowWrapper)`
  color: ${props =>
    props.value?.includes(props.option)
      ? props.theme.color
      : props.theme.black};
  border-color: ${props =>
    props.value?.includes(props.option) ? props.theme.color : '#C9C9C9'};
`;

export const CheckboxLabel = styled.label`
  font-family: ${props => props.theme.fontFamily};
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.89px;
  padding: 18px 16px 16px 50px;
  width: 100%;
  transition: all 150ms ease-out;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
  }
`;

export const CheckboxInput = styled(SelectInput)`
  border-radius: 2px;
  font-size: 16px;
  &:checked:after {
    display: flex;
    justify-content: center;
    align-items: center;
    content: '✓';
    padding-left: 1px;
    color: ${props => props.theme.white};
  }
`;
