import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import ChartToggles from 'components/global/charts/ChartToggles';
import LineChart from 'components/global/charts/LineChart';
import { findPortfolio } from 'containers/inProgressPage/subcomponents/stock/helpers/findPortfolio';
import { NotificationTypes } from 'containers/app/helpers/notificationTypes';
import {
  FlexContainer,
  HoverContainer
} from 'containers/inProgressPage/subcomponents/stock/StyledComponents';
import { warningIcon } from './assets/warningIcon';

const ChartContainer = ({
  tickIndexes,
  assets,
  actions,
  ticks,
  tickCount,
  insuranceBalance,
  bots,
  portfolios,
  startingBalance,
  summary,
  notification,
  setNotification,
  insurancePremium,
  insuranceDelta
}) => {
  const assetColors = ['#222127', '#a492d2', '#ddcb00'];
  const playerColors = ['#2c374b', '#d97ed9', '#478d99'];
  const [range, setRange] = useState({ max: 120, min: 80 });
  const [showAssets, setShowAssets] = useState(true);
  const [omit, setOmit] = useState([]);
  const sortedTickIndexes = tickIndexes.sort((a, b) => a.id - b.id);

  const handleData = () => {
    return showAssets ? assetData() : portfolioData();
  };

  const handleRange = ({ price }) => {
    if (price > range.max) {
      setRange({ ...range, max: Math.ceil(price / 20) * 20 });
    } else if (price < range.min) {
      setRange({ ...range, min: Math.floor(price / 20) * 20 });
    }
  };

  const assetData = () => {
    return assets.map(({ question_stock_asset: stockInfo, ticks }, index) => {
      return {
        id: stockInfo.ticker_name,
        color: assetColors[index],
        data: ticks
          .sort((a, b) => a._index - b._index)
          .map((tick, index) => {
            handleRange(tick);
            return {
              ...tick,
              x: index + 1,
              y: parseInt(tick.price),
              tickId: sortedTickIndexes[index] && sortedTickIndexes[index].id
            };
          })
      };
    });
  };

  const portfolioData = () => {
    let max = range.max;
    let min = range.min;
    const allPortfoliosByPlayer = [
      {
        playerName: 'you',
        playerPortfolios: portfolios.filter(
          portfolio => portfolio.player_type === 'player_taker'
        )
      }
    ];

    if (bots) {
      bots.forEach(bot => {
        allPortfoliosByPlayer.push({
          playerName: bot.bot.player_name,
          playerPortfolios: portfolios.filter(
            portfolio => portfolio.answer_bot_id === bot.id
          )
        });
      });
    }

    const data = allPortfoliosByPlayer.map(
      ({ playerName, playerPortfolios }, index) => {
        return {
          id: playerName,
          color: playerColors[index],
          data: playerPortfolios.map((playerPortfolio, index) => {
            if (playerPortfolio.cash_value + playerPortfolio.asset_value > max)
              max = playerPortfolio.cash_value + playerPortfolio.asset_value;
            if (playerPortfolio.cash_value + playerPortfolio.asset_value < min)
              min = playerPortfolio.cash_value + playerPortfolio.asset_value;

            return {
              ...playerPortfolio,
              x: index + 1,
              y: playerPortfolio.cash_value + playerPortfolio.asset_value,
              tickId: sortedTickIndexes[index] && sortedTickIndexes[index].id
            };
          })
        };
      }
    );

    handleRange({ price: max });
    handleRange({ price: min });

    return data;
  };

  const renderSpotlightCards = () => {
    if (showAssets) {
      return assets.map((asset, index) => {
        return spotlightCard(
          asset.id,
          asset.question_stock_asset.ticker_name,
          asset.ticks[ticks.length - 1].price,
          asset.ticks[0].price,
          index
        );
      });
    } else {
      const currentPlayerPortfolios = [findPortfolio(portfolios)];

      if (bots) {
        bots.forEach(bot => {
          let currentBotPortfolio = {
            ...findPortfolio(portfolios, bot.id),
            botName: bot.bot.player_name
          };

          currentPlayerPortfolios.push(currentBotPortfolio);
        });
      }

      return currentPlayerPortfolios.map((taker, index) => {
        return spotlightCard(
          taker.id,
          taker.botName ? taker.botName : 'You',
          taker.portfolio_value,
          startingBalance,
          index
        );
      });
    }
  };

  const spotlightCard = (key, name, currentValue, startingValue, index) => {
    return (
      <StockInfo key={key}>
        <StockNameContainer>
          <StockName
            color={showAssets ? assetColors[index] : playerColors[index]}
          >
            {name}
          </StockName>
        </StockNameContainer>
        <BigNumber>${currentValue}</BigNumber>
        <SmallNumber>
          <span className="number">
            {currentValue >= startingValue ? '+' : '-'}$
            {Math.abs(currentValue - startingValue)}
          </span>
          <div className="description">
            <div className="pop-up stock-info">
              Change in price of {showAssets ? 'stock' : 'portfolio value'}{' '}
              compared to start of scenario
            </div>
          </div>
        </SmallNumber>
      </StockInfo>
    );
  };

  const handleBankrupt = () => {
    return (
      notification !== NotificationTypes.NONE && (
        <BankruptNotification
          justifyContent="space-between"
          onClick={() => setNotification(NotificationTypes.NONE)}
        >
          <WarningIcon className="warning icon">{warningIcon}</WarningIcon>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            <WarningText className="bold">
              {notification === NotificationTypes.DRAWDOWN_LIMIT
                ? 'You breached the drawdown limit '
                : 'You just went bankrupt '}
              and your cash balance was reset to $1,000.
            </WarningText>
            <WarningText>
              Your score will be affected and the scenario will continue.
            </WarningText>
          </div>
          <CloseButton>x</CloseButton>
        </BankruptNotification>
      )
    );
  };

  return (
    <>
      <FlexContainer
        marginBottom="0"
        justifyContent="flex-start"
        alignItems="center"
      >
        {renderSpotlightCards()}
        {summary && (
          <ChartToggles
            showAssets={showAssets}
            setShowAssets={setShowAssets}
            startingBalance={startingBalance}
            setRange={setRange}
            omit={omit}
            setOmit={setOmit}
            bots={bots}
            assets={assets}
          />
        )}
        {handleBankrupt()}
      </FlexContainer>
      <LineChart
        showAssets={showAssets}
        chartData={handleData()}
        range={range}
        setRange={setRange}
        tickCount={tickCount}
        actions={actions}
        insuranceBalance={insuranceBalance}
        ticks={ticks}
        bots={bots}
        omit={omit}
        insurancePremium={insurancePremium}
        insuranceDelta={insuranceDelta}
      />
    </>
  );
};

ChartContainer.propTypes = {
  tickIndexes: PropTypes.arrayOf(PropTypes.object).isRequired,
  assets: PropTypes.arrayOf(PropTypes.object).isRequired,
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  ticks: PropTypes.arrayOf(PropTypes.object).isRequired,
  tickCount: PropTypes.number.isRequired,
  insuranceBalance: PropTypes.object,
  bots: PropTypes.arrayOf(PropTypes.object).isRequired,
  portfolios: PropTypes.arrayOf(PropTypes.object),
  startingBalance: PropTypes.number,
  summary: PropTypes.bool,
  notification: PropTypes.oneOf(Object.values(NotificationTypes)),
  setNotification: PropTypes.func,
  insurancePremium: PropTypes.number,
  insuranceDelta: PropTypes.number
};

ChartContainer.defaultProps = {
  summary: false,
  notification: NotificationTypes.NONE
};

export default ChartContainer;

const StockInfo = styled.div`
  margin-right: 50px;
`;

const StockNameContainer = styled.div`
  margin-bottom: 10px;
`;

const StockName = styled.span`
  color: ${({ color }) => color};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;
`;

const BigNumber = styled.div`
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 8px;
`;

const SmallNumber = styled(HoverContainer)`
  color: #2c374b;
  font-size: 14px;
  font-weight: 900;
`;

const BankruptNotification = styled(FlexContainer)`
  background: #e86d5a;
  color: white;
  min-width: 531px;
  max-width: 631px;
  height: 53px;
`;

const WarningIcon = styled.div`
  margin: 12px 8px auto 12px;
`;

const WarningText = styled.div`
  &.bold {
    font-weight: 500;
  }
`;

const CloseButton = styled.div`
  padding: 2px 8px 0 32px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
`;
