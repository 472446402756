const SET_IS_CHANGING_TABS = 'SET_IS_CHANGING_TABS';
const SET_IS_COPYING = 'SET_IS_COPYING';
const SET_BROWSER_CAPTURE = 'SET_BROWSER_CAPTURE';

export const ASSESSMENT_POLICE_ACTIONS = {
  SET_IS_CHANGING_TABS,
  SET_IS_COPYING,
  SET_BROWSER_CAPTURE
};

const TAB_CHANGE_ON_FOCUS = 'ASSESSMENT_POLICE_TAB_CHANGE_ON_FOCUS';
const TAB_CHANGE_ON_BLUR = 'ASSESSMENT_POLICE_TAB_CHANGE_ON_BLUR';
const COPY_ATTEMPT = 'ASSESSMENT_POLICE_COPY_ATTEMPT';
const BROWSER_CAPTURE_ATTEMPT = 'ASSESSMENT_POLICE_BROWSER_CAPTURE_ATTEMPT';
const FILE_CAPTURE_CONFLICT = 'ASSESSMENT_POLICE_FILE_CAPTURE_CONFLICT';

export const ASSESSMENT_POLICE_EVENTS = {
  TAB_CHANGE_ON_FOCUS,
  TAB_CHANGE_ON_BLUR,
  COPY_ATTEMPT,
  BROWSER_CAPTURE_ATTEMPT,
  FILE_CAPTURE_CONFLICT
};
export const screenCaptureHotkeys = [
  'shift+mod+4',
  'ctrl+K',
  'mod+shift+2',
  'shift+mod+2',
  'mod+shift+2',
  'meta+shift+s',
  `mod+ctrl+shift+4`,
  `mod+shift+4`,
  `mod+shift+3`,
  `mod+ctrl+shift+3`
];

export const screenRecordingHotkeys = [
  'mod+shift+c',
  'shift+mod+c',
  'mod+s',
  'f13'
];
