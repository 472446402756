import React from 'react';
import { WindowTypes } from 'containers/app/helpers/windowTypes';
import {
  InputContainer,
  Input,
  Quantity,
  QuantityContainer,
  InputButton
} from './StyledComponents';

const ActionInput = ({
  setShareValue,
  register,
  shares,
  type,
  firstAssetQuantity,
  disabled,
  error,
  isCleared
}) => {
  return (
    <InputContainer>
      <InputButton
        right={54}
        onClick={() => setShareValue(shares + 1)}
        className={disabled && 'disabled'}
      >
        +
      </InputButton>
      <InputButton
        onClick={() => setShareValue(shares - 1)}
        className={disabled && 'disabled'}
      >
        &#8211;
      </InputButton>
      <Input
        grayedOut={isCleared}
        type="number"
        name="shares"
        ref={register}
        placeholder={0}
        disabled={disabled}
        onChange={e => setShareValue(e.target.value)}
        value={shares}
        className={error}
      />
      {type === WindowTypes.INSURANCE && (
        <QuantityContainer>
          <Quantity
            onClick={() => setShareValue(firstAssetQuantity)}
            className={disabled && 'disabled'}
          >
            All
          </Quantity>
        </QuantityContainer>
      )}
    </InputContainer>
  );
};

export default ActionInput;
