import { useEffect } from 'react';

export const useIsCopying = onCopy => {
  useEffect(() => {
    document.addEventListener('copy', onCopy);
    document.addEventListener('cut', onCopy);
    document.addEventListener('paste', onCopy);
    return () => {
      document.removeEventListener('copy', onCopy);
      document.removeEventListener('cut', onCopy);
      document.removeEventListener('paste', onCopy);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
