import { SET_PAGE } from 'services/context/assessmentContext/assessmentContext';

export const setErrorPage = (response, dispatch) => {
  dispatch({
    type: SET_PAGE,
    payload: {
      assessment: null,
      assessmentIsLoading: false,
      assessmentError: {
        status: response ? response.status : '500',
        message: response ? response.data.message : 'Something went wrong'
      }
    }
  });
};
