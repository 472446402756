import React from 'react';
import InputContainer from 'components/global/InputContainer';
import { useRenderErrors } from 'components/global/hooks/useRenderErrors';
import { FileUploadInput } from './FileUploadInput';

const FileUpload = ({
  formikProps,
  name,
  showInitialErrors,
  disabled,
  capitalize,
  optional,
  label,
  description
}) => {
  const { errors, handleChange, submitCount, setFieldValue } = formikProps;

  const showErrors = useRenderErrors(
    errors,
    submitCount,
    name,
    showInitialErrors
  );

  return (
    <InputContainer
      disabled={disabled}
      capitalize={capitalize}
      optional={optional}
      label={label}
      description={description}
      showErrors={showErrors}
      errors={errors}
      name={name}
    >
      <FileUploadInput
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        name={name}
        formikProps={formikProps}
      />
    </InputContainer>
  );
};

export default FileUpload;
