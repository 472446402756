import PropTypes from 'prop-types';
import React from 'react';
import { StyledButton, BtnText } from './styles/StyledComponents';
import LoadingSpinner from './components/LoadingSpinner';

const LoadingButton = ({
  disabled,
  isLoading,
  text,
  className,
  isFullWidth,
  onClick
}) => {
  return (
    <StyledButton
      disabled={disabled || isLoading}
      className={className}
      isFullWidth={isFullWidth}
      onClick={onClick}
      type="button"
    >
      {isLoading ? (
        <LoadingSpinner className={className} />
      ) : (
        <BtnText>{text}</BtnText>
      )}
    </StyledButton>
  );
};

LoadingButton.propTypes = {
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string,
  isFullWidth: PropTypes.bool,
  icon: PropTypes.element,
  onClick: PropTypes.func.isRequired
};

LoadingButton.defaultProps = {
  disabled: false,
  isLoading: false,
  text: 'button',
  className: '',
  isFullWidth: false
};

export default LoadingButton;
