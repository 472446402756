import styled from 'styled-components';

export const StyledButton = styled.button`
  align-items: center;
  background: ${({ theme }) => theme.color};
  border: ${({ theme }) => `1px solid ${theme.color}`};
  border-radius: 2px;
  color: ${({ theme }) => theme.white};
  display: flex;
  font-family: ${({ theme }) => theme.boldFontFamily};
  font-size: 14px;
  font-weight: 900;
  height: 32px;
  justify-content: center;
  letter-spacing: 1.25px;
  line-height: 16px;
  outline: none;
  padding: 0 32px;
  text-transform: ${({ textTransform }) => textTransform};
  transition: 200ms all ease-out;
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'fit-content')};
  cursor: pointer;
  svg {
    fill: ${({ theme }) => theme.white};
    width: 11px;
    height: 11px;
    margin-right: 10px;
  }
  &:hover {
    background: ${({ theme }) => theme.colorShade};
    border-color: ${({ theme }) => theme.colorShade};
  }
  &:disabled {
    cursor: not-allowed;
    background: #dddddd;
    color: #a2a2a2;
    border-color: #dddddd;
    svg {
      cursor: not-allowed;
      fill: #a2a2a2;
    }
  }
  &.small {
    height: 22px;
    padding: 0 22px;
    font-size: 12px;
  }
  &.inverted {
    background: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.color};
    &:hover {
      background: ${({ theme }) => theme.color};
      border-color: ${({ theme }) => theme.color};
      color: ${({ theme }) => theme.white};
    }
  }
  &.secondary {
    background: ${({ theme }) => theme.white};
    border: 2px solid #939393;
    color: #939393;
    &:hover {
      background: #939393;
      color: ${({ theme }) => theme.white};
    }
  }
  &.warning {
    background: #d94a54;
    border-color: #d94a54;
    &:hover {
      background: #c62a34;
      border-color: #c62a34;
    }
  }
  &.nav {
    min-width: 120px;
  }
  &.talentstat-secondary {
    background: #f8bc7f;
    border-color: #f8bc7f;
    &:hover {
      background: #e4a86b;
      border-color: #e4a86b;
    }
    &:disabled {
      background: #dddddd;
      border-color: #dddddd;
    }
  }
`;

export const BtnText = styled.span`
  padding-top: 3px;
`;
