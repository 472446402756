import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';

export const useGetAssessmentInfo = () => {
  const { state, dispatch } = useAssessmentContext();
  const { assessmentAttempt } = state;
  const { uuid } = assessmentAttempt;
  const {
    name,
    slug,
    sections,
    taker_fields,
    is_public,
    open_date,
    close_date,
    assessment_detail: { assessment_detail_content },
    tenant: { name: tenantName }
  } = assessmentAttempt.assessment;

  return {
    name,
    slug,
    uuid,
    dispatch,
    sections,
    taker_fields,
    is_public,
    assessment_detail_content,
    tenantName,
    open_date,
    close_date
  };
};
