import React from 'react';
import Loading from 'components/local/loading/Loading';
import SlotQuestion from 'containers/inProgressPage/subcomponents/SlotQuestion';
import SlotStatic from 'containers/inProgressPage/subcomponents/SlotStatic';
import SlotSurvey from 'containers/inProgressPage/subcomponents/SlotSurvey';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';
import { ErrorSpan } from 'components/global/styles/StyledComponents';

const Slot = ({ formikProps }) => {
  const { state, currentSlot } = useAssessmentContext();
  const { slotContentIsLoading, error } = state;

  const slotTypes = {
    slot_attempt_static: <SlotStatic />,
    slot_attempt_survey: <SlotSurvey />,
    slot_attempt_question: <SlotQuestion formikProps={formikProps} />
  };

  if (slotContentIsLoading || !currentSlot || !currentSlot.slot_type)
    return <Loading />;

  return (
    <div
      id={`slot-${currentSlot.sort_index}`}
      data-type={currentSlot.question.question_type}
      data-can-select-multiple={currentSlot.question.can_select_multiple}
      data-has-images={Boolean(
        currentSlot.question.content[0].text_display.match(/<img/)
      )}
      data-has-files={Boolean(currentSlot.question?.files?.length)}
      onCopy={e => {
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
      }}
    >
      {slotTypes[currentSlot.slot_type]}
      <ErrorSpan>{error}</ErrorSpan>
    </div>
  );
};

export default Slot;
