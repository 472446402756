import { useState } from 'react';
import { QuestionTypes } from 'containers/app/helpers/questionTypes';
import { slotTypeOptions } from 'containers/app/helpers/slotTypeOptions';

export const useHandleSlot = currentSlot => {
  const [modal, setModal] = useState(false);
  const [submitSection, setSubmitSection] = useState(false);

  const questionSlot = currentSlot?.slot_type === slotTypeOptions.question;
  const banded = currentSlot?.question?.question_type === QuestionTypes.BANDED;
  const select = currentSlot?.question?.question_type === QuestionTypes.SELECT;
  const upload = currentSlot?.question?.question_type === QuestionTypes.UPLOAD;
  const answer = currentSlot?.answer;

  return {
    questionSlot,
    banded,
    select,
    upload,
    modal,
    setModal,
    submitSection,
    setSubmitSection,
    answer,
    questionType: currentSlot?.question?.question_type
  };
};
