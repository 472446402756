import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { clockIcon } from './assets/clockIcon';
import { getUTCDate } from 'containers/app/helpers/getUTCDate';
import {
  FlexContainer,
  PageWrapper
} from 'components/local/styles/StyledComponents';
import { useThemeContext } from 'services/context/themeContext/useThemeContext';

const ComingSoonPage = ({ openDate }) => {
  const { themeState } = useThemeContext();
  const [timeUntilWindow, setTimeUntilWindow] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeUntilWindow(getSecondsDifference());
    }, 1000);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    if (timeUntilWindow < 0) {
      window.location.reload(true);
    }
  }, [timeUntilWindow]);

  const getSecondsDifference = () => {
    const now = getUTCDate();
    let seconds = Math.floor((openDate - now) / 1000);
    return `${seconds}`;
  };

  const getFormattedTimeWindow = seconds => {
    if (seconds < 0) {
      return '0d 0h 0m 0s';
    }
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    hours = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hours * 60;
    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <>
      <ErrorPageBanner>
        <ImgLogo src={themeState?.c1LogoUrl} />
      </ErrorPageBanner>
      <ErrorPageWrapper>
        <FlexContainer justifyContent="center" alignItems="center">
          {timeUntilWindow && (
            <ErrorBox>
              <ErrorIcon>{clockIcon}</ErrorIcon>
              <ErrorHeader>Coming soon!</ErrorHeader>
              <ErrorBody>
                This assessment opens in{' '}
                {getFormattedTimeWindow(timeUntilWindow)}.
              </ErrorBody>
              <ErrorEmail></ErrorEmail>
            </ErrorBox>
          )}
        </FlexContainer>
      </ErrorPageWrapper>
    </>
  );
};

ComingSoonPage.propTypes = {
  openDate: PropTypes.instanceOf(Date)
};

export default ComingSoonPage;

const ErrorBody = styled.div`
  color: ${props => props.theme.black};
  font-family: ${({ theme }) => theme.mediumFontFamily};
  font-size: 16px;
  font-weight: 500;
  height: 24px;
  letter-spacing: 0.53px;
  text-align: center;
`;

const ErrorBox = styled.div`
  background: #fcfbfb;
  border-radius: 0px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  height: 722px;
  padding-top: 145px;
  text-align: center;
`;

const ErrorEmail = styled.div`
  color: ${props => props.theme.black};
  font-family: ${({ theme }) => theme.mediumFontFamily};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.53px;
  margin-bottom: 64px;
  text-align: center;
  width: 800px;
`;

const ErrorHeader = styled.div`
  color: ${({ theme }) => theme.color};
  font-family: ${({ theme }) => theme.blackFontFamily};
  font-size: 80px;
  font-weight: 400;
  height: 80px;
  letter-spacing: 2.67px;
  margin-bottom: 48px;
  width: inline-block;
`;

const ErrorIcon = styled.div`
  margin-bottom: 68px;
`;

const ErrorPageBanner = styled.div`
  background: #292945;
  border-radius: 0px;
  width: auto;
`;

const ErrorPageWrapper = styled(PageWrapper)`
  background: #f0f2f4;
  color: ${({ theme }) => theme.white};
  min-height: 95vh;
  padding-bottom: 65px;
  padding-left: 208px;
  padding-right: 208px;
  padding-top: 113px;
`;

const ImgLogo = styled.img`
  margin-bottom: 16px;
  margin-left: 208px;
  margin-right: 1075px;
  margin-top: 18px;
  width: 157px;
`;
