import styled from 'styled-components';

export const WindowWrapper = styled.div`
  align-items: center;
  background: #f7f6f6;
  border: 1px solid #cecbc8;
  border-radius: 4px;
  /* switch to hex if equivalent found */
  color: ${({ color }) => color || 'rgba(44, 55, 75, 0.7)'};
  display: flex;
  flex-direction: column;
  font-size: 14px;
  height: ${({ height }) => height};
  letter-spacing: 0.4px;
  margin: ${({ margin }) => margin};
  max-height: ${({ maxHeight }) => maxHeight};
  width: ${({ width }) => width};
  button {
    font-family: ${({ theme: condensedFontFamily }) => condensedFontFamily};
    font-weight: normal;
    letter-spacing: 0.69px;
  }
  .small-btn {
    width: 96px;
  }
`;

export const WindowBody = styled.div`
  padding: 10px;
  width: 100%;
  &.balance-table {
    padding: 0 16px;
  }
  &.execution-window {
    padding: 8px;
  }
`;

export const Header = styled.div`
  background: #dfddda;
  border-radius: 4px 4px 0 0;
  color: #2c374b;
  font-size: 14px;
  font-weight: 500;
  height: 34px;
  padding-top: 9px;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
  &.portfolio-table {
    border-radius: 0;
  }
`;

export const HeaderWithToggle = styled(Header)`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
`;

export const DropdownArrow = styled.div`
  margin-left: 5px;
  ${({ showTransactions }) =>
    showTransactions
      ? 'transform: translateY(1px);'
      : 'transform: rotate(180deg) translateY(1px);'};
  cursor: pointer;
`;

export const Input = styled.input`
  appearance: textfield;
  background: #f7f6f6;
  border: 1px solid #dee1e5;
  border-radius: 2px;
  caret-color: #2c374b;
  color: ${({ grayedOut }) => (grayedOut ? '#d3d3d4' : '#2c374b')};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
  outline: none;
  padding: 8px 6px 6px 8px;
  width: 100%;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
  }
`;

export const InputButton = styled.span`
  background: #c6c8d0;
  border-radius: 1px;
  height: 20px;
  padding: 2px 0;
  position: absolute;
  right: ${({ right }) => (right ? `${right}px` : '6px')};
  top: 6px;
  width: 42px;
  cursor: pointer;
  color: #495063;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  &.disabled {
    display: none;
  }
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const FlexContainer = styled.div`
  align-items: ${({ alignItems }) => alignItems && alignItems};
  display: flex;
  flex: ${({ flex }) => flex};
  flex-direction: ${({ direction }) => direction};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  font-size: ${({ fontSize }) => fontSize};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? `${justifyContent}` : 'space-between'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '7px'};
  padding-top: ${({ paddingTop }) => paddingTop && paddingTop};
  width: ${({ width }) => width};
  .bold {
    font-weight: bold;
  }
  .emphasis {
    font-size: 24px;
    margin-bottom: 8px;
  }
  .negative {
    color: #f79181;
  }
  &.bottom {
    margin-bottom: 13px;
  }
`;

export const AssetInfoWrapper = styled.div`
  background: #efeeed;
  margin: 8px 0;
  padding: 8px;
`;

export const Divider = styled.div`
  background: ${({ vertical }) => (vertical ? '#dfddda' : '#979797')};
  height: ${({ vertical }) => (vertical ? '116px' : '1px')};
  margin: ${({ vertical }) => (vertical ? '0 23.5px' : '16px 0')};
  width: ${({ vertical }) => (vertical ? '1px' : '100%')};
`;

export const QuantityContainer = styled.div`
  position: absolute;
  right: 102px;
  top: 8px;
`;

export const Quantity = styled.span`
  background: #c6c8d0;
  border-radius: 8px;
  color: #495063;
  height: 16px;
  margin: 2px;
  min-width: 16px;
  padding: 2px 4px;
  cursor: pointer;
  &.disabled {
    display: none;
  }
`;

export const AssetName = styled.div`
  color: ${({ index, theme }) => {
    switch (index) {
      case 0:
        return theme.black;
      case 1:
        return '#a492d2';
      case 2:
        return '#c6ab52';
      default:
        return theme.black;
    }
  }};
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 13px;
  width: 100%;
`;

export const Notification = styled.div`
  background: #2c374b;
  color: #efeeed;
  margin: 48px 0;
  padding: 7px;
  text-align: center;
  &.stock {
    width: fit-content;
    position: relative;
    top: 20px;
    margin: 0 auto;
  }
`;

export const BalanceDetail = styled.div`
  font-size: 12px;
  letter-spacing: 0.4px;
  ${({ bold }) => bold && 'font-weight: bold'}
`;

export const HoverContainer = styled(BalanceDetail)`
  .description {
    opacity: 0;
    position: absolute;
    visibility: hidden;
    transition: opacity 200ms, visibility 200ms;
    .information {
      left: 45%;
      top: 30px;
    }
    .pop-up {
      background: #2c374b;
      color: #efeeed;
      padding: 4.5px 8px 5px;
      position: relative;
    }
    .portfolio-stock-held {
      left: 15px;
      top: 0px;
    }
    .stock-held {
      left: 15px;
      top: -6px;
    }
    .stock-info {
      left: 10px;
      top: 5px;
    }
  }
  .number {
    opacity: 0.7;
  }
  &.balance-change {
    align-items: center;
    background: ${({ increase }) => (increase ? '#6ebc96' : '#f79181')};
    border-radius: 9.5px;
    color: #f7f6f6;
    display: flex;
    font-weight: 500;
    height: 19px;
    justify-content: center;
    margin-left: 5px;
    padding: 0 5px;
    white-space: nowrap;
    width: max-content;
  }
  &:hover {
    .description {
      display: block;
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const InsuranceParagraph = styled.div`
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  span {
    font-weight: bold;
  }
`;

export const IconWrapper = styled.span`
  margin: 2px 5px 0;
`;

export const SectionHeader = styled.div`
  font-size: 20px;
  margin: ${({ margin }) => margin};
`;

export const InputErrorContainer = styled.div`
  .error {
    border: 1px solid #dc212b;
    transition: all 150ms ease-out;
  }
`;
