import { MOVE_TO_NEW_SLOT } from 'services/context/assessmentContext/assessmentContext';
import { updateSectionToSubmitted } from 'services/requests/sectionRequests';

export const moveToNextSlot = (
  slotIndex,
  sectionIndex,
  assessment,
  dispatch,
  submitSection
) => {
  const {
    uuid,
    assessment: { slug },
    section_attempts
  } = assessment;
  const currentSection = section_attempts[sectionIndex];
  const {
    slot_attempts,
    section: { is_linear }
  } = currentSection;
  let endSection;

  if (is_linear) {
    endSection =
      slotIndex === slot_attempts[slot_attempts.length - 1].sort_index ||
      submitSection;
  } else {
    endSection = submitSection;
  }

  if (endSection) {
    dispatch({
      type: MOVE_TO_NEW_SLOT,
      payload: {
        assessment,
        currentSlotId: null,
        currentSectionIndex: null,
        slotIsLoading: true
      }
    });

    updateSectionToSubmitted(
      assessment,
      slug,
      uuid,
      currentSection.id,
      sectionIndex,
      dispatch
    );
  } else {
    const index = is_linear ? slotIndex + 1 : slotIndex;
    dispatch({
      type: MOVE_TO_NEW_SLOT,
      payload: {
        assessment,
        currentSlotId: slot_attempts[index].id,
        currentSlotIndex: index,
        slotIsLoading: false
      }
    });
  }
};
