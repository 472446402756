import { axios } from 'services/requests/helpers/AxiosClient';
import { configureEndpoint } from 'services/requests/helpers/configureEndpoint';

const PROCTORING = 'proctoring';
const VALIDATE_MAX_ATTEMPTS = 'validate-max-attempts';

export const validateAttemptLimit = async (takerEmail, assessmentId, uuid) => {
  const requestUrl = configureEndpoint(PROCTORING, VALIDATE_MAX_ATTEMPTS);

  const response = await axios.post(requestUrl, {
    assessment_id: assessmentId,
    taker_email: takerEmail,
    attempt_uuid: uuid
  });

  return response.data.data;
};
