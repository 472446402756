import React from 'react';
import BottomNavBar from 'components/local/navbar/BottomNavBar';
import ProgressBarClickable from 'components/global/progressBar/ProgressBarClickable';
import ProgressBarLinear from 'components/global/progressBar/ProgressBarLinear';
import SectionLanding from 'containers/inProgressPage/subcomponents/SectionLanding';
import SectionStockLanding from 'containers/inProgressPage/subcomponents/stock/SectionStockLanding';
import SectionWrapper from 'containers/inProgressPage/subcomponents/SectionWrapper';
import 'react-circular-progressbar/dist/styles.css';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';
import { getSubmitButtonText } from 'containers/app/helpers/getSubmitButtonText';
import { shouldSubmitAssessment } from 'containers/app/helpers/shouldSubmitAssessment';

const SectionPending = ({
  isLoading,
  isPausable,
  isStockSection,
  currentSection,
  handleStartSection,
  setModalShowing
}) => {
  const { section, slot_attempts } = currentSection;
  const { state, currentSlotIndex } = useAssessmentContext();
  const { assessmentAttempt } = state;
  const {
    assessment: { sections: totalSections }
  } = assessmentAttempt;
  const sectionLength = currentSection.slot_attempts.length;

  const renderProgressBar = () => {
    if (!isStockSection) {
      return section.is_linear ? (
        <ProgressBarLinear slots={slot_attempts.length} currentSlot={-1} />
      ) : (
        <ProgressBarClickable
          currentSection={currentSection}
          currentSlotIndex={0}
          disabled={true}
        />
      );
    }
    return null;
  };

  const isAssessmentReadyToSubmit = shouldSubmitAssessment(
    totalSections.length,
    currentSection.sort_index,
    sectionLength,
    currentSlotIndex
  );
  return (
    <section
      id={`section-${currentSection.id}`}
      data-is-linear={currentSection.section.is_linear}
    >
      <SectionWrapper background={isStockSection ? '#efeeed' : null}>
        {renderProgressBar()}
        {isStockSection ? (
          <SectionStockLanding currentSection={currentSection} />
        ) : (
          <SectionLanding currentSection={currentSection} />
        )}
        <BottomNavBar
          background={isStockSection ? '#dfddda' : ''}
          buttonText={getSubmitButtonText(!isAssessmentReadyToSubmit, false)}
          buttonTextSecondary={isPausable ? 'LEAVE ASSESSMENT' : null}
          flat={isStockSection}
          handleClick={handleStartSection}
          handleClickSecondary={isPausable ? setModalShowing : () => {}}
          isLoading={isLoading}
        />
      </SectionWrapper>
    </section>
  );
};

export default SectionPending;
